import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from
  '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, List, ListItem, ListItemText, Box, MenuItem, TextField, Checkbox } from '@mui/material';
import { GoogleMap, StreetViewPanorama, useJsApiLoader, useLoadScript, Marker } from '@react-google-maps/api';

function AddressInformation(props) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAMjFIIlVgRO1yUsl7uLN0RXBMyDHMJUS8",
  })
  const [latitude, setLatitude] = useState(-22.95245554364288)
  const [longitude, setLongitude] = useState(-43.21047122854996)

  const center = {
    lat: latitude,
    lng: longitude,
  };

  const positionClick = {
    lat: props.latitudeClick,
    lng: props.longitudeClick,
  }
// console.log(window.innerWidth)
  const mapContainerStyle = {
    width: '400px',
    height:
      '140px',
    width: window.innerWidth > 600 ? '400px' : window.innerWidth - 50 + 'px',
  };
  const options = {
    disableDefaultUI: true,
    zoomControl: false,
    mapTypeControl: true,
    scaleControl: true,
    rotateControl: false,
    fullscreenControl: true,
    mapTypeId: 'satellite',
    tilt: 0,
    zoom: 18,
  }
  const options2 = {
    disableDefaultUI: false,
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    rotateControl: false,
    fullscreenControl: true,
    mapTypeId: 'satellite',
    streetViewControl: false,
    draggable: true,
    inclination: 0,
    heading: 0,
    tilt: 0,
    zoom: 19,
  }

  if (props.checkedAddress === true && document.querySelector('#address-customer-checkbox')) document.querySelector('#address-customer-checkbox').checked = true



  const handleAddress = () => {
    const addressCheckbox = document.querySelector('#address-customer-checkbox')
    if (addressCheckbox.checked || props.checkedAddress) {


      props.setAddressStreet(props.street)
      props.setAddressNumber(props.number)
      props.setAddressCity(props.city)
      props.setAddressState(props.state)
      props.setAddressDistrict(props.neighborhood)
      props.setAddressCep(props.cep)

      props.setCheckedAddress(true)

        if (document.querySelector('#cep-service')) {
          document.querySelector('#cep-service').disabled = true
          document.querySelector('#street-service').disabled = true
          document.querySelector('#number-service').disabled = true
          document.querySelector('#district-service').disabled = true
          document.querySelector('#city-service').disabled = true
          document.querySelector('#state-service').disabled = true
        }
     
    }
    if (!addressCheckbox.checked) {
      props.setAddressStreet('')
      props.setAddressNumber('')
      props.setAddressCity('')
      props.setAddressState('')
      props.setAddressDistrict('')
      props.setAddressCep('')

      props.setCheckedAddress(false)
      if (document.querySelector('#cep-service')) {
        document.querySelector('#cep-service').disabled = false
        document.querySelector('#street-service').disabled = false
        document.querySelector('#number-service').disabled = false
        document.querySelector('#district-service').disabled = false
        document.querySelector('#city-service').disabled = false
        document.querySelector('#state-service').disabled = false
      }

    }
  }

  const cepFormat = (cep) => {
    cep = cep.replace(/\D/g, "")
    cep = cep.replace(/(\d{5})(\d{3})/, "$1-$2")

    if (cep.length > 9) {
      cep = cep.substring(0, 8)
    }

    return cep
  }
  const searchMaps = () => {

    fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${props.addressNumber}+${props.addressStreet}+${props.addressCity}+${props.addressState}+BR&key=AIzaSyAMjFIIlVgRO1yUsl7uLN0RXBMyDHMJUS8`, {
      method: 'GET',
    }).then(response => response.json())
      .then(data => {
        if (data.status === 'ZERO_RESULTS') {
          return
        }
      
        setLatitude(data.results[0]?.geometry.location.lat === -14.235004 ? -22.95245554364288 : data.results[0]?.geometry.location.lat)
        setLongitude(data.results[0]?.geometry.location.lng === -51.92528 ? -43.21047122854996 : data.results[0]?.geometry.location.lng)
      })
  }
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    props.setChecked(event.target.checked);
  };

  return (
    <div>

      <Grid container spacing={1} sx={{ mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Grid item sx={{ display: 'flex', alignItems: 'center', mb: 1 }} >
          <input
            type='checkbox'
            id='address-customer-checkbox'
            onClick={handleAddress}
          />&nbsp;<h4>USAR ENDEREÇO DO CLIENTE</h4>
        </Grid>
        <Grid container spacing={1}>

          <Grid item xs={12} sm={6}>
            <TextField
              id='cep-service'
              label="CEP:"
              size="small"
              fullWidth
              value={props.addressCep}
              onChange={(e) => {
                e.target.value = cepFormat(e.target.value)
                props.setAddressCep(e.target.value)
                if (e.target.value.length > 8) {
                  fetch(`https://viacep.com.br/ws/${e.target.value}/json/`)
                    .then(response => response.json())
                    .then(data => {
                      if (data.erro) {
                        return
                      }
         
                      props.setAddressCep(e.target.value)
                      props.setAddressStreet(data.logradouro)
                      props.setAddressDistrict(data.bairro)
                      props.setAddressCity(data.localidade)
                      props.setAddressState(data.uf)


                    })
                }
              }}

            >

            </TextField>

          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id='street-service'
              label="Logradouro:"
              size="small"
              value={props.addressStreet}
              onChange={(e) => { props.setAddressStreet(e.target.value) }}
              fullWidth
            >

            </TextField>

          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id='number-service'
              label="N°:"
              size="small"
              value={props.addressNumber}
              onChange={(e) => { props.setAddressNumber(e.target.value) }}
              fullWidth
            >

            </TextField>

          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id='district-service'
              label="Bairro:"
              size="small"
              value={props.addressDistrict}
              onChange={(e) => { props.setAddressDistrict(e.target.value) }}
              fullWidth
            >

            </TextField>

          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id='city-service'
              label="Cidade:"
              size="small"
              value={props.addressCity}
              onChange={(e) => { props.setAddressCity(e.target.value) }}
              fullWidth
            >

            </TextField>

          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id='state-service'
              label="Estado:"
              size="small"
              value={props.addressState}
              onChange={(e) => { props.setAddressState(e.target.value) }}
              fullWidth
            >

            </TextField>

          </Grid>
          <Grid item xs={12} sm={12} justifyContent={'center'} alignItems={'center'} display={'flex'}>
            <Button
              variant="contained"
              color="primary"
              onClick={searchMaps}
              size='small'
            >
              Pesquisar no mapa
            </Button>
          </Grid>
        </Grid>

        {isLoaded &&
          <Grid container spacing={21} pl={0} pt={2}>

            {<Grid item xs={2} sm={5} spacing={9}>
              <GoogleMap

                mapContainerStyle={mapContainerStyle}
                zoom={16}
                center={center}

              >
                <Marker position={center} />
              </GoogleMap>
              <br />
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={10}
                center={center}
                options={options2}


              >
                <StreetViewPanorama
                  position={center}
                  visible={true}
                  options={{
                    enableCloseButton: false,
                    addressControl: true,
                    fullscreenControl: true,
                    panControl: true,
                    zoomControl: false,
                    clickToGo: true,
                    scrollwheel: false,
                    visible: true,
                    showRoadLabels: true,
                    motionTracking: true,
                    motionTrackingControl: false,
                    motionTrackingControlOptions: true,

                  }}
                />
              </GoogleMap>
            </Grid>}
            <Grid item xs={10} sm={6}>
              <h5>Confirme no mapa abaixo o telhado do cliente:</h5>
              <GoogleMap
                mapContainerStyle={{ ...mapContainerStyle, height: '283px', width: window.innerWidth > 600 ? '400px' : window.innerWidth - 50 + 'px' }}// Ajuste a altura conforme necessário
                zoom={18}
                options={{
                  disableDefaultUI: true,
                  zoomControl: true,
                  mapTypeControl: true,
                  scaleControl: true,
                  rotateControl: true,
                  fullscreenControl: true,
                  mapTypeId: 'satellite',
                  streetViewControl: true,
                  draggable: true,
                  inclination: 0,
                  heading: 0,
                  tilt: 0,
                  zoom: 19,
                }}
                center={center}
                onClick={(e) => {
                  setLatitude(e.latLng.lat())
                  setLongitude(e.latLng.lng())
                  props.setLatitudeClick(e.latLng.lat())
                  props.setLongitudeClick(e.latLng.lng())
                }}

              >
                <Marker
                  position={positionClick}

                />

              </GoogleMap>
            </Grid>
          </Grid>}
      </Grid>

    </div>
  )
}

export default AddressInformation
