import { Avatar, Badge, Box, boxClasses, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React from 'react'
import Preview from '../file_preview'



//teste


import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';






import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';



import NotificationsIcon from '@mui/icons-material/Notifications';
import ListItemAvatar
    from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';

import Collapse from '@mui/material/Collapse';
import
InboxIcon from '@mui/icons-material/Inbox';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Timeline_Demand from '../timeline';
import Notifications_Sidebar from '../notifications_sidebar';
import Stepper_Project from '../stepper_project';
import System_Project_Dialog from '../system_project_dialog';
import Updade_Art from '../update_art';
import { GoogleMap,Marker } from '@react-google-maps/api';
import RateioForm from '../rateio_form';
import Edit_System_Project_Dialog from '../system_project_edit_dialog';
import Form_Highest_10 from '../entry_form/form+10';
import Form_Minor_10 from '../entry_form/form-10';


function Start_and_Follow(props) {
    const user = JSON.parse(localStorage.getItem('token'))
    const token = user.token
    const project_type = (project) => {
        if (project === 'b1_residencial') return 'B1 - Residencial'
        if (project === 'b2_rural') return 'B2 - Rural'
        if (project === 'b2_cooperativa') return 'B2 - Cooperativa'
        if (project === 'b2_servico_publico_de_irrigaca') return 'B2 - Serviço Público de Irrigação'
        if (project === 'b3_demais_classes') return 'B3 - Demais Classes'
        if (project === 'a4_horaria_verde') return 'A4 - Horária Verde'
        if (project === 'a4_horaria_azul') return 'A4 - Horária Azul'
    }
    const service_type = (service) => {
        if (service === 'monofasico') return 'Monofásico'
        if (service === 'bifasico') return 'Bifásico'
        if (service === 'trifasico') return 'Trifásico'
    }
    const branch_type = (branch) => {
        if (branch === 'aereo') return 'Aéreo'
        if (branch === 'subterraneo') return 'Subterrâneo'
    }
    const modality_type = (modality) => {
        if (modality === 'geracao_local') return 'Geração Local'
        else return 'Autoconsumo Remoto'
    }
    //Open Dialog

    const [src, setSrc] = React.useState('')
    const [typeFile, setTypeFile] = React.useState('')
    const [typeFileName, setTypeFileName] = React.useState('')
    const [open, setOpen] = React.useState(false);
    const [title, setTitle] = React.useState('')

    const handleClickOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
        setSrc('')
        setTypeFile('')
        setTitle('')
    }

    // Funções para abrir e fechar o Dialog de Notificações
    const [openNotifications, setOpenNotifications] = React.useState(false);

    const handleNotifications = () => {
        setOpenNotifications((prev) => !prev);
    };

    const [openProject, setOpenProject] = React.useState(false);

    const handleClickProject = () => {
        setOpenProject((prev) => !prev);
    }

    //Funções para abrir e fechar o Dialog de ART

    const [openArt, setOpenArt] = React.useState(false);

    const handleClickArt = () => {
        setOpenArt(true);
    }

    const closeArt = () => {
        setOpenArt(false);
    }


    //Função para abrir e fechar o Dialog Rateio

    const [openRateio, setOpenRateio] = React.useState(false);

    const handleClickRateio = () => {
        setOpenRateio(true);
    }

    const closeRateio = () => {
        setOpenRateio(false);
    }

    //função para gerar formulário maior que 10 kw

    const [openFormPlus10, setOpenFormPlus10] = React.useState(false);

    const handleClickFormPlus10 = () => {
        setOpenFormPlus10(true);
    }

    const closeFormPlus10 = () => {
        setOpenFormPlus10(false);
    }

    //função para gerar formulário menor que 10 kw

    const [openFormMinor10, setOpenFormMinor10] = React.useState(false);

    const handleClickFormMinor10 = () => {
        setOpenFormMinor10(true);
    }

    const closeFormMinor10 = () => {
        setOpenFormMinor10(false);
    }

  


           //Passando de JSON para Array
           let arrayModules = []
           let arrayInverters = []
           if(props.data.info_module_demand){
             arrayModules = JSON.parse(props.data.info_module_demand) 
             arrayInverters = JSON.parse(props.data.info_inverter_demand)
           }      

           //função para atualizar dados
           const [reload, setReload] = React.useState(false)
           const refresh = () => {
                setReload(!reload)
              }
 
              


              //Mapa
              const center = {
                lat : parseFloat(props.data.lat_demand),
                lng : parseFloat(props.data.log_demand),
            }
            let inverters
          if(props.data)   inverters = JSON.parse(props.data?.array_inverters_project)
            
            const result_maximum_photovoltaic_power = inverters?.map((item) => item.maximum_photovoltaic_power).reduce((acc, item) => acc + parseFloat(item), 0)
            const result_maximum_power = inverters?.map((inversor) => inversor.usinaFotovoltaica.map((mod) => (mod.qtd)*(mod.maximum_power)).reduce((acc, curr) => acc + curr, 0)).reduce((acc, curr) => acc + curr, 0)/1000
         
            let result_minor
        if(result_maximum_photovoltaic_power > result_maximum_power) result_minor = result_maximum_power
        else result_minor = result_maximum_photovoltaic_power
        
          

    return (
        <Dialog
            open={props.open}
            onClose={props.close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="100%"
            sx={{ '& .MuiDialog-paper': { padding: "0 0 0 20px", backgroundColor: '#f5f5f5' } }}
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{"Projeto Solar"}</DialogTitle>
            <DialogContent >

                <br />

                <Form_Highest_10 open={openFormPlus10} close={closeFormPlus10} data={props.data} refresh={refresh} result_minor={result_minor} />
                <Form_Minor_10 open={openFormMinor10} close={closeFormMinor10} data={props.data} refresh={refresh} result_minor={result_minor} />
                <Preview open={open} close={handleClose} title={title} src={src} typeFile={typeFile} typeFileName={typeFileName} data={props.data} refresh={refresh} />
                <RateioForm open={openRateio} close={closeRateio} data={props.data} />
                <System_Project_Dialog open={openProject} handleClose={handleClickProject} data={props.data} />
               
                <Grid container spacing={2} sx={{ backgroundColor: '#f5f5f5', gap: 1 }}>
                    <Grid item xs={12} sm={3} sx={{ backgroundColor: 'white' }}>
                        <Typography variant="h6" component="div" sx={{ p: '5px', fontSize: 15 }}>Procurando título</Typography>
                        <List>
                            <Paper sx={{ padding: 2, margin: 1 }}>
                                <Typography variant="h6" component="div" sx={{ p: '5px', fontSize: 15 }}>Enviar Notificação</Typography>
                                <TextField
                                    margin="dense"
                                    id="outlined-basic"
                                    label="Notificação"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={3}
                                    rowsMax={3}
                                />
                                <Button size='small' variant="contained" color="primary" fullWidth>Enviar</Button>

                            </Paper>

                        </List>

                        <Notifications_Sidebar handleClick={handleNotifications} open={openNotifications} />

                        <List>
                            <Paper sx={{ padding: 2, margin: 1 }}>
                                <Typography variant="h6" component="div" sx={{ p: '5px', fontSize: 15 }}>Documentos</Typography>
                                <ListItem>
                                    <Button size='small' variant="contained" color="primary" fullWidth
                                        disabled={props.data.data_descriptive_memorial ? false : true}
                                        id='btn-m-d'
                                        onClick={() => {

                                            setTimeout(() => {
                                                const response = fetch(`http://15.228.8.54:5000/api/demand_project_solar/download/descriptive_memorial/${props.data.id_demand}`, {
                                                    method: 'GET',
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Authorization': `Bearer ${token}`
                                                    }
                                                }).then((response) => response.blob())
                                                    .then((blob) => {
                                                        
                                                        setTypeFile(blob.type.split('/')[1])
                                                         setTypeFileName(blob.type.split('/')[0])
                                                        const url = window.URL.createObjectURL(new Blob([blob]));
                                                        setSrc(url)
                                                    }).catch((error) => {
                                                        console.log(error)
                                                    })
                                            }, 1000)

                                            setTitle('MEMORIAL DESCRITIVO')
                                            handleClickOpen()
                                        }}
                                    >memorial descritivo</Button>

                                </ListItem>
                              { result_minor && result_minor <= 10 
                              ?
                              <ListItem>
                                    <Button disabled={props.data.data_descriptive_memorial ? false : true} name='-10' size='small' variant="contained" color="primary" fullWidth 
                                        onClick={() => {
                                            handleClickFormMinor10()
                                        }}
                                    >formulário de ingresso</Button>

                                </ListItem>
                                :
                                <ListItem>
                                    <Button disabled={props.data.data_descriptive_memorial ? false : true} name='+10' size='small' variant="contained" color="primary" fullWidth 
                                        onClick={() => {
                                            handleClickFormPlus10()
                                        }}
                                    >formulário de ingresso</Button>

                                </ListItem>}
                                <ListItem>
                                    <Button size='small' variant="contained" color="primary" fullWidth disabled={props.data.data_art_demand ? false : true}
                                        onClick={() => {

                                            setTimeout(() => {
                                                const response = fetch(`http://15.228.8.54:5000/api/demand_project_solar/download/data_art/${props.data.id_demand}`, {
                                                    method: 'GET',
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Authorization': `Bearer ${token}`
                                                    }
                                                }).then((response) => response.blob())
                                                    .then((blob) => {
                                                     
                                                        setTypeFile(blob.type.split('/')[1])
                                                         setTypeFileName(blob.type.split('/')[0])
                                                        const url = window.URL.createObjectURL(new Blob([blob]));
                                                        setSrc(url)
                                                    }).catch((error) => {
                                                        console.log(error)
                                                    })
                                            }, 1000)

                                            setTitle('ART')
                                            handleClickOpen()
                                        }}
                                    >ART</Button>

                                </ListItem>
                                <ListItem>
                                    <Button size='small' variant="contained" color="primary" fullWidth onClick={handleClickRateio}>Rateio</Button>
                                </ListItem>
                                <ListItem>
                                    <Button size='small' variant="contained" color="primary" fullWidth
                                        onClick={() => {

                                            setTimeout(() => {
                                                const response = fetch(`http://15.228.8.54:5000/api/demand_project_solar/download/doc_proxy_demand/${props.data.id_demand}`, {
                                                    method: 'GET',
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Authorization': `Bearer ${token}`
                                                    }
                                                }).then((response) => response.blob())
                                                    .then((blob) => {
                                                        
                                                        setTypeFile(blob.type.split('/')[1])
                                                         setTypeFileName(blob.type.split('/')[0])
                                                        const url = window.URL.createObjectURL(new Blob([blob]));
                                                        setSrc(url)
                                                    }).catch((error) => {
                                                        console.log(error)
                                                    })
                                            }, 1000)

                                            setTitle('PROCURAÇÃO ASSINADA')
                                            handleClickOpen()
                                        }}
                                    >Procuração</Button>

                                </ListItem>
                              



                                <ListItem>
                                    <Button size='small' variant="contained" color="primary" fullWidth
                                        onClick={() => {
                                            setTimeout(() => {
                                                const response = fetch(`http://15.228.8.54:5000/api/demand_project_solar/download/rg_cnh/${props.data.id_demand}`, {
                                                    method: 'GET',
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Authorization': `Bearer ${token}`
                                                    }
                                                }).then((response) => response.blob())
                                                    .then((blob) => {
                                                        setTypeFile(blob.type.split('/')[1])
                                                         setTypeFileName(blob.type.split('/')[0])
                                                        const url = window.URL.createObjectURL(new Blob([blob]));
                                                        setSrc(url)
                                                    }).catch((error) => {
                                                        console.log(error)
                                                    })

                                            }, 1000)
                                            setTitle('RG / CNH')
                                            handleClickOpen()
                                        }}
                                    >RG / CNH</Button>
                                </ListItem>
                                {props.data.cnpj_customers_of_customers &&
                                    <ListItem>
                                        <Button size='small' variant="contained" color="primary" fullWidth
                                            onClick={() => {
                                                setTimeout(() => {
                                                    const response = fetch(`http://15.228.8.54:5000/api/demand_project_solar/download/contract_social/${props.data.id_demand}`, {
                                                        method: 'GET',
                                                        headers: {
                                                            'Content-Type': 'application/json',
                                                            'Authorization': `Bearer ${token}`
                                                        }
                                                    }).then((response) => response.blob())
                                                        .then((blob) => {
                                                            setTypeFile(blob.type.split('/')[1])
                                                             setTypeFileName(blob.type.split('/')[0])
                                                            const url = window.URL.createObjectURL(new Blob([blob]));
                                                            setSrc(url)

                                                        }).catch((error) => {
                                                            console.log(error)
                                                        })

                                                }, 1000)
                                                setTitle('CONTRATO SOCIAL')
                                                handleClickOpen()
                                            }}
                                        >Contrato Social</Button>
                                    </ListItem>}
                                <ListItem>
                                    <Button size='small' variant="contained" color="primary" fullWidth
                                        onClick={() => {
                                            setTimeout(() => {
                                                const response = fetch(`http://15.228.8.54:5000/api/demand_project_solar/download/electricity_bill/${props.data.id_demand}`, {
                                                    method: 'GET',
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Authorization': `Bearer ${token}`
                                                    }
                                                }).then((response) => response.blob())
                                                    .then((blob) => {
                                                        setTypeFile(blob.type.split('/')[1])
                                                         setTypeFileName(blob.type.split('/')[0])
                                                        const url = window.URL.createObjectURL(new Blob([blob]));
                                                        setSrc(url)
                                                    }).catch((error) => {
                                                        console.log(error)
                                                    })

                                            }, 1000)
                                            setTitle('CONTA DE LUZ')
                                            handleClickOpen()
                                        }}
                                    >Conta de Luz</Button>
                                </ListItem>
                                <ListItem>
                                    <Button size='small' variant="contained" color="primary" fullWidth
                                        onClick={() => {
                                            setTimeout(() => {
                                                const response = fetch(`http://15.228.8.54:5000/api/demand_project_solar/download/photos_breaker/${props.data.id_demand}`, {
                                                    method: 'GET',
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Authorization': `Bearer ${token}`
                                                    }
                                                }).then((response) => response.blob())

                                                    .then((blob) => {
                                                        setTypeFile(blob.type.split('/')[1])
                                                         setTypeFileName(blob.type.split('/')[0])
                                                        const url = window.URL.createObjectURL(new Blob([blob]));
                                                        setSrc(url)
                                                    }).catch((error) => {
                                                        console.log(error)
                                                    })
                                            }, 1000)

                                            setTitle('DISJUNTOR')
                                            handleClickOpen()
                                        }}
                                    >Disjuntor</Button>

                                </ListItem>
                                <ListItem>
                                    <Button size='small' variant="contained" color="primary" fullWidth
                                        onClick={() => {
                                            setTimeout(() => {
                                                const response = fetch(`http://15.228.8.54:5000/api/demand_project_solar/download/energy_box_photos/${props.data.id_demand}`, {
                                                    method: 'GET',
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Authorization': `Bearer ${token}`
                                                    }
                                                }).then((response) => response.blob())
                                                    .then((blob) => {
                                                        setTypeFile(blob.type.split('/')[1])
                                                         setTypeFileName(blob.type.split('/')[0])
                                                        const url = window.URL.createObjectURL(new Blob([blob]));
                                                        setSrc(url)
                                                    }).catch((error) => {
                                                        console.log(error)
                                                    })

                                            }, 1000)
                                            setTitle('CAIXA DE ENERGIA')
                                            handleClickOpen()
                                        }}
                                    >Caixa de Energia</Button>

                                </ListItem>
                                <ListItem>
                                    <Button size='small' variant="contained" color="primary" fullWidth disabled={props.data.info_data_photovoltaic_demand ? false : true}
                                        onClick={() => {
                                            setTimeout(() => {
                                                const response = fetch(`http://15.228.8.54:5000/api/demand_project_solar/download/data_photovoltaic/${props.data.id_demand}`, {
                                                    method: 'GET',
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Authorization': `Bearer ${token}`
                                                    }
                                                }).then((response) => response.blob())
                                                    .then((blob) => {
                                                        setTypeFile(blob.type.split('/')[1])
                                                         setTypeFileName(blob.type.split('/')[0])
                                                        const url = window.URL.createObjectURL(new Blob([blob]));
                                                        setSrc(url)
                                                    }).catch((error) => {
                                                        console.log(error)
                                                    })

                                            }, 1000)
                                            setTitle('INFORMAÇÕES FOTOVOLTAICAS')
                                            handleClickOpen()
                                        }}
                                    >Informações Fotovoltaicas</Button>

                                </ListItem>
                            </Paper>
                        </List>
                        <List>
                            <Paper sx={{ padding: 2, margin: 1 }}>
                                <Typography variant="h6" component="div" sx={{ p: '5px', fontSize: 15 }}>Linha do Tempo</Typography>
                                <Timeline_Demand />
                            </Paper>
                        </List>
                    </Grid>

                    <Grid item xs={12} sm={8.8} >
                        {
                            //voltar para ajeitar isso
                        }
                    
                    
                      
                    <Grid>
                    <Button size='small' variant="contained" color="primary" fullWidth onClick={handleClickProject}>Gerar Projeto</Button>
                </Grid>
                    
                    <Grid>
                            <Button size='small' variant="contained" color="primary" fullWidth onClick={handleClickArt}>Enviar Art</Button>
                            <Updade_Art open={openArt} close={closeArt} id={props.data.id_demand} refresh={refresh} />

                        </Grid>

                        {
                            //pensar em uma forma efetiva de atualizar os dados o stepper
                        }
                        <Stepper_Project data={props.data} />
                       


                        <br />
                        <Grid sx={{ backgroundColor: 'white' }}>



                            <TableContainer component={Paper} >
                                <Typography variant="h6" component="div" sx={{ p: '5px', fontSize: 16, mb: 2 }}>Cliente Integrador</Typography>
                                <Table sx={{ minWidth: 500, height: 50 }} aria-label="simple table" >
                                    <TableHead >
                                        <TableRow>
                                            <TableCell sx={{ p: '5px' }} align="center">{props.data.cpf ? 'Nome' : 'Rasão Social'}</TableCell>
                                            <TableCell sx={{ p: '5px' }} align="center">{props.data.cpf ? 'CPF' : 'CNPJ'}</TableCell>
                                            <TableCell sx={{ p: '5px' }} align="center">E-mail</TableCell>
                                            <TableCell sx={{ p: '5px' }} align="center">Contato</TableCell>
                                            <TableCell sx={{ p: '5px' }} align="center">Endereço</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell sx={{ p: '5px', fontSize: 12 }} align="center" component="th" scope="row">
                                                {props.data.name ? props.data.name : props.data.corporate_reason}
                                            </TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.cpf ? props.data.cpf : props.data.cnpj}</TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.email}</TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.contact}</TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 10 }} align="center">{`${props.data.street}, ${props.data.number} - ${props.data.neighborhood}, ${props.data.city} - ${props.data.state}`}</TableCell>

                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {props.data.cnpj &&
                                <TableContainer component={Paper}>
                                    <Typography variant="h6" component="div" sx={{ p: '5px', fontSize: 15 }}>Representante Legal</Typography>
                                    <Table sx={{ minWidth: 500, height: 50 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ p: '5px' }} align="center">Nome</TableCell>
                                                <TableCell sx={{ p: '5px' }} align="center">CPF</TableCell>
                                                <TableCell sx={{ p: '5px' }} align="center">Profissão</TableCell>
                                                <TableCell sx={{ p: '5px' }} align="center">Nacionalidade</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow
                                                key={props.data.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell sx={{ p: '5px', fontSize: 13 }} align="center" component="th" scope="row">
                                                    {props.data.name_representative}
                                                </TableCell>
                                                <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.cpf_representative}</TableCell>
                                                <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.profession_representative}</TableCell>
                                                <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.nationality_representative}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>}
                            <br />
                            <br />
                            <Typography variant="h6" component="div" sx={{ p: '5px', fontSize: 18 }}>Dados do Projeto</Typography>
                            <TableContainer component={Paper}>
                                <Typography variant="h6" component="div" sx={{ p: '5px', fontSize: 16, mb: 2 }}>Cliente Terceiro</Typography>
                                <Table sx={{ minWidth: 500, height: 50 }} aria-label="simple table" >
                                    <TableHead >
                                        <TableRow>
                                            <TableCell sx={{ p: '5px' }} align="center">{props.data.cpf_customers_of_customers ? 'Nome' : 'Rasão Social'}</TableCell>
                                            <TableCell sx={{ p: '5px' }} align="center">{props.data.cpf_customers_of_customers ? 'CPF' : 'CNPJ'}</TableCell>
                                            <TableCell sx={{ p: '5px' }} align="center">E-mail</TableCell>
                                            <TableCell sx={{ p: '5px' }} align="center">Contato</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell sx={{ p: '5px', fontSize: 12 }} align="center" component="th" scope="row">
                                                {props.data.name_customers_of_customers ? props.data.name_customers_of_customers : props.data.corporate_reason_customers_of_customers}
                                            </TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.cpf_customers_of_customers ? props.data.cpf_customers_of_customers : props.data.cnpj_customers_of_customers}</TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.email_customers_of_customers}</TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.contact_customers_of_customers}</TableCell>

                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>


                            <br />
                            {props.data.cnpj_customers_of_customers &&
                                <TableContainer component={Paper}>
                                    <Typography variant="h6" component="div" sx={{ p: '5px', fontSize: 16, mb: 2 }}>Representante Legal</Typography>
                                    <Table sx={{ minWidth: 500, height: 50 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ p: '5px' }} align="center">Nome</TableCell>
                                                <TableCell sx={{ p: '5px' }} align="center">CPF</TableCell>
                                                <TableCell sx={{ p: '5px' }} align="center">Profissão</TableCell>
                                                <TableCell sx={{ p: '5px' }} align="center">Nacionalidade</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow
                                                key={props.data.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell sx={{ p: '5px', fontSize: 13 }} align="center" component="th" scope="row">
                                                    {props.data.name_representative_customers_of_customers}
                                                </TableCell>
                                                <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.cpf_representative_customers_of_customers}</TableCell>
                                                <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.profession_representative_customers_of_customers}</TableCell>
                                                <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.nationality_representative_customers_of_customers}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>}
                          
                            <br />

                            <TableContainer component={Paper}>
                                <Typography variant="h6" component="div" sx={{ p: '5px', fontSize: 16, mb: 2 }}>Informações do Projeto</Typography>
                                <Table x={{ minWidth: 600, height: 50 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ p: '5px' }} align="center">N° UC</TableCell>
                                            <TableCell sx={{ p: '5px' }} align="center">Tipo de Projeto</TableCell>
                                            <TableCell sx={{ p: '5px' }} align="center">Atendimento</TableCell>
                                            <TableCell sx={{ p: '5px' }} align="center">Tipo de Ramal</TableCell>
                                            <TableCell sx={{ p: '5px' }} align="center">Modalidade</TableCell>
                                            <TableCell sx={{ p: '5px' }} align="center">Disjuntor</TableCell>
                                            <TableCell sx={{ p: '5px' }} align="center">Tensão da Rede</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell   align="center"sx={{ p: '5px', fontSize: 13 }} component="th" scope="row">
                                                {props.data.number_uc_demand}
                                            </TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{project_type(props.data.project_type_demand)}</TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{service_type(props.data.service_type_demand)}</TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{branch_type(props.data.branch_type_demand)}</TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{modality_type(props.data.modality_type_demand)}</TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.disjuntor_demand}</TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.power_rede_demand}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>


                            <br />

                            <TableContainer component={Paper}>
                                <Typography variant="h6" component="div" sx={{ p: '5px', fontSize: 16, mb: 2 }}>Módulos:</Typography>
                                <Table x={{ minWidth: 600, height: 50 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ p: '5px' }} align="center">Marca</TableCell>
                                            <TableCell sx={{ p: '5px' }} align="center">Modelo</TableCell>
                                            <TableCell sx={{ p: '5px' }} align="center">Quantidade</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    {arrayModules?.map((item, index) => (
                                        <TableBody key={index}>

                                            <TableRow
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell align="center" sx={{ p: '5px', fontSize: 13 }} component="th" scope="row">
                                                    {item.marca}
                                                </TableCell>
                                                <TableCell align="center" sx={{ p: '5px', fontSize: 13 }}>{item.modelo}</TableCell>
                                                <TableCell  sx={{ p: '5px', fontSize: 13 }} align="center">{item.quantidade}</TableCell>

                                            </TableRow>
                                        </TableBody>
                                    ))}
                                </Table>
                            </TableContainer>
                            <br />



                            <TableContainer component={Paper}>
                                <Typography variant="h6" component="div" sx={{ p: '5px', fontSize: 16, mb: 2 }}>Inversores:</Typography>
                                <Table x={{ minWidth: 600, height: 50 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ p: '5px' }} align="center">Marca</TableCell>
                                            <TableCell sx={{ p: '5px' }} align="center">Modelo</TableCell>
                                            <TableCell sx={{ p: '5px' }} align="center">Quantidade</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    {arrayInverters?.map((item, index) => (
                                        <TableBody key={index}>
                                            <TableRow
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell align="center" sx={{ p: '5px', fontSize: 13 }} component="th" scope="row">
                                                    {item.marca}
                                                </TableCell>
                                                <TableCell align="center" sx={{ p: '5px', fontSize: 13 }}>{item.modelo}</TableCell>
                                                <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{item.quantidade}</TableCell>

                                            </TableRow>
                                        </TableBody>
                                    ))}
                                </Table>
                            </TableContainer>

                            <br />
                            <TableContainer component={Paper}>
                                <Typography variant="h6" component="div" sx={{ p: '5px', fontSize: 16, mb: 2 }}>Endereço do Projeto</Typography>
                                <Table sx={{ minWidth: 500, height: 50 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ p: '5px' }} align="center">Logradouro</TableCell>
                                            <TableCell sx={{ p: '5px' }} align="center">Numero</TableCell>
                                            <TableCell sx={{ p: '5px' }} align="center">Bairro</TableCell>
                                            <TableCell sx={{ p: '5px' }} align="center">Cidade</TableCell>
                                            <TableCell sx={{ p: '5px' }} align="center">Estado</TableCell>
                                            <TableCell sx={{ p: '5px' }} align="center">CEP</TableCell>
                                            <TableCell sx={{ p: '5px' }} align="center">Latitude</TableCell>
                                            <TableCell sx={{ p: '5px' }} align="center">Longitude</TableCell>
                                            
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow
                                            key={props.data.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell sx={{ p: '5px', fontSize: 13 }} component="th" scope="row">
                                                {props.data.street_service_demand}
                                            </TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.number_service_demand}</TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.district_service_demand}</TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.city_service_demand}</TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.state_service_demand}</TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.cep_service_demand}</TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 11 }} align="center">{props.data.lat_demand}</TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 11 }} align="center">{props.data.log_demand}</TableCell>

                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <br />
                            <br />
                            <GoogleMap
          mapContainerStyle={{ height: '400px',width:'100%' }} // Ajuste a altura conforme necessário
          zoom={18}
          options={{
            disableDefaultUI: true,
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: true,
            rotateControl: true,
            fullscreenControl: true,
            mapTypeId: 'satellite',
            streetViewControl: true,
            draggable: true,
            inclination: 0,
            heading: 0,
            tilt: 0,
            zoom: 19,
          }}
          center={center} 
        >
            <Marker position={center}/>
        </GoogleMap>
                        </Grid>
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.close} color="primary"> Fechar </Button>

            </DialogActions>
        </Dialog>
    )
}

export default Start_and_Follow
