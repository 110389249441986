    import * as React from 'react';
    import { Grid,Dialog, DialogContent, RadioGroup, FormControlLabel, Radio, Button, DialogActions, List, ListItem, ListItemText, Paper, Typography, Alert, TextField } from '@mui/material';
   //html to pdf
    import html2pdf from 'html2pdf.js'
import { toast } from 'react-toastify';
//icons mui 
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';
//Logo Enel
import logoEnel from '../../assets/enel_rateio.png'
import Loading from '../loading';
//html to docx


    const RateioForm = (props) => {
//função para transformar html em docx



  
const project_type =(project)=>{
  if(project === 'b1_residencial') return 'B1 - Residencial'
  if(project === 'b2_rural') return 'B2 - Rural'
  if(project === 'b2_cooperativa') return 'B2 - Cooperativa'
  if(project === 'b2_servico_publico_de_irrigaca') return 'B2 - Serviço Público de Irrigação'
  if(project === 'b3_demais_classes') return 'B3 - Demais Classes'
  if(project === 'a4_horaria_verde') return 'A4 - Horária Verde'
  if(project === 'a4_horaria_azul') return 'A4 - Horária Azul'
}
 


      //Selecionar a ação ratio
      const [value, setValue] = React.useState('inclusao');
      const [include, setInclude] = React.useState(true);
      const [change, setChange] = React.useState(false);
      const [exclude, setExclude] = React.useState(false);

      const handleChange = (event) => {
        setValue(event.target.value);
        if(event.target.value === 'inclusao'){
          setInclude(true)
          setChange(false)
          setExclude(false)
        }
        if(event.target.value === 'alteracao'){
          setInclude(false)
          setChange(true)
          setExclude(false)
        }
        if(event.target.value === 'exclusao'){
          setInclude(false)
          setChange(false)
          setExclude(true)
        }

        
      };

    

      const [isLoading, setIsLoading] = React.useState(false)

      const handlePdfDownloadRateio = () => {
        setIsLoading(true)
        update_latest_info_rateio_demand()
        const conteudo = document.querySelector('.body-rateio')
        const pdf = conteudo
        const opt = {
          margin: 0,
          filename: `RATEIO-${
                          props.data.uc_registered_user === 'cliente_terceiro' 
                          ? 
                          (props.data?.cnpj_customers_of_customers 
                            ? 
                           props.data?.corporate_reason_customers_of_customers.replace(/\s/g, '-')
                            :
                            props.data?.name_customers_of_customers.replace(/\s/g, '-'))
                          :
                            ''
                          }
                          ${
                          props.data.uc_registered_user === 'representante_legal_terceiro'
                            && 
                            props.data?.name_representative_customers_of_customers.replace(/\s/g, '-')
                          }
                         .pdf`,
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        }
        html2pdf().from(pdf).set(opt).save().then(() => {
          setIsLoading(false)
          toast.success('Rateio gerado com sucesso', { autoClose: 2000 })
        }).catch((error) => {
          setIsLoading(false)
          toast.error('Erro ao gerar Rateio', { autoClose: 2000 })
        })
      }

      //Tranformando dados JSON em array
      let arrayRateio = []
      if(props.data.info_rateio_demand){
        arrayRateio = JSON.parse(props.data.info_rateio_demand)
      }
 
      let arrayRateioLatest = []
      if(props.data.latest_info_rateio_demand){
        arrayRateioLatest = JSON.parse(props.data.latest_info_rateio_demand)
      }
      //Calculos de rateio

    const [ucs, setUcs] = React.useState(['']);
    const [kwhValues, setKwhValues] = React.useState(['']);
    const [percentages, setPercentages] = React.useState([]);
    const [resultPercentages, setResultPercentages] = React.useState(0);


      const handleClear = () => {
        setUcs([]);
        setPercentages([]);
        setKwhValues([]);
        setResultPercentages(0);
    }
    const handleAddKwh = () => {
      handleClickOPSystem()
      setUcs([...ucs, '']);
      setKwhValues([...kwhValues, '']);
    };
  
    const handleRemoveKwh = (index) => {
      const newUcs = [...ucs];
      const newKwhValues = [...kwhValues];
      newUcs.splice(index, 1);
      newKwhValues.splice(index, 1);
      setUcs(newUcs);
      setKwhValues(newKwhValues);
    };
  
    const handleSubmit = () => {
      handleClickOPSystem()
      const totalKwh = kwhValues.reduce((acc, curr) => acc + parseFloat(curr), 0);
      const newPercentages = kwhValues.map(value => ((parseFloat(value) / totalKwh) * 100));
  
      // Adjust last percentage for 100% sum
      const adjustedPercentages = [...newPercentages];
      setPercentages(adjustedPercentages);
      let totalPercentage = adjustedPercentages.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr), 0);
  
      adjustedPercentages.forEach((percentage, index) => {
        adjustedPercentages[index] = parseFloat(percentage).toFixed(2);
      });
  
      if (totalPercentage !== 100) {
        adjustedPercentages[adjustedPercentages.length - 1] = parseFloat(adjustedPercentages[adjustedPercentages.length - 1]) + (100 - totalPercentage);
        setPercentages(adjustedPercentages);
      }
  
      adjustedPercentages.forEach((percentage, index) => {
        adjustedPercentages[index] = parseFloat(percentage).toFixed(2);
      });
  
      if (totalPercentage !== 100) {
        if (totalPercentage < 100) {
          adjustedPercentages[adjustedPercentages.length - 1] = parseFloat(adjustedPercentages[adjustedPercentages.length - 1]) + 1;
          setPercentages(adjustedPercentages);
        } else {
          adjustedPercentages[adjustedPercentages.length - 1] = parseFloat(adjustedPercentages[adjustedPercentages.length - 1]) - 1;
          setPercentages(adjustedPercentages);
        }
      }
  
      setResultPercentages(totalPercentage.toFixed(2));


    };

    const newArrayRateio = ucs.map((item, index) => {
      return { uc: item, kwh: kwhValues[index], percentage: percentages[index] }
    })


    const update_latest_info_rateio_demand = async () => {
      let rateio 

      if(opCustomer) rateio = arrayRateio
      if(opSystem) rateio = newArrayRateio
      if(opLatest) rateio = arrayRateioLatest


      await fetch(`http://15.228.8.54:5000/api/demand_project_solar/update-info-latest-rateio/${props.data.id_demand}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ latest_info_rateio_demand: JSON.stringify(rateio) })
      }).then(response => response.json())
        .then(data => {
          if (data.message === 'success') {
            
          }
          document.getElementById('bt-search').click()
          setTimeout(() => {
            document.getElementById(`${props.data.id_demand}`).click()
          }, 100)
        }).catch(err => {
         
        })
    }



    const [opCustomer, setOpCustomer] = React.useState(false)
    const [opSystem, setOpSystem] = React.useState(false)
    const [opLatest, setOpLatest] = React.useState(false)
    const handleClickOPCustomer = () => {
      setOpCustomer(true)
      setOpSystem(false)
      setOpLatest(false)
    }
    const handleClickOPSystem = () => {
      setOpSystem(true)
      setOpCustomer(false)
      setOpLatest(false)
    }
    const handleClickOPLatest = () => {
      setOpLatest(true)
      setOpCustomer(false)
      setOpSystem(false)
    }


    const isDisabled = () => {

   if(!opCustomer && !opSystem && !opLatest){

    return true
  }
  if(opCustomer && arrayRateio.length === 0){

      return true
    }
    if(opSystem && newArrayRateio.length === 0){

      return true
    }
    if(opLatest && arrayRateioLatest.length === 0){

      return true
    } 
    if( opSystem && resultPercentages !== '100.00'){
      return true
    } 
    return false
  }

      return (

        <Dialog
        open={props.open}
        onClose={props.close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="lg"
    >
        {isLoading && <Loading/>}
      <DialogContent>
        
        <Grid container spacing={2}  justifyContent={'center'}>

        <Grid item xs={3} style={{textAlign:'center'}} justifyContent={'center'} sx={{backgroundColor:'#f5f5f5',padding:'10px'}}>
          <Paper sx={{ padding: 0, margin: 0 }}>
            <ListItemText primaryTypographyProps={{variant:'h6'}} primary={'Rateio'} secondary={'Solicitado pelo cliente'} />
          <List>
            { arrayRateio.length > 0 ?
   
            (
              arrayRateio.map((item, index) => (
                <ListItem key={index}>
                  <ListItemText primary={'UC: ' + item.uc } secondary={'kWh: '+item.kwh+' -  Porcentagem: ' + item.percentage + '%'} />
                </ListItem>
              )) 
            )
      
              :
              <Typography variant="body2" color="text.secondary">
                Nenhum rateio cadastrado
              </Typography>


          }
          </List>
        </Paper>
        <br />
        <Paper sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"

        value={value}
        onChange={handleChange}
      
      >

        <FormControlLabel value="inclusao"  control={<Radio />} label="Inclusão" />
        <FormControlLabel value="alteracao" control={<Radio />} label="Alteração" />
        <FormControlLabel value="exclusao" control={<Radio />} label="Exclusão" />
      </RadioGroup>
      </Paper>
      <br />
      <Paper>
      <div>
    <Alert severity="info">O resultado das porcentagens precia ser 100%</Alert>
    <br />
    <Button variant='contained' color='primary' fullWidth onClick={handleClickOPCustomer}>copiar dados do cliente</Button>
    <br />
    {ucs?.map((uc, index) => (
      <Grid container key={index} spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={5}>
          <TextField size='small' label={`UC ${index + 1}`} value={uc} onChange={(e) => {
            e.target.value = e.target.value.replace(/\D/g, '');
            const newUcs = [...ucs];
            newUcs[index] = e.target.value;
            setUcs(newUcs);
          }} />
        </Grid>
        <Grid item xs={4}>
          <TextField size='small' label={`kWh ${index + 1}`} type="number" value={kwhValues[index]} onChange={(e) => {
            const newKwhValues = [...kwhValues];
            newKwhValues[index] = e.target.value;
            setKwhValues(newKwhValues);
          }} />
        </Grid>
        <Grid item xs={0.5}>
          <Button onClick={() => handleRemoveKwh(index)}><DeleteIcon /></Button>
        </Grid>
      </Grid>
    ))}
    <br />
    <Button onClick={handleAddKwh} >Adicionar Unidade Cosumidora (UC)</Button>
    <Button onClick={handleSubmit} variant='contained' color='primary'>gerar</Button>
    <Button onClick={handleClear} variant='contained' color='error' sx={{ ml: 1 }}>Limpar</Button>
    <br />
    <br />
    {percentages?.map((percentage, index) => (
      <Typography key={index} variant="body2" color="text.secondary">
      UC: {ucs[index]} - Porcentagem: {percentage}%
      </Typography>
    ))}
       <Typography variant="body2" color="text.secondary">
      Porcentagem Total: {resultPercentages}%
    </Typography>
    <br />
  </div>
      </Paper>
      <br />
      <Paper sx={{ padding: 0, margin: 0 }}>
            <ListItemText primaryTypographyProps={{variant:'h6'}} primary={'Rateio'} secondary={'Últimas informações salvas'} />
          <List>
            { arrayRateioLatest.length > 0 ?
   
            (
              arrayRateioLatest.map((item, index) => (
                <ListItem key={index}>
                  <ListItemText primary={'UC: ' + item.uc } secondary={'kWh: '+item.kwh+' -  Porcentagem: ' + item.percentage + '%'} />
                </ListItem>
              )) 
            )
      
              :
              <Typography variant="body2" color="text.secondary">
                Nenhum rateio cadastrado
              </Typography>


          }
          </List>
          <Button variant='contained' color='primary' fullWidth  onClick={handleClickOPLatest}>usar novamente</Button>
        </Paper>
          </Grid>
        <Grid item xs={9} style={{textAlign:'center'}}>
        <br />
        <div className="body-rateio" style={{maxHeight:'1026px'}}>
        
        
        <p style={{textAlign:'center',fontFamily:'sans-serif',fontSize:'7.5pt',marginBottom:'-40px',marginTop:'20px'}}>INTERNAL</p>
          <div style={{ gap: '00px',padding:'0px 0px 20px 190px',display:'flex',justifyContent:'center',alignItems:'center' }} >
            <p style={{ textIndent: "0pt", textAlign: "center", paddingLeft:'50px',marginRight:'100px' }}>
              <span>
                <img
                  width={128}
                  height={47}
                  alt="image"
                  src={logoEnel}
                />
              </span>
            </p>
            <div>
        
              <p
                className="s1"
                style={{ paddingTop: "80pt", textIndent: "0pt", textAlign: "right" ,fontSize:'8pt' }}
              >
               <strong> Enel Distribuição Ceará</strong>
              </p>
         
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </div>
          </div>
         <h6
            style={{
              paddingTop: "11pt",
              paddingLeft: "20pt",
              textIndent: "0pt",
              textAlign: "center",
              fontSize: "18pt"
            }}
          >
            Formulário de Solicitação de Rateio - GD
          </h6>
          <p style={{textAlign:'center'}}>
          <p style={{ textIndent: "0pt", textAlign: "center" }}>
            <br />
            <br />
          </p>

          <Grid container spacing={2} alignItems={'center'} justifyContent={'center'}>
          <ol >
            <li >
              <h2
                style={{ paddingLeft: "48pt", textIndent: "48pt", textAlign: "left", fontSize: "12pt" }}
              >
               1. Identificação da Unidade Consumidora (UC):
              </h2>
              <p style={{ textIndent: "0pt", textAlign: "center" }}>
                <br />
              </p>
              <table
                style={{ borderCollapse: "collapse", marginLeft: "50pt" }}
                cellSpacing={0}
              >
                <tbody>
                  <tr style={{ height: "15pt" }}>
                    <td
                      style={{
                        width: "218pt",
                        borderBottomStyle: "solid",
                        borderBottomWidth: "1pt"
                      }}
                    >
                      <p
                        className="s3"
                        style={{
                          paddingLeft: "6pt",
                          textIndent: "0pt",
                          lineHeight: "12pt",
                          textAlign: "left"
                        }}
                      >
                        Código da UC: <span style={{ fontSize: '9pt' }}>{props.data?.number_uc_demand} </span>
                      </p>
                    </td>
                    <td
                      style={{
                        width: "256pt",
                        borderBottomStyle: "solid",
                        borderBottomWidth: "1pt"
                      }}
                    >
                      <p
                        className="s3"
                        style={{
                          paddingLeft: "70pt",
                          textIndent: "0pt",
                          lineHeight: "12pt",
                          textAlign: "left"
                        }}
                      >
                        Classe: <span style={{ fontSize: '9pt' }}> {project_type(props.data?.project_type_demand)} </span>
                      </p>
                    </td>
                  </tr>
                  <tr style={{ height: "14pt" }}>
                    <td
                      style={{
                        width: "218pt",
                        borderTopStyle: "solid",
                        borderTopWidth: "1pt",
                        borderBottomStyle: "solid",
                        borderBottomWidth: "1pt"
                      }}
                    >
                      <p
                        className="s3"
                        style={{
                          paddingLeft: "5pt",
                          textIndent: "0pt",
                          lineHeight: "12pt",
                          textAlign: "left"
                        }}
                      >
                        Titular da UC: <span style={{ fontSize: '9pt' }}>
                          {
                          props.data.uc_registered_user === 'cliente_terceiro' 
                          ? 
                          (props.data?.cnpj_customers_of_customers 
                            ? 
                           props.data?.corporate_reason_customers_of_customers
                            :
                            props.data?.name_customers_of_customers)
                          :
                            ''
                          }
                          {
                          props.data.uc_registered_user === 'representante_legal_terceiro'
                            && 
                            props.data?.name_representative_customers_of_customers
                          }
                         

                          </span>
                      </p>
                    </td>
                    <td
                      style={{
                        width: "256pt",
                        borderTopStyle: "solid",
                        borderTopWidth: "1pt",
                        borderBottomStyle: "solid",
                        borderBottomWidth: "1pt"
                      }}
                    >
                      <p
                        className="s3"
                        style={{
                          paddingLeft: "67pt",
                          textIndent: "0pt",
                          lineHeight: "12pt",
                          textAlign: "left"
                        }}
                      >
                        CNPJ/CPF: <span style={{ fontSize: '9pt' }}>  
                          {
                          props.data.uc_registered_user === 'cliente_terceiro' 
                          ? 
                          (props.data?.cnpj_customers_of_customers 
                            ? 
                           props.data?.cnpj_customers_of_customers
                            :
                            props.data?.cpf_customers_of_customers)
                          :
                            ''
                          }
                          {
                          props.data.uc_registered_user === 'representante_legal_terceiro'
                            && 
                            props.data?.cpf_representative_customers_of_customers
                          }
                         </span>
                      </p>
                    </td>
                  </tr>
                  <tr style={{ height: "14pt" }}>
                    <td
                      style={{
                        width: "218pt",
                        borderTopStyle: "solid",
                        borderTopWidth: "1pt",
                        borderBottomStyle: "solid",
                        borderBottomWidth: "1pt"
                      }}
                    >
                      <p
                        className="s3"
                        style={{
                          paddingLeft: "6pt",
                          textIndent: "0pt",
                          lineHeight: "12pt",
                          textAlign: "left"
                        }}
                      >
                        Endereço: <span style={{ fontSize: '9pt' }}>{props.data?.street_service_demand} - {props.data?.number_service_demand ? props.data?.number_service_demand : 'S/N'} </span>
                      </p>
                    </td>
                    <td
                      style={{
                        width: "256pt",
                        borderTopStyle: "solid",
                        borderTopWidth: "1pt",
                        borderBottomStyle: "solid",
                        borderBottomWidth: "1pt"
                      }}
                    >
                      <p
                        className="s3"
                        style={{
                          paddingLeft: "70pt",
                          textIndent: "0pt",
                          lineHeight: "12pt",
                          textAlign: "left"
                        }}
                      >
                        CEP: <span style={{ fontSize: '9pt' }}>{props.data?.cep_service_demand}</span>
                      </p>
                    </td>
                  </tr>
                  <tr style={{ height: "14pt" }}>
                    <td
                      style={{
                        width: "218pt",
                        borderTopStyle: "solid",
                        borderTopWidth: "1pt",
                        borderBottomStyle: "solid",
                        borderBottomWidth: "1pt"
                      }}
                    >
                      <p
                        className="s3"
                        style={{
                          paddingLeft: "6pt",
                          textIndent: "0pt",
                          lineHeight: "12pt",
                          textAlign: "left"
                        }}
                      >
                        Bairro: <span style={{ fontSize: '9pt' }}>{props.data?.district_service_demand}</span>
                      </p>
                    </td>
                    <td
                      style={{
                        width: "256pt",
                        borderTopStyle: "solid",
                        borderTopWidth: "1pt",
                        borderBottomStyle: "solid",
                        borderBottomWidth: "1pt"
                      }}
                    >
                      <p
                        className="s3"
                        style={{
                          paddingLeft: "70pt",
                          textIndent: "0pt",
                          lineHeight: "13pt",
                          textAlign: "left"
                        }}
                      >
                        Cidade: <span style={{ fontSize: '9pt' }}>{props.data?.city_service_demand}</span>
                      </p>
                    </td>
                  </tr>
                  <tr style={{ height: "14pt" }}>
                    <td
                      style={{
                        width: "218pt",
                        borderTopStyle: "solid",
                        borderTopWidth: "1pt",
                        borderBottomStyle: "solid",
                        borderBottomWidth: "1pt"
                      }}
                    >
                   
                      <p
                        style={{
                          paddingLeft: "6pt",
                          textIndent: "0pt",
                          lineHeight: "13pt",
                          textAlign: "left"
                        }}
                        >
                       E-mail:  <span style={{ fontSize: '9pt' }} > enel@fortesol.com.br </span>
                      </p>
              
                    </td>
                    <td
                      style={{
                        width: "256pt",
                        borderTopStyle: "solid",
                        borderTopWidth: "1pt",
                        borderBottomStyle: "solid",
                        borderBottomWidth: "1pt"
                      }}
                    >
                      <p
                        className="s3"
                        style={{
                          paddingLeft: "70pt",
                          textIndent: "0pt",
                          lineHeight: "13pt",
                          textAlign: "left"
                        }}
                      >
                          Telefone:<span style={{ fontSize: '9pt' }}> (85) 4141-4900 </span>
                      </p>
                    </td>
                  </tr>
                  <tr style={{ height: "14pt" }}>
                    <td
                      style={{
                        width: "218pt",
                        borderTopStyle: "solid",
                        borderTopWidth: "1pt",
                        borderBottomStyle: "solid",
                        borderBottomWidth: "1pt"
                      }}
                    >
                      <p
                        className="s3"
                        style={{
                          paddingLeft: "6pt",
                          textIndent: "0pt",
                          lineHeight: "12pt",
                          textAlign: "left"
                        }}
                      >
                      Celular: <span style={{ fontSize: '9pt' }}>  (85) 9.9986-0900 </span>
                      </p>
                    </td>
                    <td
                      style={{
                        width: "256pt",
                        borderTopStyle: "solid",
                        borderTopWidth: "1pt",
                        borderBottomStyle: "solid",
                        borderBottomWidth: "1pt"
                      }}
                    >
                      <p style={{ textIndent: "0pt", textAlign: "center" }}>
                        <br />
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p style={{ textIndent: "0pt", textAlign: "center" }}>
                <br />
          
              </p>
              <table style={{border:'1px solid #000',borderCollapse:'collapse',marginLeft: "50pt"}}>
                <tbody>
                  <tr>
                    <td style={{border:'1px solid #000', paddingLeft:'10px',paddingRight:'20px',textAlign:'left',minWidth:'93.4px'}}>
                      <p>Inclusão:</p>
                    </td>
                    <td style={{border:'1px solid #000', paddingLeft:'10px',paddingRight:'20px',textAlign:'center',minWidth:'93.4px'}}>
                      {include ? 'X' : ''}
                    </td>
                    </tr>
                    <tr>
                    <td style={{border:'1px solid #000', paddingLeft:'10px',paddingRight:'20px',textAlign:'left',minWidth:'93.4px'}}>
                      <p>Alteração:</p>
                    </td>
                    <td style={{border:'1px solid #000', paddingLeft:'10px',paddingRight:'20px',textAlign:'center',minWidth:'93.4px'}}>
                      {change ? 'X' : ''}
                    </td>
                    </tr>
                    <tr>
                    <td style={{border:'1px solid #000', paddingLeft:'10px',paddingRight:'20px',textAlign:'left',minWidth:'93.4px'}}>
                      <p>Exclusão:</p>
                    </td>
                    <td style={{border:'1px solid #000', paddingLeft:'10px',paddingRight:'20px',textAlign:'center',minWidth:'93.4px'}}>
                      {exclude ? 'X' : ''}
                    </td>
                    </tr>
                </tbody>
              </table>
            </li>
            <br />
            <li>
              <h2
                style={{
                  paddingTop: "10pt",
                  paddingLeft: "48pt",
                  textIndent: "48pt",
                  textAlign: "justify",
                  fontSize: "12pt"
                }}
              >
               
                2.  Lista de unidades consumidoras participantes do sistema de compensação
                  <br />
                  indicando a porcentagem de rateio dos créditos e o enquadramento:
                  <br />
        
                <span className="s5">
                  (Conforme incisos VI a VIII do art. 2º da Resolução Normativa nº
                  482/2012)
                </span>
              </h2>
            </li>
          </ol>
          <p style={{ textIndent: "0pt", textAlign: "center" }}>
            <br />
            <br />
          </p>
          <table
            style={{ borderCollapse: "collapse", marginLeft: "50pt" }}
            cellSpacing={0}
          >
            <tbody style={{ minHeight: '230px !important', maxHeight: '230px',height:'230px' }}>
        
              <tr style={{ height: "16pt" }}>
{ opCustomer && arrayRateio.length > 0 ? 
  (
    arrayRateio.map((item, index) => (
              <tr style={{ height: "16pt" }}>
                <td
                  style={{
                    width: "94pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",height:'16pt'
                  }}
                >
                  <p
                    className=""
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "6pt",
                      textIndent: "0pt",
                      textAlign: "left"
                    }}
                  >
                    UC:<span style={{ fontSize: '9pt' }}> {item.uc} </span>
                  </p>
                </td>
                <td
                  style={{
                    width: "226pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",height:'16pt'
                  }}
                >
                  <p
                    className=""
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "69pt",
                      textIndent: "0pt",
                      textAlign: "left"
                    }}
                  >
                    CNPJ/CPF:<span style={{ fontSize: '9pt' }}>  {
                          props.data.uc_registered_user === 'cliente_terceiro' 
                          ? 
                          (props.data?.cnpj_customers_of_customers 
                            ? 
                           props.data?.cnpj_customers_of_customers
                            :
                            props.data?.cpf_customers_of_customers)
                          :
                            ''
                          }
                          {
                          props.data.uc_registered_user === 'representante_legal_terceiro'
                            && 
                            props.data?.cpf_representative_customers_of_customers
                          }</span>
                  </p>
                </td>
                <td
                  style={{
                    width: "154pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",height:'16pt'
                  }}
                >
                  <p
                    className=""
                    style={{
                      paddingRight: "6pt",
                      textIndent: "0pt",
                      textAlign: "right"
                    }}
                  >
                    : <span className='rateio1Per' style={{ fontSize: '9pt' }}> {item.percentage} %</span>
                  </p>
                </td>
              </tr>
    ))
  )
  :
  ''
       }

       {
          opSystem && newArrayRateio.length > 0 ?
          (
            newArrayRateio.map((item, index) => (
              <tr style={{ height: "16pt" }}>
                <td
                  style={{
                    width: "94pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",height:'16pt'
                  }}
                >
                  <p
                    className=""
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "6pt",
                      textIndent: "0pt",
                      textAlign: "left"
                    }}
                  >
                    UC:<span style={{ fontSize: '9pt' }}> {item.uc} </span>
                  </p>
                </td>
                <td
                  style={{
                    width: "226pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",height:'16pt'
                  }}
                >
                  <p
                    className=""
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "69pt",
                      textIndent: "0pt",
                      textAlign: "left"
                    }}
                  >
                    CNPJ/CPF:<span style={{ fontSize: '9pt' }}>{
                          props.data.uc_registered_user === 'cliente_terceiro' 
                          ? 
                          (props.data?.cnpj_customers_of_customers 
                            ? 
                           props.data?.cnpj_customers_of_customers
                            :
                            props.data?.cpf_customers_of_customers)
                          :
                            ''
                          }
                          {
                          props.data.uc_registered_user === 'representante_legal_terceiro'
                            && 
                            props.data?.cpf_representative_customers_of_customers
                          }</span>
                  </p>
                </td>
                <td
                  style={{
                    width: "154pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",height:'16pt'
                  }}
                >
                  <p
                    className=""
                    style={{
                      paddingRight: "6pt",
                      textIndent: "0pt",
                      textAlign: "right"
                    }}
                  >
                    : <span className='rateio1Per' style={{ fontSize: '9pt' }}> {item.percentage} %</span>
                  </p>
                </td>
              </tr>
            ))
          )
          :
          ''
       }

        {
          opLatest && arrayRateioLatest.length > 0 ?
          (
            arrayRateioLatest.map((item, index) => (
              <tr style={{ height: "16pt" }}>
                <td
                  style={{
                    width: "94pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",height:'16pt'
                  }}
                >
                  <p
                    className=""
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "6pt",
                      textIndent: "0pt",
                      textAlign: "left"
                    }}
                  >
                    UC:<span style={{ fontSize: '9pt' }}> {item.uc} </span>
                  </p>
                </td>
                <td
                  style={{
                    width: "226pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",height:'16pt'
                  }}
                >
                  <p
                    className=""
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "69pt",
                      textIndent: "0pt",
                      textAlign: "left"
                    }}
                  >
                    CNPJ/CPF:<span style={{ fontSize: '9pt' }}>{
                          props.data.uc_registered_user === 'cliente_terceiro' 
                          ? 
                          (props.data?.cnpj_customers_of_customers 
                            ? 
                           props.data?.cnpj_customers_of_customers
                            :
                            props.data?.cpf_customers_of_customers)
                          :
                            ''
                          }
                          {
                          props.data.uc_registered_user === 'representante_legal_terceiro'
                            && 
                            props.data?.cpf_representative_customers_of_customers
                          }</span>
                  </p>
                </td>
                <td
                  style={{
                    width: "154pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",height:'16pt'
                  }}
                >
                  <p
                    className=""
                    style={{
                      paddingRight: "6pt",
                      textIndent: "0pt",
                      textAlign: "right"
                    }}
                  >
                    : <span className='rateio1Per' style={{ fontSize: '9pt' }}> {item.percentage} %</span>
                  </p>
                </td>
              </tr>
            ))
          )
          :
          ''
        }

                 

                  </tr>
          
            </tbody>
          </table>
          <p style={{ textIndent: "0pt", textAlign: "center" }}>
            <br />
            <br />
            <br />
          </p>
          <table
            style={{ borderCollapse: "collapse", marginLeft: "50pt" }}
            cellSpacing={0}
          >
            <tbody>
              <tr style={{ height: "17pt" }}>
                <td
                  style={{
                    width: "474pt",
                  
        
                  }}
                >
                  <h2
                    className="s7"
                    style={{
                      paddingLeft: "10pt",
                      paddingRight: "197pt",
                      textIndent: "0pt",
                      lineHeight: "13pt",
                      textAlign: "left",
                      fontSize:'11pt'
                    }}
                  >
                    IMPORTANTE
                  </h2>
                </td>
              </tr>
              <tr style={{ height: "29pt" }}>
                <td
                  style={{
                    width: "474pt",
           
                    padding:'2px'
                  }}
                >
                  <ul style={{listStyleType:'circle'}}>
                    <li  >
                      <p
                        className="s8"
                        style={{
                          paddingLeft: "10pt",
                          paddingRight: "9pt",
                          textIndent: "10pt",
                          lineHeight: "110%",
                          fontSize: '9pt',
                          textAlign: "justify",
                        }}
                      >
                      Caso a leitura da unidade beneficiaria ocorra antes ou no
                          mesmo dia que a unidade geradora, os creditos serão faturados apenas no próximo faturamento.
                     
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
             
            </tbody>
          </table>
          </Grid>
          <p style={{ textIndent: "0pt", textAlign: "center" }}>
            <br />
          </p>
          <br />
          <br />
          <br />
          <br />
          <br />
          <p style={{ textAlign: 'center' }}>_____________________________________</p>
          <p
            style={{
              paddingLeft: "105pt",
              textIndent: "0pt",
              lineHeight: "1pt",
              textAlign: "left"
            }}
          ></p>
          <h3 style={{ paddingLeft: "0pt", textIndent: "0pt", textAlign: "center" }}>
            Rodrigo Fernandes Feijó
          </h3>
          <p
            className="s9"
            style={{
              paddingTop: "1pt",
              paddingLeft: "0pt",
              textIndent: "2pt",
              lineHeight: "120%",
              textAlign: "center",
              fontSize:'9pt',
              textDecoration:'none',
            
            }}
          >
        
              Engenheiro Eletricista
              <br />
              CREA-CE nº 061872146-0
              <br />
        
          </p>
          <h4
            className=""
            style={{
              paddingLeft: "0pt",
              textIndent: "0pt",
              lineHeight: "9pt",
              textAlign: "center"
              ,textDecoration:'none',
              fontSize:'9pt'
            }}
          >
            Representante Legal
          </h4>
          <p style={{ textIndent: "0pt", textAlign: "center" }}>
            <br />
          </p>
          <h4 style={{ paddingLeft: "0pt", textIndent: "0pt", textAlign: "center" }}>
            Enel Distribuição Ceará&nbsp;<span style={{fontSize:'9pt',fontStyle:'normal',fontFamily:'serifss',textDecoration:'none'}}>&nbsp;–&nbsp; www.enel.com.br</span>
          </h4>
        
        </p>
        </div>
        </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
          <Button onClick={props.close} color="primary">
              Fechar
          </Button>
          <Button color="primary" variant="contained" onClick={handlePdfDownloadRateio} disabled={isDisabled()}>
              download <CloudDownloadIcon sx={{ml:1}} />
          </Button>
        
    
      </DialogActions>
    </Dialog>
      
);
};

export default RateioForm;


