import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Avatar, Collapse, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, Typography } from '@mui/material'
import React from 'react'
import NotificationsIcon from '@mui/icons-material/Notifications'; 
export default function Notifications_Sidebar(props){
  return (
    <List>
    <Paper sx={{ padding: 2, margin: 1 }}>
        <Typography variant="h6" component="div" sx={{ p: '5px', fontSize: 15 }}>Notificações <NotificationsIcon sx={{ fontSize: 13, color: 'grey.500' }} /></Typography>
        <ListItemButton onClick={props.handleClick}>
            <ListItemText primary="Notificações" />
            {props.open? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={props.open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                <ListItem sx={{ pl: 4 }}>
                    <ListItemAvatar>
                        <Avatar>
                            <NotificationsIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Você tem 3 notificações" />
                </ListItem>
                <ListItem sx={{ pl: 4 }}>
                    <ListItemAvatar>
                        <Avatar>
                            <NotificationsIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Você tem 3 notificações" />
                </ListItem>
                <ListItem sx={{ pl: 4 }}>
                    <ListItemAvatar>
                        <Avatar>
                            <NotificationsIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Você tem 3 notificações" />
                </ListItem>
                </List>
        </Collapse>
    </Paper>

    
</List>
  )
}
