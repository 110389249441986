
import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar'
import './style.css'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Popup_demand from '../popup_details_demand';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArticleIcon from '@mui/icons-material/Article';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Box,
  TextField,
  Button,
  IconButton,
} from '@mui/material';
import { Link } from 'react-router-dom';
import HeaderSolProjetos from '../headeSolProjetos';


function ListSolarService(props) {





  const [demand, setDemand] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 8;


  useEffect(() => {
    const fetchDemand = async () => {
      setLoading(true);
      try {
        fetch(`http://15.228.8.54:5000/api/demand_project_solar/list-integrator/${props.id}?page=${currentPage}&limit=${itemsPerPage}`, {
          //fetch(`http://15.228.8.54:5000/api/demand_project_solar/list?page=${currentPage}&limit=${itemsPerPage}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((response) => response.json())
          .then((data) => {
            setDemand(data);
            setTotalPages(data[1]);
          })

      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchDemand();
  }, []);
  
 

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // ... renderizar a lista de produtos e a paginação


  const formatDate = (date) => {
    if(date === '---'){
      return '---'
    }
    const dateFormated = new Date(date)
    return dateFormated.toLocaleDateString() + ' ' + dateFormated.toLocaleTimeString()
  };


  document.querySelectorAll('.MuiPaginationItem-root').forEach((item) => {
    item.style.backgroundColor = '#6b705c';
    item.style.color = '#fff';
    if (item.ariaCurrent === 'true') {
      item.style.backgroundColor = '#ffffff';
      item.style.color = '#5e6251';
      item.style.border = '2px solid #5e6251';
    }

  })
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const openClose = () => {
    setOpen(false);
  };

  const [handleData, setHandleData] = useState('');


  const searchDemand = () => {

    let value = document.querySelector('.search-demand').value;
    value = value.toLowerCase();
    value = value.replace('/', '-');
    if (value !== '') {
      fetch(`http://15.228.8.54:5000/api/demand_project_solar/search/${props.id}?page=${currentPage}&limit=${itemsPerPage}&search=${value}`, {
        method: 'GET',
      }).then(response => response.json())
        .then(data => {
          setDemand(data);
          setTotalPages(data[1]);

        })
      return
    }
    
  }


  const [doc_proxy, setDoc_proxy] = useState(null)

  
  return (

    <Sidebar>
      <div id='list-project-solar'>
        <div className='project-solar'>
          <ToastContainer
            position="bottom-right"
            autoClose={4000}
            newestOnTop={false}
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
            height={50}
          />
          <HeaderSolProjetos/>
          <br />
          <div style={{ display: 'flex', padding: '0 50px 0 50px', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', paddingLeft: '50px' }}>
              <div style={{ display: 'flex' }}>
                <input type="text" className='search-demand' placeholder='Pesquisar'
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {

                    searchDemand()
                  }}
                }
                onChange={(e) => {
            

                  if (e.target.value === '') {
                    fetch(`http://15.228.8.54:5000/api/demand_project_solar/list-integrator/${props.id}?page=${currentPage}&limit=${itemsPerPage}`, {
                      //fetch(`http://15.228.8.54:5000/api/demand_project_solar/list?page=${currentPage}&limit=${itemsPerPage}`, {
                      method: 'GET',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                    }).then((response) => response.json())
                      .then((data) => {
                        setDemand(data);
                        setTotalPages(data[1]);
                      })
                  }
                }} />
                <button id='bt-search' onClick={searchDemand}>Pesquisar</button>
              </div>
              <button onClick={() => {

                setLoading(true);
                try {
                  fetch(`http://15.228.8.54:5000/api/demand_project_solar/list-integrator/${props.id}?page=${currentPage}&limit=${itemsPerPage}`, {
                    //fetch(`http://15.228.8.54:5000/api/demand_project_solar/list?page=${currentPage}&limit=${itemsPerPage}`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  }).then((response) => response.json())
                    .then((data) => {
                      setDemand(data);
                      setTotalPages(data[1]);
                    })

                } catch (error) {
                  console.error(error);
                } finally {
                  setLoading(false);
                }


              }} id='bt-all' >Todos</button>
              <button onClick={() => {


                setLoading(true);
                try {
                  fetch(`http://15.228.8.54:5000/api/demand_project_solar/list-status/${props.id}?status=${'Concluído'}&page=${currentPage}&limit=${itemsPerPage}`, {
                    //fetch(`http://15.228.8.54:5000/api/demand_project_solar/list?page=${currentPage}&limit=${itemsPerPage}`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  }).then((response) => response.json())
                    .then((data) => {
                      setDemand(data);
                      setTotalPages(data[1]);
                    })

                } catch (error) {
                  console.error(error);
                } finally {
                  setLoading(false);
                }
              }} id='bt-concluded'>Concluídos</button>
              <button onClick={() => {

                setLoading(true);
                try {
                  fetch(`http://15.228.8.54:5000/api/demand_project_solar/list-status/${props.id}?status=${'Solicitado'}&page=${currentPage}&limit=${itemsPerPage}`, {
                    //fetch(`http://15.228.8.54:5000/api/demand_project_solar/list?page=${currentPage}&limit=${itemsPerPage}`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  }).then((response) => response.json())
                    .then((data) => {
              
                      setDemand(data);
                      setTotalPages(data[1]);
                    })

                } catch (error) {
                  console.error(error);
                } finally {
                  setLoading(false);
                }
              }} id='bt-in-progress'> Solicitado</button>
            </div>
            <Link to='/solar-service/demand'><button style={{ background: 'rgb(0, 130, 78)' }} > Novo Projeto</button></Link>
          </div>

          <div id='tableDemands' style={{
            padding: '0 0px 0 50px',
          }}>
            {

              (
                <>
                  <Paper>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ p: '5px', fontSize: 14 }}align="center">Numero UC</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 14 }}align="center">Nome</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 14 }}align="center">CPF/CNPJ</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 14 }}align="center">Status</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 14 }}align="center">Data da Solicitação</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 14 }}align="center">Data de Conclusão</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 14 }}align="center">Procuração Assinada</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 14 }}align="center">Detalhes</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {demand[0]?.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell sx={{ p: '5px', fontSize: 12 }}align="center">{item.number_uc_demand}</TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 12 }}align="center">{item.name_customers_of_customers ? item.name_customers_of_customers : item.corporate_reason_customers_of_customers}</TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 12 }}align="center">{item.cpf_customers_of_customers ? item.cpf_customers_of_customers : item.cnpj_customers_of_customers}</TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 12,display: 'flex', gap: 1 }}align="center" >
                              {item.status}
                              <Box
                                sx={{
                                  width: '10px',
                                  height: '10px',
                                  borderRadius: '50%',
                                  backgroundColor: item.status === 'Solicitado' ? '#DAA520' : '#228b22df',
                                  animation: 'pulse 1s infinite',
                                }}
                              />
                              {/*<span className='span_check' style={{fontWeight:1000}}>&#10003;</span>*/}
                            </TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 12 }}align="center">{formatDate(item.date_demand)}</TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 12 }}align="center">{item.date_conclusion_demand === '---' ? '---' : formatDate(item.date_conclusion_demand)}</TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 12 }}align="center">
                              {
                              item.info_proxy_demand === 'failed'
                                ? 
                                <div style={{display:'flex',alignItems:'center', justifyContent:'center',flexDirection:'row',gap:5}} className='doc_proxy'>
                                 <label for='doc_proxy' style={{ width: '60px' }} className='label-doc_proxy style-label'>enviar</label> 
                                 <input hidden type='file' className='doc_proxy' id='doc_proxy'  onChange={e => {
                                   const file = e.target.files[0]
                                   const formdata = new FormData()
                                   formdata.append('doc_proxy_demand', file)
                                   setDoc_proxy(formdata)

                                   fetch(`http://15.228.8.54:5000/api/demand_project_solar/uploadDocProxyDemand/${item.id_demand}`, {
                                      method: 'POST',
                                      body: formdata,
                                    }).then(response => response.json())
                                      .then(data => {
                                      
                                        if (data.message === 'success') {
                                          fetch(`http://15.228.8.54:5000/api/demand_project_solar/update-info-proxy/${item.id_demand}`, {
                                            method: 'PUT',
                                            headers: {
                                              'Content-Type': 'application/json',
                                            },
                                            body: JSON.stringify({
                                              info_proxy_demand: 'under_analysis'
                                            })
                                          }).then(response => response.json())
                                            .then(data => {
                                            
                                              if (data.message === 'success') {
                                                fetch(`http://15.228.8.54:5000/api/demand_project_solar/list-integrator/${props.id}?page=${currentPage}&limit=${itemsPerPage}`, {
                                                  //fetch(`http://15.228.8.54:5000/api/demand_project_solar/list?page=${currentPage}&limit=${itemsPerPage}`, {
                                                  method: 'GET',
                                                  headers: {
                                                    'Content-Type': 'application/json',
                                                  },
                                                }).then((response) => response.json())
                                                  .then((data) => {
                                                    setDemand(data);
                                                    setTotalPages(data[1]);
                                                  })
                                              }
                                            })
                                        }
                                      })

                                 }} /><Box
                                  sx={{
                                    width: '10px',
                                    height: '10px',
                                    borderRadius: '50%',
                                    backgroundColor:  '#ff0000a7',
                                    animation: 'pulse 1s infinite',
                                  }}
                                />
                                </div>
                                : ''
                              }
                              {
                                item.info_proxy_demand === 'success'
                                ?
                                <p> Aprovada! <IconButton style={{ fontSize: '8pt', padding: 0, color: 'green' }}><CheckCircleIcon fontSize="small" /></IconButton></p>
                                : ''
                              }
                              {
                                item.info_proxy_demand === 'under_analysis' && item.doc_proxy_demand !== ''
                                ?
                                <p style={{fontSize:'9pt'}}> Em análise! <span style={{fontSize:'8pt'}}>&#128338;</span></p>
                             : ''
                              }
                              {
                                item.info_proxy_demand === 'send' && item.doc_proxy_demand === '---'
                                ?
                                <div style={{display:'flex',alignItems:'center', justifyContent:'center',flexDirection:'column'}} className='doc_proxy'>
                                <label for='doc_proxy' style={{ width: '60px' }} className='label-doc_proxy style-label'>enviar</label> 
                                <input hidden type='file' className='doc_proxy' id='doc_proxy' onChange={e => {
                                   const file = e.target.files[0]
                                   const formdata = new FormData()
                                   formdata.append('doc_proxy_demand', file)
                                   setDoc_proxy(formdata)

                                   fetch(`http://15.228.8.54:5000/api/demand_project_solar/uploadDocProxyDemand/${item.id_demand}`, {
                                      method: 'POST',
                                      body: formdata,
                                    }).then(response => response.json())
                                      .then(data => {
                                    
                                        if (data.message === 'success') {
                                          fetch(`http://15.228.8.54:5000/api/demand_project_solar/update-info-proxy/${item.id_demand}`, {
                                            method: 'PUT',
                                            headers: {
                                              'Content-Type': 'application/json',
                                            },
                                            body: JSON.stringify({
                                              info_proxy_demand: 'under_analysis'
                                            })
                                          }).then(response => response.json())
                                            .then(data => {
                                          
                                              if (data.message === 'success') {
                                                fetch(`http://15.228.8.54:5000/api/demand_project_solar/list-integrator/${props.id}?page=${currentPage}&limit=${itemsPerPage}`, {
                                                  //fetch(`http://15.228.8.54:5000/api/demand_project_solar/list?page=${currentPage}&limit=${itemsPerPage}`, {
                                                  method: 'GET',
                                                  headers: {
                                                    'Content-Type': 'application/json',
                                                  },
                                                }).then((response) => response.json())
                                                  .then((data) => {
                                                    setDemand(data);
                                                    setTotalPages(data[1]);
                                                  })
                                              }
                                            })
                                        }
                                      })

                                 }} />
                                 </div>
                                 : ''
                              }
                             </TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 12 }}align="center">
                             {/*<button className='bt-details' onClick={() => {
                                //  document.querySelector(`.popup`).style.display = 'block';
                                handleClickOpen();
                                setHandleData(item);
                              }}>Detalhes</button>*/}
                            <IconButton sx={{margin:'0px!important'}} id='btn-details'  onClick={() => {
                                //  document.querySelector(`.popup`).style.display = 'block';
                                handleClickOpen();
                                setHandleData(item);
                              }
                            }><ArticleIcon fontSize="small" /></IconButton>
                              
                            </TableCell>

                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>

                    <Popup_demand
                      data={handleData}
                      open={open}
                      handleClose={openClose}
                    />

                  </Paper>

                  <Pagination style={{ marginTop: '10px' }}
                    count={totalPages}
                    page={currentPage}
                    onChange={(event, page) => handlePageChange(page)}
                    showFirstButton
                    showLastButton
                    boundaryCount={1} // Número de páginas antes e depois das ellipses
                    siblingCount={1} // Número de páginas antes e depois da página atual
                  />
                </>
              )}


          </div>


        </div>
      </div>
    </Sidebar>
  )
}

export default ListSolarService
