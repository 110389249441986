import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'

export default function DropDialog(props) {
    

  return (
    <Dialog
    open={props.openDrop}
    onClose={props.closeDrop}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {props.msg}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.handleCloseDrop} color="primary">
        Cancelar
      </Button>
      <Button onClick={props.delete} color="error" autoFocus>
        Deletar
      </Button>
    </DialogActions>
  </Dialog>
  )
}
