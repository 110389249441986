import { DialerSipSharp, Visibility, VisibilityOff } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItem, TextField } from '@mui/material'
import React, { useEffect } from 'react'

export default function New_Edit_User_Customer(props) {

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    function gerarSenha(email) {
        // Extrai os primeiros 4 dígitos do ano atual
        let primeirosDigitos = new Date().getFullYear().toString().substring(0, 4);
       
      
        // Extrai as primeiras 4 letras do email
        const primeirasLetras = email.substring(0, 4).toUpperCase();
      
        // Concatena os valores, intercalando @ e #
        const senha = `${primeirosDigitos}@${primeirasLetras}#`;
      
        return senha;
      }
      const [name, setName] = React.useState('');
      const [email, setEmail] = React.useState('');
        const [password, setPassword] = React.useState('');
      useEffect(() => {
        if (props.title === 'Novo Usuário') {
          
            if(props.data.cpf)setName(props.data.name);
            if(props.data.cnpj)setName(props.data.corporate_reason);
            if(props.data.email)setEmail(props.data.email);
            setPassword(gerarSenha(props.data.email));
            
        }
        if (props.title === 'Editar Usuário') {
            setName(props.data.name_user);
            setEmail(props.data.email);
            setPassword(props.data.password);

        }
      }, [props]);

    const closeUser = () => {
        setName('');
        setEmail('');
        setPassword('');
        props.closeUser();
    }

    const new_user = () => {
        try{
             fetch(`http://15.228.8.54:5000/api/users/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name_user: name,
                email: email,
                password: password,
                typeUser: 'Cliente',
                id_customer: props.data.id,
                id_employee: null

            }),
        }).then(response => response.json())
        .then(data => {
           
            if(data.message === 'User registered successfully'){
                closeUser();
            }
        })
        }catch(error){
            console.log(error)
        }
    }

    const edit_user = () => {
        try{
            fetch(`http://15.228.8.54:5000/api/users/update/${props.data.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name_user: name,
                    email: email,
                    password: password,
                    typeUser: 'Cliente',
                    id_customer: props.data.id_customer ? props.data.id_customer : props.data.id,
                    id_employee: null
                }),
            }).then(response => response.json())
            .then(data => {
               
                if(data.message === 'User updated successfully'){
                    closeUser();
                }
            })
        }catch(error){
            console.log(error)
        }
    }


    return (
        <Dialog
            open={props.openUser}
            onClose={props.closeUser}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            fullWidth={true}
        >
            <DialogContent>
                <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
                <List>
                    <ListItem>
                        <TextField
                            margin="dense"
                            id="name"
                            label="Nome"
                            type="text"
                            fullWidth
                            value={name.split(' ')[0]}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </ListItem>
                    <ListItem>
                        <TextField
                            margin="dense"
                            id="email"
                            label="Email"
                            type="email"
                            fullWidth
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </ListItem>
                    <ListItem>
                        <TextField

                            id="password-input"
                            label="Senha"
                            type={showPassword ? 'text' : 'password'}
                            autoComplete="current-password"
                            fullWidth
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}   
                            InputProps={{
                                endAdornment: (
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                ),
                            }}
                        />
                    </ListItem>

                </List>


            </DialogContent>
            <DialogActions>
                <Button onClick={closeUser} color="primary">
                    Cancelar
                </Button>
               {props.title === 'Novo Usuário' ?
    
                <Button onClick={new_user} color="primary" autoFocus>
                    Salvar
                </Button>
                :
                <Button onClick={edit_user} color="primary" autoFocus>
                    Salvar
                </Button>}
            </DialogActions>
        </Dialog>
    )
}
