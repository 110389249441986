import Sidebar from "../../Components/Sidebar"
import './style.css'  
const Settings = () => {
    return (
      <Sidebar>

      <div className="OrganizeGroupSettings">
        <h1>Settings</h1>
      </div>
      </Sidebar>
    )
  }
  export default Settings