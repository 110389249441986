import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from   
 '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';   
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, List, ListItem, ListItemText, Box } from '@mui/material';
import { GoogleMap, StreetViewPanorama, useJsApiLoader, useLoadScript, Marker } from '@react-google-maps/api';
import Stepper_Project from '../stepper_project';




function Popup_demand(props) {

    const { isLoaded } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: "AIzaSyAMjFIIlVgRO1yUsl7uLN0RXBMyDHMJUS8",
    })
  
    const center = {
        lat : parseFloat(props.data.lat_demand),
        lng : parseFloat(props.data.log_demand),
    }
    const mapContainerStyle = {
        width: '250px',
        height:   
       '190px',
      };
      const options = {
        disableDefaultUI: true,
        zoomControl: false,
        mapTypeControl: true,
        scaleControl: true,
        rotateControl: false,
        fullscreenControl: true,
        mapTypeId: 'satellite',
        tilt: 0,
        zoom: 18,
      }
      const options2 = {
        disableDefaultUI: false,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        rotateControl: false,
        fullscreenControl: true,
        mapTypeId: 'satellite',
        streetViewControl: false,
        draggable: true,
        inclination: 0,
        heading: 0,
        tilt: 0,
        zoom: 19,
      }
    const [open, setOpen] = React.useState(props.open);

    const project_type =(project)=>{
        if(project === 'b1_residencial') return 'B1 - Residencial'
        if(project === 'b2_rural') return 'B2 - Rural'
        if(project === 'b2_cooperativa') return 'B2 - Cooperativa'
        if(project === 'b2_servico_publico_de_irrigaca') return 'B2 - Serviço Público de Irrigação'
        if(project === 'b3_demais_classes') return 'B3 - Demais Classes'
        if(project === 'a4_horaria_verde') return 'A4 - Horária Verde'
        if(project === 'a4_horaria_azul') return 'A4 - Horária Azul'
      }
      const service_type = (service) => {
        if (service === 'monofasico') return 'Monofásico'
        if (service === 'bifasico') return 'Bifásico'
        if (service === 'trifasico') return 'Trifásico'
    }
    const branch_type = (branch) => {
        if (branch === 'aereo') return 'Aéreo'
        if (branch === 'subterraneo') return 'Subterrâneo'
    }
    const modality_type = (modality) => {
        if (modality === 'geracao_local') return 'Geração Local'
        else return 'Autoconsumo Remoto'
    }
       
      if(!props.data){
          return( <div></div>)
          } 

          //Passando de JSON para Array
          let arrayModules = []
          let arrayInverters = []
          if(props.data.info_module_demand){
            arrayModules = JSON.parse(props.data.info_module_demand) 
            arrayInverters = JSON.parse(props.data.info_inverter_demand)
          }      

  return (
    <div >
        
       <Dialog
                            open={props.open}
                            onClose={props.handleClose}
                        
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            maxWidth='1200px'
                            sx={{ml:5}}
                        >
                            <DialogTitle id="alert-dialog-title">{"Detalhes do Projeto"}</DialogTitle>
                            <DialogContent>

                            <div style={{minWidth:'1200px'}}>
                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                              <Stepper_Project/>
      <Grid item xs={6}>
        <Box sx={{ height: 100 }}>
            <h3 style={{fontSize:14,marginBottom:10,marginTop:5}}>Cliente:</h3>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500, height:50 }} aria-label="simple table" >
                <TableHead >
                    <TableRow>
                    <TableCell sx={{p:'5px'}} align="center">{props.data.cpf_customers_of_customers ? 'Nome':'Rasão Social'}</TableCell>
                    <TableCell sx={{p:'5px'}} align="center">{props.data.cpf_customers_of_customers ? 'CPF' : 'CNPJ'}</TableCell>
                    <TableCell sx={{p:'5px'}}align="center">E-mail</TableCell>
                    <TableCell sx={{p:'5px'}}align="center">Contato</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell sx={{p:'5px',fontSize:10}} component="th" scope="row">
                        {props.data.name_customers_of_customers? props.data.name_customers_of_customers : props.data.corporate_reason_customers_of_customers}
                    </TableCell>
                    <TableCell sx={{p:'5px',fontSize:12}}align="center">{props.data.cpf_customers_of_customers ? props.data.cpf_customers_of_customers : props.data.cnpj_customers_of_customers}</TableCell>
                    <TableCell sx={{p:'5px',fontSize:12}}align="center">{props.data.email_customers_of_customers}</TableCell>
                    <TableCell sx={{p:'5px',fontSize:12}}align="center">{props.data.contact_customers_of_customers}</TableCell>
            
                    </TableRow>
                </TableBody>
            </Table>
          </TableContainer>
        </Box>
      { props.data.cnpj_customers_of_customers &&  <Box sx={{ height: 100 }}>
 <br />
        <h3 style={{fontSize:14,marginBottom:10,marginTop:5}}>Representante Legal:</h3>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500, height:50 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                    <TableCell sx={{p:'5px'}} align="center">Nome</TableCell>
                    <TableCell sx={{p:'5px'}} align="center">CPF</TableCell>
                    <TableCell sx={{p:'5px'}} align="center">Profissão</TableCell>
                    <TableCell sx={{p:'5px'}} align="center">Nacionalidade</TableCell>
               
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow
                    key={props.data.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell align="center" sx={{p:'5px',fontSize:13}} component="th" scope="row">
                        {props.data.name_representative_customers_of_customers}
                    </TableCell>
                    <TableCell sx={{p:'5px',fontSize:13}}align="center">{props.data.cpf_representative_customers_of_customers}</TableCell>
                    <TableCell sx={{p:'5px',fontSize:13}}align="center">{props.data.profession_representative_customers_of_customers}</TableCell>
                    <TableCell sx={{p:'5px',fontSize:13}}align="center">{props.data.nationality_representative_customers_of_customers}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
          </TableContainer>
        </Box>}
     
        <Box sx={{ height: 140 }}>
        <br />
        <h3 style={{fontSize:14,marginBottom:10,marginTop:5}}>Informações do Projeto:</h3>

          <TableContainer component={Paper}>
            <Table x={{ minWidth: 600, height:50 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                    <TableCell sx={{p:'5px'}}align="center">N° UC</TableCell>
                    <TableCell sx={{p:'5px'}}align="center">Tipo de Projeto</TableCell>
                    <TableCell sx={{p:'5px'}}align="center">Atendimento</TableCell>
                    <TableCell sx={{p:'5px'}}align="center">Tipo de Ramal</TableCell>
                    <TableCell sx={{p:'5px'}}align="center">Modalidade</TableCell>
                    <TableCell sx={{p:'5px'}}align="center">Dijuntor</TableCell>
                    <TableCell sx={{p:'5px'}}align="center">Tensão da Rede</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell align="center" sx={{p:'5px',fontSize:13}}component="th" scope="row">
                        { props.data.number_uc_demand}
                    </TableCell>
                    <TableCell sx={{p:'5px',fontSize:11}} align="center">{ project_type(props.data.project_type_demand)}</TableCell>
                    <TableCell sx={{p:'5px',fontSize:13}} align="center">{service_type(props.data.service_type_demand)}</TableCell>
                    <TableCell sx={{p:'5px',fontSize:13}} align="center">{branch_type(props.data.branch_type_demand)}</TableCell>
                    <TableCell sx={{p:'5px',fontSize:13}} align="center">{modality_type(props.data.modality_type_demand)}</TableCell>
                    <TableCell sx={{p:'5px',fontSize:13}} align="center">{props.data.disjuntor_demand}</TableCell>
                    <TableCell sx={{p:'5px',fontSize:13}} align="center">{props.data.power_rede_demand}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box sx={{ height: 100 }}>
          <br />
        <h3 style={{fontSize:14,marginBottom:10,marginTop:5}}>Modulos:</h3>

          <TableContainer component={Paper}>
            <Table x={{ minWidth: 600, height:50 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                    <TableCell sx={{p:'5px'}}align="center">Marca</TableCell>
                    <TableCell sx={{p:'5px'}}align="center">Modelo</TableCell>
                    <TableCell sx={{p:'5px'}}align="center">Quantidade</TableCell>
                
                    </TableRow>
                </TableHead>
                { arrayModules?.map((item, index) => (
                <TableBody key={index}>
                  
                    <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell align="center" sx={{p:'5px',fontSize:13}}component="th" scope="row">
                        { item.marca}
                    </TableCell>
                    <TableCell align="center" sx={{p:'5px',fontSize:13}}>{item.modelo}</TableCell>
                    <TableCell sx={{p:'5px',fontSize:13}} align="center">{item.quantidade}</TableCell>
       
                    </TableRow>
                </TableBody>
                    ))}
            </Table>
          </TableContainer>
        </Box>
        <Box sx={{ height: 100 }}>
          <br />
        <h3 style={{fontSize:14,marginBottom:10,marginTop:5}}>Inversores:</h3>

          <TableContainer component={Paper}>
            <Table x={{ minWidth: 600, height:50 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                    <TableCell sx={{p:'5px'}}align="center">Marca</TableCell>
                    <TableCell sx={{p:'5px'}}align="center">Modelo</TableCell>
                    <TableCell sx={{p:'5px'}}align="center">Quantidade</TableCell>
                
                    </TableRow>
                </TableHead>
                { arrayInverters?.map((item, index) => (
                <TableBody key={index}>
                    <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell align="center" sx={{p:'5px',fontSize:13}}component="th" scope="row">
                        { item.marca}
                    </TableCell>
                    <TableCell align="center" sx={{p:'5px',fontSize:13}}>{item.modelo}</TableCell>
                    <TableCell sx={{p:'5px',fontSize:13}} align="center">{item.quantidade}</TableCell>
       
                    </TableRow>
                </TableBody>
                    ))}
            </Table>
          </TableContainer>
        </Box>

      </Grid>
      <Grid item xs={6}>
      <Box sx={{ height: 100 }}>
        <h3 style={{fontSize:14,marginBottom:10,marginTop:5}}>Endereço do Projeto:</h3>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500, height:50 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                    <TableCell sx={{p:'5px'}} align="center">Logradouro</TableCell>
                    <TableCell sx={{p:'5px'}} align="center">Numero</TableCell>
                    <TableCell sx={{p:'5px'}} align="center">Bairro</TableCell>
                    <TableCell sx={{p:'5px'}} align="center">Cidade</TableCell>
                    <TableCell sx={{p:'5px'}} align="center">Estado</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow
                    key={props.data.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell sx={{p:'5px',fontSize:13}} component="th" scope="row">
                        {props.data.street_service_demand}
                    </TableCell>
                    <TableCell sx={{p:'5px',fontSize:13}} align="center">{props.data.number_service_demand}</TableCell>
                    <TableCell sx={{p:'5px',fontSize:13}} align="center">{props.data.district_service_demand}</TableCell>
                    <TableCell sx={{p:'5px',fontSize:13}} align="center">{props.data.city_service_demand}</TableCell>
                    <TableCell sx={{p:'5px',fontSize:13}} align="center">{props.data.state_service_demand}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
          </TableContainer>
        </Box>
      {isLoaded &&
      
      <Grid container spacing={9} pl={2} pt={2}>

  {   /* <Grid item xs={2} sm={5} spacing={9}>
      <GoogleMap

mapContainerStyle={mapContainerStyle}
zoom={16}
center={center}

>
<Marker position={center} />
</GoogleMap>
        <br />
        <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        zoom={10}
                        center={center}
                        options={options2}
                    
                   
                      >
                        <StreetViewPanorama
                          position={center}
                          visible={true}
                          options={{
                            enableCloseButton: false,
                            addressControl: true,
                            fullscreenControl: true,
                            panControl: true,
                            zoomControl: false,
                            clickToGo: true,
                            scrollwheel: false,
                            visible: true,
                            showRoadLabels: true,
                            motionTracking: true,
                            motionTrackingControl: false,
                            motionTrackingControlOptions: true,

                          }}
                        />
                      </GoogleMap>
      </Grid>*/}
      <Grid item xs={10} sm={4}>
        <GoogleMap
          mapContainerStyle={{ ...mapContainerStyle, height: '400px',width:'550px' }} // Ajuste a altura conforme necessário
          zoom={18}
          options={{
            disableDefaultUI: true,
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: true,
            rotateControl: true,
            fullscreenControl: true,
            mapTypeId: 'satellite',
            streetViewControl: true,
            draggable: true,
            inclination: 0,
            heading: 0,
            tilt: 0,
            zoom: 19,
          }}
          center={center} 
        >
            <Marker position={center} />
        </GoogleMap>
      </Grid>
      </Grid>}
      </Grid>
    </Grid>
                            </div>

                            </DialogContent>
                            <DialogActions>
                            <Button onClick={props.handleClose} autoFocus>
                                Fechar
                            </Button>
                            </DialogActions>
                        </Dialog>

    </div>
  )
}

export default Popup_demand
