import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText } from '@mui/material'
import React, { useEffect } from 'react'
import { Tooltip, Viewer, Worker } from '@react-pdf-viewer/core';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import HideImageIcon from '@mui/icons-material/HideImage';
import Edit_System_Project_Dialog from '../system_project_edit_dialog';
import System_Project_Dialog from '../system_project_dialog';

export default function Preview(props) {

  
    const download = () => {
        const link = document.createElement('a');
        link.href = props.src;
        link.download = `file.${props.typeFile}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
 
    const statusSuccess = () => {
        fetch(`http://15.228.8.54:5000/api/demand_project_solar/update-info-proxy/${props.data.id_demand}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              info_proxy_demand: 'success',
            })
          }).then(response => response.json())
            .then(data => {
                if (data.message === 'success') {
             document.getElementById('bt-search').click()
             setTimeout(() => {
                    document.getElementById(`${props.data.id_demand}`).click()
                }, 100)
                }
            })
            .catch((error) => {
                
            })
    }
    const statusFailed = () => {
        fetch(`http://15.228.8.54:5000/api/demand_project_solar/update-info-proxy/${props.data.id_demand}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              info_proxy_demand: 'failed',
            })
          }).then(response => response.json())
            .then(data => {
                if (data.message === 'success') {
             document.getElementById('bt-search').click()
             setTimeout(() => {
                    document.getElementById(`${props.data.id_demand}`).click()
                }, 100)
                }
            })
            .catch((error) => {
                
            })
    }

      //Função para abrir e fechar o Dialog de editar memorial descritivo

      const [openEditDescriptive, setOpenEditDescriptive] = React.useState(false);

      const handleClickEditDescriptive = () => {
          setOpenEditDescriptive(true);
      }
  
      const closeEditDescriptive = () => {
        document.getElementById('btn-m-d').click()
          setOpenEditDescriptive(false);
      }

    //Função para abrir e fechar o Dialog de projeto
      const [openProject, setOpenProject] = React.useState(false);

    const handleClickProject = () => {
        document.getElementById('btn-m-d').click()
        setOpenProject((prev) => !prev);
    }


  

  return (
    <Dialog
        open={props.open}
        onClose={props.close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth={true}
    >
                <Edit_System_Project_Dialog open={openEditDescriptive} close={closeEditDescriptive} data={props.data} />
                <System_Project_Dialog open={openProject} handleClose={handleClickProject} data={props.data} />
        <DialogContent>
            <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}> {props.title} </DialogTitle>
           {
           
         props.src  ?
         (  props.typeFile === 'pdf' ? (
                <Worker workerUrl="pdf.worker.min.js">
                    <div style={{ height: '750px' }}>
                        <Viewer fileUrl={props.src} />
                    </div>
                </Worker>
            ) :  props.typeFileName === 'image' ? (
                <img src={props.src} alt="Imagem" style={{ width: '100%' }} />
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center">
                <HideImageIcon />
            </Box>
            )
            )
            : (
                <Box display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress />
                </Box>
            )   
            }
               
           
        </DialogContent>

        <DialogActions >
            <DialogContent >

   {
       props.title === 'PROCURAÇÃO ASSINADA' ? (
           <List>
                    <ListItemText 
                    primary="STATUS:" 
                    secondary={
                        props.data.info_proxy_demand === 'send' ? 'Aguardando Envio.' :
                        props.data.info_proxy_demand === 'under_analysis' ? 'Aguardando Confirmação.' :
                        props.data.info_proxy_demand === 'success' ? 'Procuração Aceita.' :
                        props.data.info_proxy_demand === 'failed' ? 'Procuração rejeitada, aguardando reenvio.' : ''

                    } />
                   {  props.data.info_proxy_demand === 'under_analysis' ? <ListItem sx={{gap:1}}>
                        <Button variant='contained' color='success' size='small' onClick={statusSuccess}>confirmar</Button>
                        <Button variant='contained' color='error' size='small' onClick={statusFailed}>rejeitar</Button>
                    </ListItem> : ''}
                </List>
            ) : null
        }
        {
            props.title === 'MEMORIAL DESCRITIVO' ? (
                <List>
                     <ListItem sx={{gap:1}}>
                        <Button variant='contained' color='success' size='small' onClick={handleClickEditDescriptive}>editar</Button>
                        <Button variant='contained' color='primary' size='small' onClick={handleClickProject}>refazer</Button>
                    </ListItem>
                </List>
            ) : null
        }
        </DialogContent>
            <Button disabled={props.typeFile ? (props.typeFile === 'json' ? true : false) : true} onClick={download} color="primary" autoFocus variant='contained'>
                download&nbsp;<CloudDownloadIcon />
            </Button>
            <Button onClick={props.close} color="primary">
                Fechar
            </Button>
        </DialogActions>

    </Dialog>
  )
}
