import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, Collapse, Grid, ListItemText, Switch, TextField, Typography } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';   
import { toast } from 'react-toastify';
import html2pdf from 'html2pdf.js';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

 
function Documentation(props){
  //Download procuração
const handlePdfDownloadPro = () => {
  document.querySelector('#expand').click()

setTimeout(() => {
    const conteudo = document.querySelector('.body-procuracao')
    const pdf = conteudo
    const opt = {
      margin: 0,
      filename: `Procuração Particular - ${props.name ? props.name : props.corporate_reason}.pdf`,
      image: { type: 'png', quality: 0.98 },
      html2canvas: { scale: 1.5 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    }
    html2pdf().from(pdf).set(opt).save().then(() => {
      toast.success('Procuração Particular gerada com sucesso', { autoClose: 2000 })
      props.handleClick()
    }).catch((error) => {
      toast.error('Erro ao gerar Procuração Particular', { autoClose: 2000 })
    })
}, 10)

  }



 

    const handleExpandClick = () => {
      props.setExpanded(!props.expanded);
    };
    

    const handleChange = (event) => {
      props.setChecked(event.target.checked);
    };

    
  const handleClear = () => {
            props.setUcs([]);
            props.setPercentages([]);
            props.setKwhValues([]);
            props.setResultPercentages(0);
        }


    //separar

  
    const handleAddKwh = () => {
      props.setUcs([...props.ucs, '']);
      props.setKwhValues([...props.kwhValues, '']);
    };
  
    const handleRemoveKwh = (index) => {
      const newUcs = [...props.ucs];
      const newKwhValues = [...props.kwhValues];
      newUcs.splice(index, 1);
      newKwhValues.splice(index, 1);
      props.setUcs(newUcs);
      props.setKwhValues(newKwhValues);
    };
  
    const handleSubmit = () => {
      const totalKwh = props.kwhValues.reduce((acc, curr) => acc + parseFloat(curr), 0);
      const newPercentages = props.kwhValues.map(value => ((parseFloat(value) / totalKwh) * 100));
  
      // Adjust last percentage for 100% sum
      const adjustedPercentages = [...newPercentages];
      props.setPercentages(adjustedPercentages);
      let totalPercentage = adjustedPercentages.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr), 0);
  
      adjustedPercentages.forEach((percentage, index) => {
        adjustedPercentages[index] = parseFloat(percentage).toFixed(2);
      });
  
      if (totalPercentage !== 100) {
        adjustedPercentages[adjustedPercentages.length - 1] = parseFloat(adjustedPercentages[adjustedPercentages.length - 1]) + (100 - totalPercentage);
        props.setPercentages(adjustedPercentages);
      }
  
      adjustedPercentages.forEach((percentage, index) => {
        adjustedPercentages[index] = parseFloat(percentage).toFixed(2);
      });
  
      if (totalPercentage !== 100) {
        if (totalPercentage < 100) {
          adjustedPercentages[adjustedPercentages.length - 1] = parseFloat(adjustedPercentages[adjustedPercentages.length - 1]) + 1;
          props.setPercentages(adjustedPercentages);
        } else {
          adjustedPercentages[adjustedPercentages.length - 1] = parseFloat(adjustedPercentages[adjustedPercentages.length - 1]) - 1;
          props.setPercentages(adjustedPercentages);
        }
      }
  
      props.setResultPercentages(totalPercentage.toFixed(2));


    };


    const handleDateValid = () => {
        var data = new Date()
        var dia = data.getDate()
        var mes = data.getMonth() + 1
        var ano = data.getFullYear()
    
        if (dia < 10) {
          dia = `0${dia}`
        }
        if (mes === 1) {
          mes = 'Janeiro'
        }
        if (mes === 2) {
          mes = 'Fevereiro'
        }
        if (mes === 3) {
          mes = 'Março'
        }
        if (mes === 4) {
          mes = 'Abril'
        }
        if (mes === 5) {
          mes = 'Maio'
        }
        if (mes === 6) {
          mes = 'Junho'
        }
        if (mes === 7) {
          mes = 'Julho'
        }
        if (mes === 8) {
          mes = 'Agosto'
        }
        if (mes === 9) {
          mes = 'Setembro'
        }
        if (mes === 10) {
          mes = 'Outubro'
        }
        if (mes === 11) {
          mes = 'Novembro'
        }
        if (mes === 12) {
          mes = 'Dezembro'
        }
        var data = `${dia} de ${mes} de ${ano + 1}`
        return data
      }
    
      const handleDate = () => {
        var data = new Date()
        var dia = data.getDate()
        var mes = data.getMonth() + 1
        var ano = data.getFullYear()
    
        if (dia < 10) {
          dia = `0${dia}`
        }
        if (mes === 1) {
          mes = 'Janeiro'
        }
        if (mes === 2) {
          mes = 'Fevereiro'
        }
        if (mes === 3) {
          mes = 'Março'
        }
        if (mes === 4) {
          mes = 'Abril'
        }
        if (mes === 5) {
          mes = 'Maio'
        }
        if (mes === 6) {
          mes = 'Junho'
        }
        if (mes === 7) {
          mes = 'Julho'
        }
        if (mes === 8) {
          mes = 'Agosto'
        }
        if (mes === 9) {
          mes = 'Setembro'
        }
        if (mes === 10) {
          mes = 'Outubro'
        }
        if (mes === 11) {
          mes = 'Novembro'
        }
        if (mes === 12) {
          mes = 'Dezembro'
        }
    
        var data = `${dia} de ${mes} de ${ano}`
        return data
      }
    
      
   


    
      





  return (
    <Grid container maxWidth={'md'} >
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',mt: 2,mb:2, width: '100%'}}>
    <Alert severity="info">É necessário que a procuração seja baixada para ser assinada e enviada posteriormente.</Alert>
    </Box>
<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',mt: 2,mb:2, width: '100%'}}>

<Button
id='expand'
  onClick={handleExpandClick}
  endIcon={props.expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
>
    Procuração Particular
</Button>
<Button  variant='contained' color='primary'onClick={handlePdfDownloadPro}>Baixar Procuração <CloudDownloadIcon sx={{ ml: 1 }} /></Button>
</Box>
<Collapse in={props.expanded} timeout="auto" unmountOnExit>
<Grid item xs={12} >
<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',mt: 2,mb:2, width: '100%',boxShadow: 1, p: 2, bgcolor: 'background.paper' }}>
<div className="body-procuracao">
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '0px',
          width: '80%',
          maxHeight: '1123px',

          minHeight: '1123px',
          textAlign: 'center',
        }} >
          <h2>Procuração Particular</h2>
        {/*
        props.tipoPessoa === 'pj' &&  
        <div className='procuracao-text pj'>
        <p> <strong>OUTORGANTE: {props.corporate_reason}</strong>, inscrita no CNPJ <span>nº 
                        <strong> {props.cnpj}</strong></span>, situada na&nbsp; {props.street}, {props.number} – {props.neighborhood} – {props.city} - 
           {props.state} – CEP: {props.cep}, neste ato representado pelo <strong>Sr. {props.name_representative}</strong>, 
           {props.nationality_representative}, {props.profession_representative}, RG nº {props.rg_representative} e inscrito no CPF sob o nº 
           {props.cpf_representative}.</p>
          </div>
         */ }
        {
        props.tipoPessoa === 'pj' &&  
        <div className='procuracao-text pj'>
        <p> <strong>OUTORGANTE: {props.corporate_reason}</strong>, inscrita no CNPJ <span>nº 
                        <strong> {props.cnpj}</strong></span>, situada na&nbsp; {props.street}, {props.number} – {props.neighborhood} – {props.city} - 
           {props.state} – CEP: {props.cep}</p>
          </div>
          }
         {props.tipoPessoa === 'pf' && <div className='procuracao-text pf'>
         <p><strong> OUTORGANTE: {props.name}</strong>, {props.profession}, inscrito no CPF sob nº 
                        <strong> {props.cpf}</strong> e RG nº {props.rg}, residente e domiciliado na &nbsp;
                        {props.street}, {props.number} {props.neighborhood} – {props.city} – {props.state}
                        - CEP: {props.cep}.</p>
          </div>}
          <div className="procuracao-text">
            <p><strong>OUTORGADO</strong>: Rodrigo Fernandes Feijó, brasileiro, engenheiro eletricista, inscrito no CPF
              sob nº 669.011.203-97, RG nº 99010500307 e RNP nº 0618721460 CREA-CE,
              residente e domiciliado na Rua Doutor Gilberto Studart, 2189 – AP1602 – Cocó – Fortaleza-
              CE, CEP: 60.192-115.</p>
          </div>
          <div className="procuracao-text">
            <p><strong>PODERES ESPECÍFICOS</strong>: Para representar o outorgante perante Companhia
              Energética do Ceará, Agência Nacional de Energia Elétrica - ANEEL e Agência
              Reguladora do Estado do Ceará - ARCE, relativo aos processos e procedimentos
              de geração distribuída, podendo para tanto, requerer, alertar e assinar todos os
              atos necessários ao bom e fiel cumprimento desse mandato</p>
          </div>
          <div className="procuracao-text">
            <p>Este Instrumento possuí validade até {handleDateValid()}.</p>
          </div>
          <div className="procuracao-text">
            <p style={{
              textAlign: 'right',
            }}>Fortaleza, {handleDate()}.</p>
          </div>
        <div style={{ textAlign: 'center', marginTop: '70px', paddingBottom: '200px' }} className="procuracao-text">
            <p style={{ textAlign: 'center' }}>_____________________________________</p>
            <br />
            {props.tipoPessoa === 'pj' &&  <div style={{ textAlign: 'center', width: '100%' }} className="procuracao-ass-pj">
           <p style={{textAlign:'center'}}>{props.name_representative}</p>
                        <p style={{textAlign:'center'}}>CPF nº {props.cpf_representative}</p> 
                        <p style={{textAlign:'center'}}>{props.corporate_reason}</p> 
                        <p style={{textAlign:'center'}}>CNPJ nº {props.cnpj}</p>
            </div>}
            {props.tipoPessoa === 'pf' &&<div style={{ textAlign: 'center', width: '100%' }} className="procuracao-ass-pf">
            <p style={{textAlign:'center'}}>{props.name}</p>
            <p style={{textAlign:'center'}}>CPF nº {props.cpf}</p> 
            </div>}

          </div>
        </div>


      </div>
</Box>
</Grid>
</Collapse>





<Grid item xs={12} >
{/*<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',mt: 20,mb:2, width: '100%'}}>
    <Typography  sx={{ mt: 3, mb: 3 }}variant="h5">Solicitar Rateio</Typography>
      <Switch
checked={props.checked}
onChange={handleChange}
inputProps={{ 'aria-label': 'controlled'   }}
/>
    </Box>  */}
    <br />
    <br />
    <br />
  <Collapse in={props.modalityType === 'autoconsumo_remoto' ? true : false} timeout="auto" unmountOnExit>
  <div>
    <Alert severity="info">
      <ListItemText primary='Informações de Rateio' secondary='O resultado das porcentagens precia ser 100%'/>
    </Alert>
    {props.ucs?.map((uc, index) => (
      <Grid container key={index} spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={3}>
          <TextField size='small' label={`UC ${index + 1}`} value={uc} onChange={(e) => {
            e.target.value = e.target.value.replace(/\D/g, '');
            const newUcs = [...props.ucs];
            newUcs[index] = e.target.value;
            props.setUcs(newUcs);
          }} />
        </Grid>
        <Grid item xs={3}>
          <TextField size='small' label={`kWh ${index + 1}`} type="number" value={props.kwhValues[index]} onChange={(e) => {
            const newKwhValues = [...props.kwhValues];
            newKwhValues[index] = e.target.value;
            props.setKwhValues(newKwhValues);
          }} />
        </Grid>
        <Grid item xs={3}>
          <Button onClick={() => handleRemoveKwh(index)}>Remover</Button>
        </Grid>
      </Grid>
    ))}
    <br />
    <Button onClick={handleAddKwh} autoFocus>Adicionar Unidade Cosumidora (UC)</Button>
    <Button onClick={handleSubmit} variant='contained' color='primary'>Calcular e Salvar</Button>
    <Button onClick={handleClear} variant='contained' color='error' sx={{ ml: 1 }}>Limpar</Button>
    {props.percentages?.map((percentage, index) => (
      <Typography key={index}>
        kWh {index + 1}: {props.ucs[index]} - Porcentagem: {percentage}%
      </Typography>
    ))}
    <Typography>
      Porcentagem Total: {props.resultPercentages}%
    </Typography>
  </div>
  </Collapse>

</Grid>
</Grid>
  )
}

export default Documentation

