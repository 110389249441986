import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem } from '@mui/material';
import { Label, PlaylistAddCheckCircleRounded } from '@mui/icons-material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';   
import { toast, ToastContainer } from 'react-toastify';

function New_Edit_Inverter(props) {






  const grid_connection_typeOptions = [
    { label: 'Monofásico', value: 'monofasico' },
    { label: 'Bifásico', value: 'bifasico' },
    { label: 'Trifásico', value: 'trifasico' },
  
  ];

  const options = [
    { label: 'Sim', value: 'sim' },
    { label: 'Não', value: 'nao' },

  ];

    const [brand, setBrand] = useState('')
    const [model, setModel] = useState('')
    const [maximum_photovoltaic_power, setMaximum_photovoltaic_power] = useState('')
    const [maximum_supported_voltage, setMaximum_supported_voltage] = useState('')
    const [mppt_voltage_range, setMppt_voltage_range] = useState('')
    const [maximum_input_current, setMaximum_input_current] = useState('')
    const [maximum_short_circuit_current, setMaximum_short_circuit_current] = useState('')
    const [output_power, setOutput_power] = useState('')
    const [maximum_apparent_power, setMaximum_apparent_power] = useState('')
    const [rated_voltage_range, setRated_voltage_range] = useState('')
    const [frequency_range, setFrequency_range] = useState('')
    const [maximum_output_current, setMaximum_output_current] = useState('')
    const [thd, setThd] = useState('')
    const [apparent_power_factor, setApparent_power_factor] = useState('')
    const [type_of_network_connection, setType_of_network_connection] = useState('')
    const [undervoltage_and_overvoltage_protection, setUndervoltage_and_overvoltage_protection] = useState('')
    const [cc_insulation_protection, setCc_insulation_protection] = useState('')
    const [Ground_fault_protection_monitoring, setGround_fault_protection_monitoring] = useState('')
    const [network_failure_protection, setNetwork_failure_protection] = useState('')
    const [cC_injection_monitoring, setCc_injection_monitoring] = useState('')
    const [return_current_monitoring, setReturn_current_monitoring] = useState('')
    const [residual_current_protection, setResidual_current_protection] = useState('')
    const [anti_islanding_protection, setAnti_islanding_protection] = useState('')
    const [overload_protection, setOverload_protection] = useState('')
    const [overheat_protection, setOverheat_protection] = useState('')
    const [dimension_l_a_c, setDimension_l_a_c] = useState('')
    const [net_weight, setNet_weight] = useState('')
    const [operating_temperature_range, setOperating_temperature_range] = useState('')
    const [storage_relative_humidity, setStorage_relative_humidity] = useState('')
    const [degree_of_protection, setDegree_of_protection] = useState('')
    const [cooling, setCooling] = useState('')
    const [noise_level, setNoise_level] = useState('')
    const [mppt_by_string, setMppt_by_string] = useState('')
    const [start_up_voltage, setStart_up_voltage] = useState('')
const [datasheet, setDatasheet] = useState('')


const handleClose = () => {
    setBrand('')
    setModel('')
    setMaximum_photovoltaic_power('')
    setMaximum_supported_voltage('')
    setMppt_voltage_range('')
    setMaximum_input_current('')
    setMaximum_short_circuit_current('')
    setOutput_power('')
    setMaximum_apparent_power('')
    setRated_voltage_range('')
    setFrequency_range('')
    setMaximum_output_current('')
    setThd('')
    setApparent_power_factor('')
    setType_of_network_connection('')
    setUndervoltage_and_overvoltage_protection('')
    setCc_insulation_protection('')
    setGround_fault_protection_monitoring('')
    setNetwork_failure_protection('')
    setCc_injection_monitoring('')
    setReturn_current_monitoring('')
    setResidual_current_protection('')
    setAnti_islanding_protection('')
    setOverload_protection('')
    setOverheat_protection('')
    setDimension_l_a_c('')
    setNet_weight('')
    setOperating_temperature_range('')
    setStorage_relative_humidity('')
    setDegree_of_protection('')
    setCooling('')
    setNoise_level('')
    setMppt_by_string('')
    setStart_up_voltage('')
    setDatasheet('')


    props.setData('')
    props.handleClose()
}

const handleNewInverter = async () => {
if(!brand || !model || !maximum_photovoltaic_power || !maximum_supported_voltage || !mppt_voltage_range || !maximum_input_current || !maximum_short_circuit_current || !output_power || !maximum_apparent_power || !rated_voltage_range || !frequency_range || !maximum_output_current || !thd || !apparent_power_factor || !type_of_network_connection || !undervoltage_and_overvoltage_protection || !cc_insulation_protection || !Ground_fault_protection_monitoring || !network_failure_protection || !cC_injection_monitoring || !return_current_monitoring || !residual_current_protection || !anti_islanding_protection || !overload_protection || !overheat_protection || !dimension_l_a_c || !net_weight || !operating_temperature_range || !storage_relative_humidity || !degree_of_protection || !cooling || !noise_level || !mppt_by_string || !start_up_voltage ){
  return  toast.error('Preencha todos os campos')
}
  
  


    //const response = await fetch('https://api-aplication-fortesol.vercel.app/api/photovoltaic_modules', {
    const response = await fetch('http://15.228.8.54:5000/api/inverters/create', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ brand, model, maximum_photovoltaic_power, maximum_supported_voltage, mppt_voltage_range, maximum_input_current, maximum_short_circuit_current, output_power, maximum_apparent_power, rated_voltage_range, frequency_range, maximum_output_current, thd, apparent_power_factor, type_of_network_connection, undervoltage_and_overvoltage_protection, cc_insulation_protection, Ground_fault_protection_monitoring, network_failure_protection, cC_injection_monitoring, return_current_monitoring, residual_current_protection, anti_islanding_protection, overload_protection, overheat_protection, dimension_l_a_c, net_weight, operating_temperature_range, storage_relative_humidity, degree_of_protection, cooling, noise_level, mppt_by_string, start_up_voltage})
        }).then((response) => response.json())
    .then((data) => {
      
        if(data.message === 'Photovoltaic inverter created successfully'){
          document.getElementById('bt-search').click()
           handleClose()
        }
    }).catch((error) => {
        console.log(error)
    })
    return response 
}

const handleNewValue = (value) => {
    
if(value === 'Sim') return 'sim'
if(value === 'Não') return 'nao'
if(value === 'Monofásico') return String('monofasico')
if(value === 'Bifásico') return 'bifasico'
if(value === 'Trifásico') return 'trifasico'
}


const handleEditInverter = async () => {
    const brand = document.getElementById('brand-inverter').value
    const model = document.getElementById('model-inverter').value
    const maximum_photovoltaic_power = document.getElementById('maximum_photovoltaic_power-inverter').value
    const maximum_supported_voltage = document.getElementById('maximum_supported_voltage-inverter').value
    const mppt_voltage_range = document.getElementById('mppt_voltage_range-inverter').value
    const maximum_input_current = document.getElementById('maximum_input_current-inverter').value
    const maximum_short_circuit_current = document.getElementById('maximum_short_circuit_current-inverter').value
    const mppt_by_string = document.getElementById('mppt_by_string-inverter').value
    const output_power = document.getElementById('output_power-inverter').value
    const maximum_apparent_power = document.getElementById('maximum_apparent_power-inverter').value
    const rated_voltage_range = document.getElementById('rated_voltage_range-inverter').value
    const frequency_range = document.getElementById('frequency_range-inverter').value
    const maximum_output_current = document.getElementById('maximum_output_current-inverter').value
    const thd = document.getElementById('thd-inverter').value
    const apparent_power_factor = document.getElementById('apparent_power_factor-inverter').value
    const type_of_network_connection =  handleNewValue(document.getElementById('grid_connection_type-inverter').innerText)
    const undervoltage_and_overvoltage_protection = handleNewValue(document.getElementById('under_over_voltage_protection').innerText)
    const cc_insulation_protection = handleNewValue(document.getElementById('cc_insulation_protection').innerText)
    const Ground_fault_protection_monitoring = handleNewValue(document.getElementById('ground_fault_protection_monitoring').innerText)
    const network_failure_protection = handleNewValue(document.getElementById('grid_failure_protection').innerText)
    const cC_injection_monitoring = handleNewValue(document.getElementById('cc_injection_monitoring').innerText)
    const return_current_monitoring = handleNewValue(document.getElementById('return_current_monitoring').innerText)
    const residual_current_protection = handleNewValue(document.getElementById('residual_current_protection').innerText)
    const anti_islanding_protection = handleNewValue(document.getElementById('islanding_protection').innerText)
    const overload_protection = handleNewValue(document.getElementById('overload_protection').innerText)
    const overheat_protection = handleNewValue(document.getElementById('overheating_protection').innerText)
    const dimension_l_a_c = document.getElementById('dimension_l_a_c-inverter').value
    const net_weight = document.getElementById('net_weight-inverter').value
    const operating_temperature_range = document.getElementById('operating_temperature_range-inverter').value
    const storage_relative_humidity = document.getElementById('storage_relative_humidity-inverter').value
    const degree_of_protection = document.getElementById('degree_of_protection-inverter').value
    const cooling = document.getElementById('cooling-inverter').value
    const noise_level = document.getElementById('noise_level-inverter').value
    const start_up_voltage = document.getElementById('start_up_voltage-inverter').value



 
  

 
   
    //const response = await fetch(`https://api-aplication-fortesol.vercel.app/api/inverters/${props?.data.id}`, {
    const response = await fetch(`http://15.228.8.54:5000/api/inverters/update/${props?.data.id_inverters}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ brand, model, maximum_photovoltaic_power, maximum_supported_voltage, mppt_voltage_range, maximum_input_current, maximum_short_circuit_current, mppt_by_string, output_power, maximum_apparent_power, rated_voltage_range, frequency_range, maximum_output_current, thd, apparent_power_factor, type_of_network_connection, undervoltage_and_overvoltage_protection, cc_insulation_protection, Ground_fault_protection_monitoring, network_failure_protection, cC_injection_monitoring, return_current_monitoring, residual_current_protection, anti_islanding_protection, overload_protection, overheat_protection, dimension_l_a_c, net_weight, operating_temperature_range, storage_relative_humidity, degree_of_protection, cooling, noise_level, start_up_voltage })
    }).then((response) => response.json())
    .then((data) => {
      
        if(data.message === 'Photovoltaic inverter updated successfully'){
          document.getElementById('bt-search').click()
           handleClose()
        }
    }).catch((error) => {
        console.log(error)
    })
    return response
}

  

 

  return (
<>
<Dialog
                            open={props.open}
                            onClose={props.handleClose}
                        
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            maxWidth='1200px'
                            sx={{ml:5}}
                        >
                            <DialogTitle id="alert-dialog-title">{props.title === 'new' ? 'Cadastro de Inversores' : 'Editar Inversor'}</DialogTitle>
                            <DialogContent>
    <ToastContainer
            position="bottom-right"
            autoClose={4000}
            newestOnTop={false}
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
            height={50}
            
          />
<Alert severity="info">Preencha os campos abaixo de acordo com as referências exemplificadas para salvar o inversor corretamente.</Alert>
    <Grid sx={{mt:1}} container spacing={2}>
   



 


    <Grid item xs={12} sm={6}>
        <TextField
        id='brand-inverter'
        label="Marca"
        value={brand ? brand : (props?.data?.brand ? props?.data?.brand : '')}
          fullWidth
           size="small"
          onChange={(e) => {
            setBrand(e.target.value)
          }
          }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
        <TextField
        id='model-inverter'
        label="Modelo"
        value={model ? model : (props?.data?.model ? props?.data?.model : '')}
          fullWidth
           size="small"
          onChange={(e) => {
            setModel(e.target.value)
          }
          }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
        <TextField
        id='maximum_photovoltaic_power-inverter'
        label="Potência Fotovoltaica Máxima"
        placeholder='Ex : 00kW'
        value={maximum_photovoltaic_power ? maximum_photovoltaic_power : (props?.data?.maximum_photovoltaic_power ? props?.data?.maximum_photovoltaic_power : '')}
            fullWidth
             size="small"
            onChange={(e) => {
                setMaximum_photovoltaic_power(e.target.value)
            }
            }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
        <TextField
        id='maximum_supported_voltage-inverter'
        label="Tensão Máxima Suportada"
        placeholder='Ex : 0000 V'
        value={maximum_supported_voltage ? maximum_supported_voltage : (props?.data?.maximum_supported_voltage ? props?.data?.maximum_supported_voltage : '')}
            fullWidth
             size="small"
            onChange={(e) => {
                setMaximum_supported_voltage(e.target.value)
            }
            }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
        <TextField
        id='start_up_voltage-inverter'
        label="Tensão de Inicialização"
        placeholder='Ex : 000 V'
        value={start_up_voltage ? start_up_voltage : (props?.data?.start_up_voltage ? props?.data?.start_up_voltage : '')}
            fullWidth
             size="small"
            onChange={(e) => {
                setStart_up_voltage(e.target.value)
            }
            }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
        <TextField
        id='mppt_voltage_range-inverter'
        label="Faixa de Tensão do MPPT"
        placeholder='Ex : 000-000 V'
        value={mppt_voltage_range ? mppt_voltage_range : (props?.data?.mppt_voltage_range ? props?.data?.mppt_voltage_range : '')}
            fullWidth
             size="small"
            onChange={(e) => {
                setMppt_voltage_range(e.target.value)
            }
            }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
        <TextField
        id='maximum_input_current-inverter'
        label="Corrente Máxima de Entrada"
        placeholder='Ex : 0 0 0 0 A'
        value={maximum_input_current ? maximum_input_current : (props?.data?.maximum_input_current ? props?.data?.maximum_input_current : '')}
            fullWidth
             size="small"
            onChange={(e) => {
                setMaximum_input_current(e.target.value)
            }
            }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
        <TextField
        id='maximum_short_circuit_current-inverter'
        label="Corrente Máxima de Curto-Circuito"
        placeholder='Ex : 0 0 0 0 A'
        value={maximum_short_circuit_current ? maximum_short_circuit_current : (props?.data?.maximum_short_circuit_current ? props?.data?.maximum_short_circuit_current : '')}
            fullWidth
             size="small"
            onChange={(e) => {
                setMaximum_short_circuit_current(e.target.value)
            }
            }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
        <TextField
        id='mppt_by_string-inverter'
        label="Número de MPPT / Strings por MPPT"
        placeholder='Ex : 0/0 ou 0/0+0+0'
        value={mppt_by_string ? mppt_by_string : (props?.data?.mppt_by_string ? props?.data?.mppt_by_string : '')}
            fullWidth
             size="small"
            onChange={(e) => {
                setMppt_by_string(e.target.value)
            }
            }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
        <TextField
        id='output_power-inverter'
        label="Potência de Saída"
        placeholder='Ex : 00000 W'
        value={output_power ? output_power : (props?.data?.output_power ? props?.data?.output_power : '')}
            fullWidth
             size="small"
            onChange={(e) => {
                setOutput_power(e.target.value)
            }
            }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
        <TextField
        id='maximum_apparent_power-inverter'
        label="Potência Máxima Aparente"
        placeholder='Ex : 000000 WA'
        value={maximum_apparent_power ? maximum_apparent_power : (props?.data?.maximum_apparent_power ? props?.data?.maximum_apparent_power : '')}
            fullWidth
             size="small"
            onChange={(e) => {
                setMaximum_apparent_power(e.target.value)
            }
            }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
        <TextField
        id='rated_voltage_range-inverter'
        label="Faixa de Tensão Nominal"
        placeholder='Ex : 220V ou 220/380V'
        value={rated_voltage_range ? rated_voltage_range : (props?.data?.rated_voltage_range ? props?.data?.rated_voltage_range : '')}
            fullWidth
             size="small"
            onChange={(e) => {
                setRated_voltage_range(e.target.value)
            }
            }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
        <TextField
        id='frequency_range-inverter'
        label="Faixa de Frequência"
        placeholder='Ex : 00/00HZ'
        value={frequency_range ? frequency_range : (props?.data?.frequency_range ? props?.data?.frequency_range : '')}
            fullWidth
             size="small"
            onChange={(e) => {
                setFrequency_range(e.target.value)
            }
            }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
        <TextField
        id='maximum_output_current-inverter'
        label="Corrente Máxima de Saída"
        placeholder='Ex : 00,0 A'
        value={maximum_output_current ? maximum_output_current : (props?.data?.maximum_output_current ? props?.data?.maximum_output_current : '')}
            fullWidth
             size="small"
            onChange={(e) => {
                setMaximum_output_current(e.target.value)
            }
            }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
        <TextField
        id='thd-inverter'
        label="THD"
        placeholder='Ex : <0%'
        value={thd ? thd : (props?.data?.thd ? props?.data?.thd : '')}
            fullWidth
             size="small"
            onChange={(e) => {
                setThd(e.target.value)
            }
            }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
        <TextField
        id='apparent_power_factor-inverter'
        label="Fator de Potência Aparente"
        placeholder='Ex : >0,0%'
        value={apparent_power_factor ? apparent_power_factor : (props?.data?.apparent_power_factor ? props?.data?.apparent_power_factor : '')}
            fullWidth
             size="small"
            onChange={(e) => {
                setApparent_power_factor(e.target.value)
            }
            }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
        <TextField
        select
        id='grid_connection_type-inverter'
        label="Tipo de Conexão com a Rede"
        value={type_of_network_connection ? type_of_network_connection : (props?.data?.type_of_network_connection ? props?.data?.type_of_network_connection : '')}
            fullWidth
             size="small"
            onChange={(e) => {
                setType_of_network_connection(e.target.value)
            }
            }
        >
            {grid_connection_typeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    </Grid>
   

            <Grid item xs={12} sm={6}
    sx={{display:'flex',alignItems:'center'}}
    >
        <TextField
        select
        id='under_over_voltage_protection'
        label="Proteção Contra Subtensão e Sobretensão"
        value={undervoltage_and_overvoltage_protection ? undervoltage_and_overvoltage_protection : (props?.data?.undervoltage_and_overvoltage_protection ? props?.data?.undervoltage_and_overvoltage_protection : '')}
            fullWidth
                size="small"
            onChange={(e) => {
                setUndervoltage_and_overvoltage_protection(e.target.value)
            }
            }
        >
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    </Grid>
    
    <Grid item xs={12} sm={6}
    sx={{display:'flex',alignItems:'center'}}
    >
        <TextField
        select
        id='cc_insulation_protection'
        label="Proteção de Isolação CC"
        value={cc_insulation_protection ? cc_insulation_protection : (props?.data?.cc_insulation_protection ? props?.data?.cc_insulation_protection : '')}
            fullWidth
                size="small"
            onChange={(e) => {
                setCc_insulation_protection(e.target.value)
            }
            }
        >
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    </Grid>
    <Grid item xs={12} sm={6}
    sx={{display:'flex',alignItems:'center'}}
    >
        <TextField
        select
        id='ground_fault_protection_monitoring'
        label="Monitoramento da Proteção contra falha no aterramento"
        value={Ground_fault_protection_monitoring ? Ground_fault_protection_monitoring : (props?.data?.Ground_fault_protection_monitoring ? props?.data?.Ground_fault_protection_monitoring : '')}
            fullWidth
                size="small"
            onChange={(e) => {
                setGround_fault_protection_monitoring(e.target.value)
            }
            }
        >
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    </Grid>
    <Grid item xs={12} sm={6}
    sx={{display:'flex',alignItems:'center'}}
    >
        <TextField
        select
        id='grid_failure_protection'
        label="Proteção Contra Falha na Rede"
        value={network_failure_protection ? network_failure_protection : (props?.data?.network_failure_protection ? props?.data?.network_failure_protection : '')}
            fullWidth
                size="small"
            onChange={(e) => {
                setNetwork_failure_protection(e.target.value)
            }
            }
        >
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    </Grid>
    <Grid item xs={12} sm={6}
    sx={{display:'flex',alignItems:'center'}}
    >
        <TextField
        select
        id='cc_injection_monitoring'
        label="Monitoramento de Injeção CC"
        value={cC_injection_monitoring ? cC_injection_monitoring : (props?.data?.cC_injection_monitoring ? props?.data?.cC_injection_monitoring : '')}
            fullWidth
                size="small"
            onChange={(e) => {
                setCc_injection_monitoring(e.target.value)
            }
            }
        >
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    </Grid>

    <Grid item xs={12} sm={6}
    sx={{display:'flex',alignItems:'center'}}
    >
        <TextField
        select
        id='return_current_monitoring'
        label="Monitoramento da Corrente de Retorno"
        value={return_current_monitoring ? return_current_monitoring : (props?.data?.return_current_monitoring ? props?.data?.return_current_monitoring : '')}
            fullWidth
                size="small"
            onChange={(e) => {
                setReturn_current_monitoring(e.target.value)
            }
            }
        >
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    </Grid>
    <Grid item xs={12} sm={6}
    sx={{display:'flex',alignItems:'center'}}
    >
        <TextField
        select
        id='residual_current_protection'
        label="Proteção contra Corrente Residual"
        value={residual_current_protection ? residual_current_protection : (props?.data?.residual_current_protection ? props?.data?.residual_current_protection : '')}
            fullWidth
                size="small"
            onChange={(e) => {
                setResidual_current_protection(e.target.value)
            }
            }
        >
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    </Grid>
    <Grid item xs={12} sm={6}
    sx={{display:'flex',alignItems:'center'}}
    >
        <TextField
        select
        id='islanding_protection'
        label="Proteção contra Anti-Ilhamento"
        value={anti_islanding_protection ? anti_islanding_protection : (props?.data?.anti_islanding_protection ? props?.data?.anti_islanding_protection : '')}
            fullWidth
                size="small"
            onChange={(e) => {
                setAnti_islanding_protection(e.target.value)
            }
            }
        >
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    </Grid>
    <Grid item xs={12} sm={6}
    sx={{display:'flex',alignItems:'center'}}
    >
        <TextField
        select
        id='overload_protection'
        label="Proteção contra Sobrecarga"
        value={overload_protection ? overload_protection : (props?.data?.overload_protection ? props?.data?.overload_protection : '')}
            fullWidth
                size="small"
            onChange={(e) => {
                setOverload_protection(e.target.value)
            }
            }
        >
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    </Grid>
    <Grid item xs={12} sm={6}
    sx={{display:'flex',alignItems:'center'}}
    >
        <TextField
        select
        id='overheating_protection'
        label="Proteção contra Super Aquecimento"
        value={overheat_protection ? overheat_protection : (props?.data?.overheat_protection ? props?.data?.overheat_protection : '')}
            fullWidth
                size="small"
            onChange={(e) => {
                setOverheat_protection(e.target.value)
            }
            }
        >
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    </Grid>
    <Grid item xs={12} sm={6}>
        <TextField
        id='dimension_l_a_c-inverter'
        label="Dimensão (L x A x C)"
        placeholder='Ex : 000 / 000 / 000 mm'
        value={dimension_l_a_c ? dimension_l_a_c : (props?.data?.dimension_l_a_c ? props?.data?.dimension_l_a_c : '')}
            fullWidth
             size="small"
            onChange={(e) => {
                setDimension_l_a_c(e.target.value)
            }
            }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
        <TextField
        id='net_weight-inverter'
        label="Peso Liquido"
        placeholder='Ex : 0 KG'
        value={net_weight ? net_weight : (props?.data?.net_weight ? props?.data?.net_weight : '')}
            fullWidth
             size="small"
            onChange={(e) => {
                setNet_weight(e.target.value)
            }
            }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
        <TextField
        id='operating_temperature_range-inverter'
        label="Faixa de Temperatura de Operação"
        placeholder='Ex : 0 a 0 °C'
        value={operating_temperature_range ? operating_temperature_range : (props?.data?.operating_temperature_range ? props?.data?.operating_temperature_range : '')}
            fullWidth
             size="small"
            onChange={(e) => {
                setOperating_temperature_range(e.target.value)
            }
            }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
        <TextField
        id='storage_relative_humidity-inverter'
        label="Umidade Relativa de Armazenamento"
        placeholder='Ex : 0%'
        value={storage_relative_humidity ? storage_relative_humidity : (props?.data?.storage_relative_humidity ? props?.data?.storage_relative_humidity : '')}
            fullWidth
             size="small"
            onChange={(e) => {
                setStorage_relative_humidity(e.target.value)
            }
            }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
        <TextField
        id='degree_of_protection-inverter'
        label="Grau de Proteção"
        placeholder='Ex : IP65'
        value={degree_of_protection ? degree_of_protection : (props?.data?.degree_of_protection ? props?.data?.degree_of_protection : '')}
            fullWidth
             size="small"
            onChange={(e) => {
                setDegree_of_protection(e.target.value)
            }
            }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
        <TextField
        id='cooling-inverter'
        label="Resfriamento"
        placeholder='Ex : ventilador'
        value={cooling ? cooling : (props?.data?.cooling ? props?.data?.cooling : '')}
            fullWidth
             size="small"
            onChange={(e) => {
                setCooling(e.target.value)
            }
            }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
        <TextField
        id='noise_level-inverter'
        label="Nível de Ruído"
        placeholder='Ex : <45 dB(A)'
        value={noise_level ? noise_level : (props?.data?.noise_level ? props?.data?.noise_level : '')}
            fullWidth
             size="small"
            onChange={(e) => {
                setNoise_level(e.target.value)
            }
            }
        />
    </Grid>







    <Grid item xs={12} sm={6}
    sx={{display:'flex',alignItems:'center'}}
    >
      <Button component="label" 
        variant="contained"
      >
        Carrregar Datasheet
        <input type="file" hidden onChange={
          (e) => setDatasheet(e.target.files[0])
        } />
        &nbsp;
        <CloudUploadIcon />
      </Button>
    </Grid>
      </Grid>



    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        Cancelar
      </Button>
     {props?.title ==='edit' && <Button onClick={handleEditInverter} variant='contained' color="primary"
      autoFocus>
        Salvar
      </Button>}
 {props?.title === 'new'&&  <Button onClick={handleNewInverter} variant='contained' color="primary"
      autoFocus>
        Cadastrar
      </Button>}
    </DialogActions>
    </Dialog>
   
  </>
  
  )
}

export default New_Edit_Inverter
