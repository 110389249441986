import React, { useState } from 'react'
import './App.css'
import {
 

FaUser ,
   FaRegFileAlt,
  FaArrowRight,
  FaArrowLeft,
  FaRegSun,
  FaUserAlt,
  FaIdCardAlt,
  FaHome,
  FaUserFriends,
  FaRegCalendarAlt

} from 'react-icons/fa'
import { NavLink } from 'react-router-dom';
import * as C from './styles'


import useAuth from '../../hooks/useAuth';
import { GiHamburgerMenu } from "react-icons/gi";
import { Container, div } from '@mui/material';
import logoMain from '../../assets/logoMain.png'
import logoSemFundo from '../../assets/logosemfundo.png'

const Sidebar = (props) => {

  const token = localStorage.getItem('token')
if(!token){
  window.location.href = '/login'
}
  const typeUser = JSON.parse(token).typeUser
  const name_user = JSON.parse(token).name
  
  const nome = name_user.split(' ')[0]

  const { logout } = useAuth()

  const[isOpen ,setIsOpen] = useState(false);

  
  const toggle = () => setIsOpen (!isOpen);
  const[isOpenHanb ,setIsOpenHanb] = useState(false);
  const toggleHanb = () => setIsOpenHanb (!isOpenHanb);
  let menuItem=[]
  if(typeUser === 'Administrador'){
    menuItem=[
      {}]
    }
  if(typeUser === 'Cliente'){
    menuItem=[
      { 
        path:"/home",
        name:"Home",
        icon:<FaHome />,
        id:"sidebar-home"
    },
    {
      path:"/solar-service",
      name:"Projetos",
      icon:< FaRegFileAlt/>,
      id:"sidebar-solar-service"
  },
    ]
  }
  if(typeUser === 'Administrador'){
    menuItem=[
     
      { 
        path:"/home",
        name:"Home",
        icon:<FaHome />,
        id:"sidebar-home"

    },
    {
        path:"/employees",
        name:"Funcionários",
        icon:<FaIdCardAlt/>,
        id:"sidebar-employees"

    },
    {
      path:"/customers",
      name:"Clientes",
      icon:<FaUserFriends/>,
      id:"sidebar-customers"
    },
    {
        path:"/services",
        name:"Serviços",
        icon:< FaRegFileAlt/>,
        id:"sidebar-services"
    },

    {
        path:"/calendar",
        name:"Calendário",
        icon:<FaRegCalendarAlt/>,
        id:"sidebar-calendar"
    },/*
    {
        path:"/email",
        name:"Mail",
        icon:<FaEnvelope/>
    },*/
    {
        path:"/settings",
        name:"Configurações",
        icon:<FaRegSun />,
        id:"sidebar-settings"
    },
    ]
  }
  return (

    <div id='side'>

  
        <div className='menuHanb' style={{ background: typeUser !== 'Cliente'?'#023047' : '#000000', background: typeUser !== 'Cliente'?'#023047' : '#000000',}} >
      <GiHamburgerMenu size={20} onClick={toggleHanb} />
        </div>
        <div className='opMenuHanb' style={{display: isOpenHanb ? "block" : "none", background: typeUser !== 'Cliente'?'#023047' : '#000000',}}>
        <div className='menuHanb' style={{ background: typeUser !== 'Cliente'?'#023047' : '#000000',}} >
      <GiHamburgerMenu size={20} onClick={toggleHanb} />
        </div>
        {
               menuItem.map((item, index)=>(
                     <NavLink to={item.path} key={index} id={item.id} className="link" activeclassname="active">
                         <div className="icon">{item.icon}</div>
                         <div  style={{display: isOpenHanb ? "block" : "none"}} className="link_text">{item.name}</div>
                     </NavLink>
                 ))
                }
                 <li style={{display:'flex', flexDirection:'row'}}>
                <li className="link" style={{paddingTop:'33px'}} >
                    <FaUser/>
                </li>
                    <C.Div>
                    <span>{nome}</span>
                      <C.Button text='Sair' onClick={() => logout()}>
                        Sair
                      </C.Button>
                    </C.Div>
                  </li>
        </div>
    <div className="container" >
      <div onMouseOver={()=>{
        setIsOpen(true)
      }}  
      onMouseLeave={()=>{
        setIsOpen(false)
      }}
      style={{
        background: typeUser !== 'Cliente'?'#023047' : '#000000',
      }}
      className="sidebar">
          
            {
            typeUser !== 'Cliente' &&
             <div className="top_section" style={{padding:5,paddingBottom:20,paddingTop:20}} >
                <img className='logo'  style={{display: isOpen ? "none" : "block", background: 'white',borderRadius:'10px'}} src={logoMain} alt="" height='40px' />
                <img className='logo'   style={{display: isOpen ? "block" : "none", background: 'white',borderRadius:'10px'}} src={logoSemFundo} alt="" height='45px' />
             </div> 
            }
            {
            typeUser === 'Cliente' &&
             <div className="top_section" >
               
             </div> 
            }
             {
               menuItem.map((item, index)=>(
                     <NavLink to={item.path} key={index} id={item.id} className="link" activeclassname="active">
                         <div className="icon">{item.icon}</div>
                         <div   style={{display: isOpen ? "block" : "none"}}  className="link_text">{item.name}</div>
                     </NavLink>
                 ))
                }
             <li style={{display: isOpen ? "block" : "none"}}>
                    <C.Div>
                    <span style={{fontSize:10}}>{nome}</span>
                      <C.Button text='Sair' onClick={() => logout()}>
                        Sair
                      </C.Button>
                    </C.Div>
                  </li>
                <li className="link" style={{  display: isOpen ? "none" : "block"}}>
                    <FaUser/>
                </li>
         </div>
    
         <div style={{
            marginLeft: isOpen || isOpenHanb ? "185px" : (isOpenHanb?'50px': "0px"),
            transition: "all 0.7s",
            width: '100%',
            maxHeight: '100vh',
            overflowY: 'auto',
            
         }}>
          {props.children}
         </div>
       </div>
       </div>
       
    
  )
}
export default Sidebar
