import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, MenuItem, Radio, RadioGroup } from '@mui/material';
import { Label, PlaylistAddCheckCircleRounded } from '@mui/icons-material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { toast, ToastContainer } from 'react-toastify';
import validator from 'validator';

function New_Edit_Employee(props) {


    const [name_employee, setName_employee] = useState('')
    const [rg_employee, setRg_employee] = useState('')
    const [cpf_employee, setCpf_employee] = useState('')
    const [civil_state_employee, setCivil_state_employee] = useState('')
    const [nationality_employee, setNationality_employee] = useState('')
    const [email_employee, setEmail_employee] = useState('')
    const [contact_employee, setContact_employee] = useState('')
    const [cep_employee, setCep_employee] = useState('')
    const [street_employee, setStreet_employee] = useState('')
    const [number_employee, setNumber_employee] = useState('')
    const [neighborhood_employee, setNeighborhood_employee] = useState('')
    const [city_employee, setCity_employee] = useState('')
    const [state_employee, setState_employee] = useState('')
    const [complement_employee, setComplement_employee] = useState('')
    const [roles_id, setRoles_id] = useState('')

    const [roles, setRoles] = useState([{ name_role: 'Selecione o departamento' }])
    const [departments, setDepartments] = useState([])
    const [arrayDepartments, setArrayDepartments] = useState([])

    useEffect(() => {

        if (props?.title === 'edit') {
       
            listDepartments()
            fetch(`http://15.228.8.54:5000/api/roles/search?search=${props?.data.name_departments}&page=1&limit=100`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => response.json())
                .then((data) => {
                   
                    setRoles(data[0])
                }).catch((error) => {
                    console.log(error)
                })
        }


    }, [props])


    const listDepartments = async () => {

        fetch('http://15.228.8.54:5000/api/departments/list-all', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => response.json())
            .then((data) => {
          
                setArrayDepartments(data)
            }).catch((error) => {
                console.log(error)
            })
    }

    const civil_states = [
        { label: 'Solteiro(a)', value: 'Solteiro(a)' },
        { label: 'União Estável', value: 'União Estável' },
        { label: 'Casado(a)', value: 'Casado(a)' },
        { label: 'Divorciado(a)', value: 'Divorciado(a)' },
        { label: 'Viúvo(a)', value: 'Viúvo(a)' },
    ];




    const handleClearFields = () => {
        setName_employee('')
        setRg_employee('')
        setCpf_employee('')
        setCivil_state_employee('')
        setNationality_employee('')
        setEmail_employee('')
        setContact_employee('')
        setCep_employee('')
        setStreet_employee('')
        setNumber_employee('')
        setNeighborhood_employee('')
        setCity_employee('')
        setState_employee('')
        setComplement_employee('')
        setRoles_id('')
        setDepartments([])
        setRoles([])

    }
    const handleClose = () => {
        handleClearFields()
        props.setData('')
        props.handleClose()
    }
  


    const handleNewEmployee = async () => {
        if (name_employee === '') {
            toast.error('Preencha o campo nome')
            document.getElementById('name_employee').focus()
            return
        }
        if (rg_employee === '') {
            toast.error('Preencha o campo RG')
            document.getElementById('rg_employee').focus()
            return
        }
        if (cpf_employee === '') {
            toast.error('Preencha o campo CPF')
            document.getElementById('cpf_employee').focus()
            return
        }
        if (civil_state_employee === '') {
            toast.error('Preencha o campo Estado Civil')
            document.getElementById('civil_state_employee').focus()
            return
        }
        if (nationality_employee === '') {
            toast.error('Preencha o campo Nacionalidade')
            document.getElementById('nationality_employee').focus()
            return
        }
        if (email_employee === '') {
            toast.error('Preencha o campo Email')
            document.getElementById('email_employee').focus()
            return
        }
        if (!validator.isEmail(email_employee)) {
            toast.error('Email inválido')
            document.getElementById('email_employee').focus()
            return
        }

        if (contact_employee === '') {
            toast.error('Preencha o campo Contato')
            document.getElementById('contact_employee').focus()
            return
        }
        if (cep_employee === '') {
            toast.error('Preencha o campo CEP')
            document.getElementById('cep_employee').focus()
            return
        }
        if (street_employee === '') {
            toast.error('Preencha o campo Rua')
            document.getElementById('street_employee').focus()
            return
        }
        if (number_employee === '') {
            toast.error('Preencha o campo Número')
            document.getElementById('number_employee').focus()
            return
        }
        if (neighborhood_employee === '') {
            toast.error('Preencha o campo Bairro')
            document.getElementById('neighborhood_employee').focus()
            return
        }
        if (city_employee === '') {
            toast.error('Preencha o campo Cidade')
            document.getElementById('city_employee').focus()
            return
        }
        if (state_employee === '') {
            toast.error('Preencha o campo Estado')
            document.getElementById('state_employee').focus()
            return
        }
        if (complement_employee === '') {
            toast.error('Preencha o campo Complemento')
            document.getElementById('complement_employee').focus()
            return
        }
        if (departments === '') {
            toast.error('Selecione o departamento')
            document.getElementById('departments').focus()
            return
        }
        if (roles_id === '') {
            toast.error('Selecione a função')
            document.getElementById('roles').focus()
            return
        }



        //const response = await fetch('https://api-aplication-fortesol.vercel.app/api/photovoltaic_modules', {
        const response = await fetch('http://15.228.8.54:5000/api/employees/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name_employee, rg_employee, cpf_employee, civil_state_employee, nationality_employee, email_employee, contact_employee, cep_employee, street_employee, number_employee, neighborhood_employee, city_employee, state_employee, complement_employee, roles_id })
        }).then((response) => response.json())
            .then((data) => {
                
                if (data.message === 'Employee created successfully') {
                    toast.success('Funcionário cadastrado com sucesso')
                    document.getElementById('bt-search').click()
                    handleClose()
                }
                else {
                    toast.error(`${data.message}`)
                }
            }).catch((error) => {
                console.log(error)
            })
        return response
    }







    const handleEditEmployee = async () => {
        fetch(`http://15.228.8.54:5000/api/roles/list/${roles_id ? roles_id : props?.data.id_roles}?page=1&limit=1`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => response.json())
            .then((data) => {
         
                const name_employee = document.getElementById('name_employee').value
                const rg_employee = document.getElementById('rg_employee').value
                const cpf_employee = document.getElementById('cpf_employee').value
                const civil_state_employee = document.getElementById('civil_state_employee').innerHTML
                const nationality_employee = document.getElementById('nationality_employee').value
                const email_employee = document.getElementById('email_employee').value
                const contact_employee = document.getElementById('contact_employee').value
                const cep_employee = document.getElementById('cep_employee').value
                const street_employee = document.getElementById('street_employee').value
                const number_employee = document.getElementById('number_employee').value
                const neighborhood_employee = document.getElementById('neighborhood_employee').value
                const city_employee = document.getElementById('city_employee').value
                const state_employee = document.getElementById('state_employee').value
                const complement_employee = document.getElementById('complement_employee').value

                const roles_id = data[0][0].id_roles

                if (name_employee === '') {
                    toast.error('Preencha o campo nome')
                    document.getElementById('name_employee').focus()
                    return
                }
                if (rg_employee === '') {
                    toast.error('Preencha o campo RG')
                    document.getElementById('rg_employee').focus()
                    return
                }
                if (cpf_employee === '') {
                    toast.error('Preencha o campo CPF')
                    document.getElementById('cpf_employee').focus()
                    return
                }
                if (civil_state_employee === '') {
                    toast.error('Preencha o campo Estado Civil')
                    document.getElementById('civil_state_employee').focus()
                    return
                }
                if (nationality_employee === '') {
                    toast.error('Preencha o campo Nacionalidade')
                    document.getElementById('nationality_employee').focus()
                    return
                }
                if (email_employee === '') {
                    toast.error('Preencha o campo Email')
                    document.getElementById('email_employee').focus()
                    return
                }
                if (!validator.isEmail(email_employee)) {
                    toast.error('Email inválido')
                    document.getElementById('email_employee').focus()
                    return
                }
                if (contact_employee === '') {
                    toast.error('Preencha o campo Contato')
                    document.getElementById('contact_employee').focus()
                    return
                }
                if (cep_employee === '') {
                    toast.error('Preencha o campo CEP')
                    document.getElementById('cep_employee').focus()
                    return
                }
                if (street_employee === '') {
                    toast.error('Preencha o campo Rua')
                    document.getElementById('street_employee').focus()
                    return
                }

                if (number_employee === '') {
                    toast.error('Preencha o campo Número');
                    document.getElementById('number_employee').focus();
                    return;
                }
                if (neighborhood_employee === '') {
                    toast.error('Preencha o campo Bairro');
                    document.getElementById('neighborhood_employee').focus();
                    return;
                }
                if (city_employee === '') {
                    toast.error('Preencha o campo Cidade');
                    document.getElementById('city_employee').focus();
                    return;
                }
                if (state_employee === '') {
                    toast.error('Preencha o campo Estado');
                    document.getElementById('state_employee').focus();
                    return;
                }
                if (complement_employee === '') {
                    toast.error('Preencha o campo Complemento');
                    document.getElementById('complement_employee').focus();
                    return;
                }
                if (roles_id === '') {
                    toast.error('Selecione uma função');
                    document.getElementById('roles').focus();
                    return;
                }




                //const response = await fetch(`https://api-aplication-fortesol.vercel.app/api/employees/${props?.data.id}`, {
                fetch(`http://15.228.8.54:5000/api/employees/update/${props?.data.id_employee}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ name_employee, rg_employee, cpf_employee, civil_state_employee, nationality_employee, email_employee, contact_employee, cep_employee, street_employee, number_employee, neighborhood_employee, city_employee, state_employee, complement_employee, roles_id })
                }).then((response) => response.json())
                    .then((data) => {
                        
                        if (data.message === 'Employee updated successfully') {
                            toast.success('Funcionário atualizado com sucesso')
                            document.getElementById('bt-search').click()
                            handleClose()
                        }
                    }).catch((error) => {
                        console.log(error)
                    })

                return data
            }).catch((error) => {
                console.log(error)
            })
    }

    const phone_format = (phone) => {
        phone = phone.replace(/\D/g, "")
        phone = phone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3")

        if (phone.length > 15) {
            phone = phone.substring(0, 14)
        }

        return phone
    }
    const cpf_format = (cpf) => {

        cpf = cpf.replace(/\D/g, "")
        cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
        cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
        cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2")

        if (cpf.length > 14) {
            cpf = cpf.substring(0, 13)
        }

        return cpf
    }
    const cep_format = (cep) => {
        cep = cep.replace(/\D/g, "")
        cep = cep.replace(/(\d{5})(\d{3})/, "$1-$2")

        if (cep.length > 9) {
            cep = cep.substring(0, 8)
        }

        return cep
    }







    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.handleClose}

                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='1200px'
                sx={{ ml: 5 }}
            >
                <DialogTitle id="alert-dialog-title">{props.title === 'new' ? 'Cadastro de Funcionários' : 'Editar Funcionário'}</DialogTitle>
                <DialogContent>

                    <Alert severity="info">Preencha os campos abaixo para salvar as informações.</Alert>

                    <br />
                    {props?.title === 'new' &&
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size='small'
                                    id="name_employee"
                                    label="Nome"
                                    type="text"
                                    fullWidth
                                    onClick={listDepartments}
                                    value={name_employee}
                                    onChange={(e) => setName_employee(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size='small'
                                    id="rg_employee"
                                    label="RG"
                                    type="text"
                                    fullWidth
                                    value={rg_employee}
                                    onChange={(e) => {
                                        e.target.value = e.target.value.replace(/\D/g, "")
                                        setRg_employee(e.target.value)
                                    }
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size='small'
                                    id="cpf_employee"
                                    label="CPF"
                                    type="text"
                                    fullWidth
                                    onClick={listDepartments}
                                    value={cpf_employee}
                                    onChange={(e) => {
                                        e.target.value = cpf_format(e.target.value)
                                        setCpf_employee(e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    select
                                    size='small'
                                    id="civil_state_employee"
                                    label="Estado Civil"

                                    fullWidth
                                    value={civil_state_employee}
                                    onChange={(e) => setCivil_state_employee(e.target.value)}
                                >
                                    {civil_states.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size='small'
                                    id='nationality_employee'
                                    label="Nacionalidade"
                                    type="text"
                                    fullWidth
                                    value={nationality_employee}
                                    onChange={(e) => setNationality_employee(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size='small'
                                    id='email_employee'
                                    label="Email"
                                    type="text"
                                    fullWidth
                                    value={email_employee}
                                    onChange={(e) => {
                                        setEmail_employee(e.target.value)
                                        if (e.target.value === '') {
                                            document.getElementById('email_employee').focus()
                                            document.getElementById('email_employee').style.border = '1px solid red'
                                        } else {
                                            document.getElementById('email_employee').style.border = '1px solid #ccc'
                                        }
                                        if (!e.target.value.includes('@')) {
                                            document.getElementById('email_employee').focus()
                                            document.getElementById('email_employee').style.border = '1px solid red'
                                        }
                                        else {
                                            document.getElementById('email_employee').style.border = '1px solid #ccc'
                                        }
                                    }}

                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size='small'
                                    id='contact_employee'
                                    label="Contato"
                                    type="text"
                                    fullWidth
                                    value={contact_employee}
                                    onChange={(e) => {
                                        e.target.value = phone_format(e.target.value)
                                        setContact_employee(e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size='small'
                                    id='cep_employee'
                                    label="CEP"
                                    type="text"
                                    fullWidth
                                    value={cep_employee}
                                    onChange={(e) => {
                                        e.target.value = cep_format(e.target.value)
                                        setCep_employee(e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size='small'
                                    id='street_employee'
                                    label="Logradouro"
                                    type="text"
                                    fullWidth
                                    value={street_employee}
                                    onChange={(e) => setStreet_employee(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size='small'
                                    id='number_employee'
                                    label="Número"
                                    type="text"
                                    fullWidth
                                    value={number_employee}
                                    onChange={(e) => {
                                        e.target.value = e.target.value.replace(/\D/g, "")
                                        setNumber_employee(e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size='small'
                                    id='neighborhood_employee'
                                    label="Bairro"
                                    type="text"
                                    fullWidth
                                    value={neighborhood_employee}
                                    onChange={(e) => setNeighborhood_employee(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size='small'
                                    id='city_employee'
                                    label="Cidade"
                                    type="text"
                                    fullWidth
                                    value={city_employee}
                                    onChange={(e) => setCity_employee(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size='small'
                                    id='state_employee'
                                    label="Estado"
                                    type="text"
                                    fullWidth
                                    value={state_employee}
                                    onChange={(e) => setState_employee(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size='small'
                                    id='complement_employee'
                                    label="Complemento"
                                    type="text"
                                    fullWidth

                                    value={complement_employee}
                                    onChange={(e) => setComplement_employee(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    select
                                    size='small'
                                    id='departments'
                                    label="Departamento"
                                    fullWidth
                                    value={departments}
                                    onClick={listDepartments}
                                    onChange={(e) => {
                                        setDepartments(e.target.value)
                                        fetch(`http://15.228.8.54:5000/api/roles/search?search=${e.target.value}&page=1&limit=100`, {
                                            method: 'GET',
                                            headers: {
                                                'Content-Type': 'application/json'
                                            }
                                        }).then((response) => response.json())
                                            .then((data) => {
                                                
                                                setRoles(data[0])
                                            }).catch((error) => {
                                                console.log(error)
                                            })
                                    }}

                                >
                                    {arrayDepartments?.map((item) => (
                                        <MenuItem key={item.id_departments} value={item.id_departments}>
                                            {item.name_departments}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    select
                                    size='small'
                                    id='roles'
                                    label="Função"
                                    fullWidth
                                    value={roles_id}
                                    onChange={(e) => {
                                        setRoles_id(e.target.value)
                                    }}
                                >
                                    {roles?.map((item) => (
                                        <MenuItem key={item.id_roles} value={item.id_roles}>
                                            {item.name_role}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                    }

                    {props?.title === 'edit' &&
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size='small'
                                    id="name_employee"
                                    label="Nome"
                                    type="text"
                                    fullWidth
                                    onClick={listDepartments}
                                    value={name_employee ? name_employee : props?.data.name_employee}
                                    onChange={(e) => setName_employee(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size='small'
                                    id="rg_employee"
                                    label="RG"
                                    type="text"
                                    fullWidth
                                    value={rg_employee ? rg_employee : props?.data.rg_employee}
                                    onChange={(e) => {
                                        e.target.value = e.target.value.replace(/\D/g, "")
                                        setRg_employee(e.target.value)
                                    }
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size='small'
                                    id="cpf_employee"
                                    label="CPF"
                                    type="text"
                                    fullWidth
                                    onClick={listDepartments}
                                    value={cpf_employee ? cpf_employee : props?.data.cpf_employee}
                                    onChange={(e) => {
                                        e.target.value = cpf_format(e.target.value)
                                        setCpf_employee(e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    select
                                    size='small'
                                    id="civil_state_employee"
                                    label="Estado Civil"

                                    fullWidth
                                    value={civil_state_employee ? civil_state_employee : props?.data.civil_state_employee}
                                    onChange={(e) => setCivil_state_employee(e.target.value)}
                                >
                                    {civil_states.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size='small'
                                    id='nationality_employee'
                                    label="Nacionalidade"
                                    type="text"
                                    fullWidth
                                    value={nationality_employee ? nationality_employee : props?.data.nationality_employee}
                                    onChange={(e) => setNationality_employee(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size='small'
                                    id='email_employee'
                                    label="Email"
                                    type="text"
                                    fullWidth
                                    value={email_employee ? email_employee : props?.data.email_employee}
                                    onChange={(e) => {
                                        setEmail_employee(e.target.value)
                                        if (e.target.value === '') {
                                            document.getElementById('email_employee').focus()
                                            document.getElementById('email_employee').style.border = '1px solid red'
                                        } else {
                                            document.getElementById('email_employee').style.border = '1px solid #ccc'
                                        }
                                        if (!e.target.value.includes('@')) {
                                            document.getElementById('email_employee').focus()
                                            document.getElementById('email_employee').style.border = '1px solid red'
                                        }
                                        else {
                                            document.getElementById('email_employee').style.border = '1px solid #ccc'
                                        }
                                    }}

                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size='small'
                                    id='contact_employee'
                                    label="Contato"
                                    type="text"
                                    fullWidth
                                    value={contact_employee ? contact_employee : props?.data.contact_employee}
                                    onChange={(e) => {
                                        e.target.value = phone_format(e.target.value)
                                        setContact_employee(e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size='small'
                                    id='cep_employee'
                                    label="CEP"
                                    type="text"
                                    fullWidth
                                    value={cep_employee ? cep_employee : props?.data.cep_employee}
                                    onChange={(e) => {
                                        e.target.value = cep_format(e.target.value)
                                        setCep_employee(e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size='small'
                                    id='street_employee'
                                    label="Logradouro"
                                    type="text"
                                    fullWidth
                                    value={street_employee ? street_employee : props?.data.street_employee}
                                    onChange={(e) => setStreet_employee(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size='small'
                                    id='number_employee'
                                    label="Número"
                                    type="text"
                                    fullWidth
                                    value={number_employee ? number_employee : props?.data.number_employee}
                                    onChange={(e) => {
                                        e.target.value = e.target.value.replace(/\D/g, "")
                                        setNumber_employee(e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size='small'
                                    id='neighborhood_employee'
                                    label="Bairro"
                                    type="text"
                                    fullWidth
                                    value={neighborhood_employee ? neighborhood_employee : props?.data.neighborhood_employee}
                                    onChange={(e) => setNeighborhood_employee(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size='small'
                                    id='city_employee'
                                    label="Cidade"
                                    type="text"
                                    fullWidth
                                    value={city_employee ? city_employee : props?.data.city_employee}
                                    onChange={(e) => setCity_employee(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size='small'
                                    id='state_employee'
                                    label="Estado"
                                    type="text"
                                    fullWidth
                                    value={state_employee ? state_employee : props?.data.state_employee}
                                    onChange={(e) => setState_employee(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size='small'
                                    id='complement_employee'
                                    label="Complemento"
                                    type="text"
                                    fullWidth

                                    value={complement_employee ? complement_employee : props?.data.complement_employee}
                                    onChange={(e) => setComplement_employee(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    select
                                    size='small'
                                    id='departments'
                                    label="Departamento"
                                    fullWidth
                                    value={departments.length > 0 ? departments : props?.data.id_departments}
                                    onClick={listDepartments}
                                    onChange={(e) => {
                                        setDepartments(e.target.value)
                                        fetch(`http://15.228.8.54:5000/api/roles/search?search=${e.target.value}&page=1&limit=100`, {
                                            method: 'GET',
                                            headers: {
                                                'Content-Type': 'application/json'
                                            }
                                        }).then((response) => response.json())
                                            .then((data) => {
                                                
                                                setRoles(data[0])
                                            }).catch((error) => {
                                                console.log(error)
                                            })
                                    }}

                                >
                                    {arrayDepartments?.map((item) => (
                                        <MenuItem key={item.id_departments} value={item.id_departments}>
                                            {item.name_departments}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    select
                                    size='small'
                                    id='roles'
                                    label="Função"
                                    fullWidth
                                    value={roles_id ? roles_id : props?.data.id_roles}
                                    onChange={(e) => {
                                        setRoles_id(e.target.value)
                                    }}
                                >
                                    {roles?.map((item) => (
                                        <MenuItem key={item.id_roles} value={item.id_roles}>
                                            {item.name_role}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                    }





                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    {props?.title === 'edit' && <Button onClick={handleEditEmployee} variant='contained' color="primary"
                        autoFocus>
                        Salvar
                    </Button>}
                    {props?.title === 'new' && <Button onClick={handleNewEmployee} variant='contained' color="primary"
                        autoFocus>
                        Cadastrar
                    </Button>}
                </DialogActions>
            </Dialog>

        </>

    )
}

export default New_Edit_Employee
