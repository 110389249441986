import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, MenuItem, Radio, RadioGroup } from '@mui/material';
import { Label, PlaylistAddCheckCircleRounded } from '@mui/icons-material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { toast, ToastContainer } from 'react-toastify';
import validator from 'validator';

function New_Edit_Customer(props) {






    const civil_states = [
        { label: 'Solteiro(a)', value: 'Solteiro(a)' },
        { label: 'União Estável', value: 'União Estável' },
        { label: 'Casado(a)', value: 'Casado(a)' },
        { label: 'Divorciado(a)', value: 'Divorciado(a)' },
        { label: 'Viúvo(a)', value: 'Viúvo(a)' },
    ];


    const [name, setName] = useState('')
    const [cpf, setCpf] = useState(props?.data.cpf)
    const [civil_state, setCivil_state] = useState('')
    const [nationality, setNationality] = useState('')
    const [profession, setProfession] = useState('')
    const [fantasy_name, setFantasy_name] = useState('')
    const [corporate_reason, setCorporate_reason] = useState('')
    const [cnpj, setCnpj] = useState('')
    const [email, setEmail] = useState('')
    const [contact, setContact] = useState('')
    const [password, setPassword] = useState('')
    const [cep, setCep] = useState('')
    const [street, setStreet] = useState('')
    const [number, setNumber] = useState('')
    const [neighborhood, setNeighborhood] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [complement, setComplement] = useState('')
    const [rg, setRg] = useState('')
    const [name_representative, setName_representative] = useState('')
    const [rg_representative, setRg_representative] = useState('')
    const [cpf_representative, setCpf_representative] = useState('')
    const [nationality_representative, setNationality_representative] = useState('')
    const [profession_representative, setProfession_representative] = useState('')

    const handleClearFields = () => {
        setName('')
        setCpf('')
        setCivil_state('')
        setNationality('')
        setProfession('')
        setFantasy_name('')
        setCorporate_reason('')
        setCnpj('')
        setEmail('')
        setContact('')
        setCep('')
        setStreet('')
        setNumber('')
        setNeighborhood('')
        setCity('')
        setState('')
        setComplement('')
        setRg('')
        setName_representative('')
        setRg_representative('')
        setCpf_representative('')
        setNationality_representative('')
        setProfession_representative('')
    }
    const handleClose = () => {
        handleClearFields()
        setTipoCliente('pf')
        props.setType('')
        props.setData('')
        props.handleClose()
    }
    console.log(props?.type)
    
    
    const handleNewCustomer = async () => {
        
        if (tipoCliente === 'pf') {
            
            if(!name){
                toast.error('Campo nome vazio')
                document.getElementById('nome').focus()
                return
            }
            if(!rg){
                toast.error('Campo RG vazio')
                document.getElementById('rg').focus()
                return
            }
            if(!cpf){
                toast.error('Campo CPF vazio')
                document.getElementById('cpf').focus()
                return
            }
            if(!civil_state){
                toast.error('Campo estado civil vazio')
                document.getElementById('estadoCivil').focus()
                return
            }
            if(!nationality){
                toast.error('Campo nacionalidade vazio')
                document.getElementById('nacionalidade').focus()
                return
            }
            if(!profession){
                toast.error('Campo profissão vazio')
                document.getElementById('profissao').focus()
                return
            }
            if(!email){
                toast.error('Campo email vazio')
                document.getElementById('email_pf').focus()
                return
            }
            if(!validator.isEmail(email)){
                toast.error('Email inválido')
                document.getElementById('email_pf').focus()
                return
            }
            if(!contact){
                toast.error('Campo contato vazio')
                document.getElementById('contato_pf').focus()
                return
            }
            if(!cep){
                toast.error('Campo CEP vazio')
                document.getElementById('cep_pf').focus()
                return
            }
            if(!street){
                toast.error('Campo logradouro vazio')
                document.getElementById('logradouro_pf').focus()
                return
            }
            if(!number){
                toast.error('Campo número vazio')
                document.getElementById('numero_pf').focus()
                return
            }
            if(!neighborhood){
                toast.error('Campo bairro vazio')
                document.getElementById('bairro_pf').focus()
                return
            }
            if(!city){
                toast.error('Campo cidade vazio')
                document.getElementById('cidade_pf').focus()
                return
            }
            if(!state){
                toast.error('Campo estado vazio')
                document.getElementById('estado_pf').focus()
                return
            }
            if(!complement){
                toast.error('Campo complemento vazio')
                document.getElementById('complemento_pf').focus()
                return
            }

            
            //const response = await fetch('https://api-aplication-fortesol.vercel.app/api/photovoltaic_modules', {
            const response = await fetch('http://15.228.8.54:5000/api/customers/create', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ name, rg, cpf, civil_state, nationality, profession, email, contact, password, cep, street, number, neighborhood, city, state, complement })
            }).then((response) => response.json())
                .then((data) => {
             
                    if (data.message === 'Customer created successfully' ) {
                        toast.success('Cliente cadastrado com sucesso')
                        document.getElementById('bt-search').click()
                        props.handleClose()
                    }
                    else {
                        toast.error(`${data.message}`)
                    }
                }).catch((error) => {
                    console.log(error)
                })
            return response
        }

        if (tipoCliente === 'pj') {
          if(!fantasy_name){
                toast.error('Campo nome fantasia vazio')
                document.getElementById('nome_fantasia').focus()
                return
            }
            if(!corporate_reason){
                toast.error('Campo razão social vazio')
                document.getElementById('razao_social').focus()
                return
            }
            if(!cnpj){
                toast.error('Campo CNPJ vazio')
                document.getElementById('cnpj').focus()
                return
            }
            if(!email){
                toast.error('Campo email vazio')
                document.getElementById('email_pj').focus()
                return
            }
            if(!validator.isEmail(email)){
                toast.error('Email inválido')
                document.getElementById('email_pj').focus()
                return
            }
            if(!contact){
                toast.error('Campo contato vazio')
                document.getElementById('contato_pj').focus()
                return
            }
            if(!cep){
                toast.error('Campo CEP vazio')
                document.getElementById('cep_pj').focus()
                return
            }
            if(!street){
                toast.error('Campo logradouro vazio')
                document.getElementById('logradouro_pj').focus()
                return
            }
            if(!number){
                toast.error('Campo número vazio')
                document.getElementById('numero_pj').focus()
                return
            }
            if(!neighborhood){
                toast.error('Campo bairro vazio')
                document.getElementById('bairro_pj').focus()
                return
            }
            if(!city){
                toast.error('Campo cidade vazio')
                document.getElementById('cidade_pj').focus()
                return
            }
            if(!state){
                toast.error('Campo estado vazio')
                document.getElementById('estado_pj').focus()
                return
            }
         /*   if(!complement){
                toast.error('Campo complemento vazio')
                document.getElementById('complemento_pj').focus()
                return
            }
            if(!name_representative){
                toast.error('Campo nome representante vazio')
                document.getElementById('nome_representante').focus()
                return  
            }
            if(!rg_representative){
                toast.error('Campo RG representante vazio')
                document.getElementById('rg_representante').focus()
                return
            }
            if(!cpf_representative){
                toast.error('Campo CPF representante vazio')
                document.getElementById('cpf_representante').focus()
                return
            }
            if(!nationality_representative){
                toast.error('Campo nacionalidade representante vazio')
                document.getElementById('nacionalidade_representante').focus()
                return
            }
            if(!profession_representative){
                toast.error('Campo profissão representante vazio')
                document.getElementById('profissao_representante').focus()
                return
            }*/
            //const response = await fetch('https://api-aplication-fortesol.vercel.app/api/photovoltaic_modules', {
            const response = await fetch('http://15.228.8.54:5000/api/customers/create', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ fantasy_name, corporate_reason, cnpj, email, contact, cep, street, number, neighborhood, city, state, complement, name_representative, rg_representative, cpf_representative, nationality_representative, profession_representative })
            }).then((response) => response.json())
                .then((data) => {
             
                    if (data.message === 'Customer created successfully') {
                        toast.success('Cliente cadastrado com sucesso')
                        document.getElementById('bt-search').click()
                        handleClose()
                    }
                    else {
                        toast.error(`${data.message}`)
                    }
                }).catch((error) => {
                    console.log(error)
                })
            return response
        }

    }

    const handleEditCustomer = async () => {

        
        
        if (tipoCliente === 'pf') {
            const name_edited = document.getElementById('nome').value
            const rg_edited = document.getElementById('rg').value
            const cpf_edited = document.getElementById('cpf').value
            const civil_state_edited = document.getElementById('estadoCivil').innerHTML
            const nationality_edited = document.getElementById('nacionalidade').value
            const profession_edited = document.getElementById('profissao').value
            const email_edited = document.getElementById('email_pf').value
            const contact_edited = document.getElementById('contato_pf').value
            const cep_edited = document.getElementById('cep_pf').value
            const street_edited = document.getElementById('logradouro_pf').value
            const number_edited = document.getElementById('numero_pf').value
            const neighborhood_edited = document.getElementById('bairro_pf').value
            const city_edited = document.getElementById('cidade_pf').value
            const state_edited = document.getElementById('estado_pf').value
            const complement_edited = document.getElementById('complemento_pf').value


          
            
            if(!name_edited){
                toast.error('Campo nome vazio')
                document.getElementById('nome').focus()
                return
            }
            if(!rg_edited){
                toast.error('Campo RG vazio')
                document.getElementById('rg').focus()
                return
            }
            if(!cpf_edited){
                toast.error('Campo CPF vazio')
                document.getElementById('cpf').focus()
                return
            }
            if(!civil_state_edited){
                toast.error('Campo estado civil vazio')
                document.getElementById('estadoCivil').focus()
                return
            }
            if(!nationality_edited){
                toast.error('Campo nacionalidade vazio')
                document.getElementById('nacionalidade').focus()
                return
            }
            if(!profession_edited){
                toast.error('Campo profissão vazio')
                document.getElementById('profissao').focus()
                return
            }
            if(!email_edited){
                toast.error('Campo email vazio')
                document.getElementById('email_pf').focus()
                return
            }
            if(!contact_edited){
                toast.error('Campo contato vazio')
                document.getElementById('contato_pf').focus()
                return
            }
            if(!cep_edited){
                toast.error('Campo CEP vazio')
                document.getElementById('cep_pf').focus()
                return
            }
            if(!street_edited){
                toast.error('Campo logradouro vazio')
                document.getElementById('logradouro_pf').focus()
                return
            }
            if(!number_edited){
                toast.error('Campo número vazio')
                document.getElementById('numero_pf').focus()
                return
            }
            if(!neighborhood_edited){
                toast.error('Campo bairro vazio')
                document.getElementById('bairro_pf').focus()
                return
            }
            if(!city_edited){
                toast.error('Campo cidade vazio')
                document.getElementById('cidade_pf').focus()
                return
            }
            if(!state_edited){
                toast.error('Campo estado vazio')
                document.getElementById('estado_pf').focus()
                return
            }
            if(!complement_edited){
                toast.error('Campo complemento vazio')
                document.getElementById('complemento_pf').focus()
                return
            }
               //const response = await fetch(`https://api-aplication-fortesol.vercel.app/api/customers/${props?.data.id}`, {
        const response = await fetch(`http://15.228.8.54:5000/api/customers/update/${props?.data.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name: name_edited, rg: rg_edited, cpf: cpf_edited, civil_state: civil_state_edited,nationality: nationality_edited, profession: profession_edited, email: email_edited, contact: contact_edited, cep: cep_edited, street: street_edited, number: number_edited, neighborhood: neighborhood_edited, city: city_edited, state: state_edited, complement: complement_edited, fantasy_name, corporate_reason, cnpj, name_representative, rg_representative, cpf_representative,nationality_representative, profession_representative })
        }).then((response) => response.json())
            .then((data) => {
         
                if (data.message === 'Customer updated successfully') {
                    document.getElementById('bt-search').click()
                    handleClose()
                }
            }).catch((error) => {
                console.log(error)
            })
        return response
        }
        if (tipoCliente === 'pj') {

            const fantasy_name_edited = document.getElementById('nome_fantasia').value
            const corporate_reason_edited = document.getElementById('razao_social').value
            const cnpj_edited = document.getElementById('cnpj').value
            const email_edited = document.getElementById('email_pj').value
            const contact_edited = document.getElementById('contato_pj').value
            const cep_edited = document.getElementById('cep_pj').value
            const street_edited = document.getElementById('logradouro_pj').value
            const number_edited = document.getElementById('numero_pj').value
            const neighborhood_edited = document.getElementById('bairro_pj').value
            const city_edited = document.getElementById('cidade_pj').value
            const state_edited = document.getElementById('estado_pj').value
            const complement_edited = document.getElementById('complemento_pj').value
            const name_representative_edited = document.getElementById('nome_representante').value
            const rg_representative_edited = document.getElementById('rg_representante').value
            const cpf_representative_edited = document.getElementById('cpf_representante').value
            const nationality_representative_edited = document.getElementById('nacionalidade_representante').value
            const profession_representative_edited = document.getElementById('profissao_representante').value


   
            if(!fantasy_name_edited){
                  toast.error('Campo nome fantasia vazio')
                  document.getElementById('nome_fantasia').focus()
                  return
              }
              if(!corporate_reason_edited){
                  toast.error('Campo razão social vazio')
                  document.getElementById('razao_social').focus()
                  return
              }
              if(!cnpj_edited){
                  toast.error('Campo CNPJ vazio')
                  document.getElementById('cnpj').focus()
                  return
              }
              if(!email_edited){
                  toast.error('Campo email vazio')
                  document.getElementById('email_pj').focus()
                  return
              }
              if(!contact_edited){
                  toast.error('Campo contato vazio')
                  document.getElementById('contato_pj').focus()
                  return
              }
              if(!cep_edited){
                  toast.error('Campo CEP vazio')
                  document.getElementById('cep_pj').focus()
                  return
              }
              if(!street_edited){
                  toast.error('Campo logradouro vazio')
                  document.getElementById('logradouro_pj').focus()
                  return
              }
              if(!number_edited){
                  toast.error('Campo número vazio')
                  document.getElementById('numero_pj').focus()
                  return
              }
              if(!neighborhood_edited){
                  toast.error('Campo bairro vazio')
                  document.getElementById('bairro_pj').focus()
                  return
              }
              if(!city_edited){
                  toast.error('Campo cidade vazio')
                  document.getElementById('cidade_pj').focus()
                  return
              }
              if(!state_edited){
                  toast.error('Campo estado vazio')
                  document.getElementById('estado_pj').focus()
                  return
              }
              if(!complement_edited){
                  toast.error('Campo complemento vazio')
                  document.getElementById('complemento_pj').focus()
                  return
              }
              if(!name_representative_edited){
                  toast.error('Campo nome representante vazio')
                  document.getElementById('nome_representante').focus()
                  return  
              }
              if(!rg_representative_edited){
                  toast.error('Campo RG representante vazio')
                  document.getElementById('rg_representante').focus()
                  return
              }
              if(!cpf_representative_edited){
                  toast.error('Campo CPF representante vazio')
                  document.getElementById('cpf_representante').focus()
                  return
              }
              if(!nationality_representative_edited){
                  toast.error('Campo nacionalidade representante vazio')
                  document.getElementById('nacionalidade_representante').focus()
                  return
              }
              if(!profession_representative_edited){
                  toast.error('Campo profissão representante vazio')
                  document.getElementById('profissao_representante').focus()
                  return
              }
              const response = await fetch(`http://15.228.8.54:5000/api/customers/update/${props?.data.id}`, {
                  method: 'PUT',
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({ name, rg, cpf, civil_state,nationality, profession, email: email_edited, contact: contact_edited, cep: cep_edited, street: street_edited, number: number_edited, neighborhood: neighborhood_edited, city: city_edited, state: state_edited, complement: complement_edited, fantasy_name: fantasy_name_edited, corporate_reason: corporate_reason_edited, cnpj: cnpj_edited, name_representative: name_representative_edited, rg_representative: rg_representative_edited, cpf_representative: cpf_representative_edited, nationality_representative:nationality_representative_edited, profession_representative: profession_representative_edited })
              }).then((response) => response.json())
                  .then((data) => {
               
                      if (data.message === 'Customer updated successfully') {
                          document.getElementById('bt-search').click()
                          handleClose()
                      }
                  }).catch((error) => {
                      console.log(error)
                  })
              return response
            }


     
    }

    const phone_format = (phone) => {
        phone = phone.replace(/\D/g, "")
        phone = phone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3")

        if (phone.length > 15) {
            phone = phone.substring(0, 14)
        }

        return phone
    }
    const cpf_format = (cpf) => {

        cpf = cpf.replace(/\D/g, "")
        cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
        cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
        cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2")

        if (cpf.length > 14) {
            cpf = cpf.substring(0, 13)
        }

        return cpf
    }
    const cep_format = (cep) => {
        cep = cep.replace(/\D/g, "")
        cep = cep.replace(/(\d{5})(\d{3})/, "$1-$2")

        if (cep.length > 9) {
            cep = cep.substring(0, 8)
        }

        return cep
    }

    const cnpj_format = (cnpj) => {
        cnpj = cnpj.replace(/\D/g, "")
        cnpj = cnpj.replace(/(\d{2})(\d)/, "$1.$2")
        cnpj = cnpj.replace(/(\d{3})(\d)/, "$1.$2")
        cnpj = cnpj.replace(/(\d{3})(\d)/, "$1/$2")
        cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2")

        if (cnpj.length > 18) {
            cnpj = cnpj.substring(0, 17)
        }

        return cnpj
    }
    const handleCNPJ = async (cnpj) => {
        if (!cnpj) {
            toast.error('Campo CNPJ vazio')
            return
        }
        const cnpjCorrect = cnpj.replace(/[^\d]+/g, '');
        if (cnpjCorrect.length !== 14) {
            toast.error('CNPJ inválido')
            return
        }
        await fetch(`https://publica.cnpj.ws/cnpj/${cnpjCorrect}`)
            .then(response => response.json())
            .then(data => {
                if (data.status === 'ERROR') {
                    toast.error('CNPJ não encontrado')
                    return
                }

                const cnpjformat = data.estabelecimento.cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
                const formattedCep = data.estabelecimento.cep.replace(/(\d{5})(\d{3})/, "$1-$2")


         
                setFantasy_name(data.estabelecimento.nome_fantasia ? data.estabelecimento.nome_fantasia : '---')
                setCorporate_reason(data.razao_social)
                setCnpj(cnpjformat)
                setEmail(data.estabelecimento.email)
                setContact(`${data.estabelecimento.ddd1} ${data.estabelecimento.telefone1}`)
                setCep(formattedCep)
                setStreet(`${data.estabelecimento.tipo_logradouro} ${data.estabelecimento.logradouro}`)
                setNumber(data.estabelecimento.numero ? data.estabelecimento.numero : 'S/N')
                setNeighborhood(data.estabelecimento.bairro)
                setCity(data.estabelecimento.cidade.nome)
                setState(data.estabelecimento.estado.sigla)
                setComplement(data.estabelecimento.complemento ? data.estabelecimento.complemento : '---')




                const _cnpj = [
                    data.razao_social,
                    cnpjformat,
                    data.estabelecimento.email,
                    data.estabelecimento.nome_fantasia,
                    data.estabelecimento.logradouro,
                    data.estabelecimento.numero ? data.estabelecimento.numero : 'S/N',
                    data.estabelecimento.complemento ? data.estabelecimento.complemento : '---',
                    data.estabelecimento.bairro,
                    data.estabelecimento.cidade.nome,
                    data.estabelecimento.estado.sigla,
                    formattedCep

                ]


            }).catch(err => {
                console.log(err)

            })
    }

  



 

    const [tipoCliente, setTipoCliente] = useState('pf')

    const handleChange = (event) => {
        setTipoCliente(event.target.value);
        handleClearFields()
    };
useEffect(() => {
    if(props?.title === 'edit'){
      if(props.data.cpf){
        setTipoCliente('pf')
      }else{
        setTipoCliente('pj')
      }
    }
}
, [props])



    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.handleClose}

                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='1200px'
                sx={{ ml: 5 }}
            >
                <DialogTitle id="alert-dialog-title">{props.title === 'new' ? 'Cadastro de Clientes' : 'Editar Cliente'}</DialogTitle>
                <DialogContent>
                    <ToastContainer
                        position="bottom-right"
                        autoClose={4000}
                        newestOnTop={false}
                        closeOnClick
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        height={50}

                    />
                    <Alert severity="info">Preencha os campos abaixo para salvar as informações.</Alert>
                   {props.title === 'new' && <>
                   
                   <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="pf"
                        name="radio-buttons-group"
                        onChange={handleChange}
                        sx={{ mt: 2, flexDirection: 'row', justifyContent: 'center' }}
                    >
                        <FormControlLabel value="pf" control={<Radio />} label="Pessoa Física" />
                        <FormControlLabel value="pj" control={<Radio />} label="Pessoa Jurídica" />
                    </RadioGroup>





                    {tipoCliente === 'pf'  && (
                        <Grid sx={{ mt: 1 }} container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    id='nome'
                                    size="small"
                                    label="Nome Completo"
                                    variant="outlined"
                                    name="nome"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    id='rg'
                                    size="small"
                                    label="RG"
                                    variant="outlined"
                                    name="RG"
                                    value={rg}
                                    onChange={(e) => {
                                        e.target.value = e.target.value.replace(/\D/g, "")
                                        setRg(e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id='cpf'
                                    label="CPF"
                                    variant="outlined"
                                    name="cpf"
                                    value={cpf}
                                    onChange={(e) => {
                                        e.target.value = e.target.value.replace(/\D/g, "")
                                        setCpf(cpf_format(e.target.value))
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    select
                                    fullWidth
                                    size="small"
                                    id='estadoCivil'
                                    label="Estado Civil"
                                    variant="outlined"
                                    name="estadoCivil"
                                    value={civil_state}
                                    onChange={(e) => setCivil_state(e.target.value)}
                                >
                                    {civil_states.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id='nacionalidade'
                                    label="Nacionalidade"
                                    variant="outlined"
                                    name="nacionalidade"
                                    value={nationality}
                                    onChange={(e) => setNationality(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id='profissao'
                                    label="Profissão"
                                    variant="outlined"
                                    name="profissao"
                                    value={profession}
                                    onChange={(e) => setProfession(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id='email_pf'
                                    label="Email"
                                    variant="outlined"
                                    name="email"
                                    value={email}
                                    onChange={(e) => {
                                        e.target.value = e.target.value.toLowerCase()
                                        if (e.target.value.includes(' ')) {
                                            e.target.value = e.target.value.replace(/\s/g, '')
                                        }

                                        setEmail(e.target.value)
                                        if (!e.target.value.includes('@')) {
                                            document.getElementById('email_pf').style.border = '2px solid red'
                                        } else {
                                            document.getElementById('email_pf').style.border = '1px solid #ced4da'
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id='contato_pf'
                                    label="Contato"
                                    variant="outlined"
                                    name="contato"
                                    value={contact}
                                    onChange={(e) => {
                                        e.target.value = phone_format(e.target.value)
                                        setContact(e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id='cep_pf'
                                    label="CEP"
                                    variant="outlined"
                                    name="cep"
                                    value={cep}
                                    onChange={(e) => {
                                        e.target.value = cep_format(e.target.value)
                                        setCep(e.target.value)
                                        if (e.target.value.length === 9) {
                                            fetch(`https://viacep.com.br/ws/${e.target.value}/json/`)
                                                .then(response => response.json())
                                                .then(data => {
                                                    if (data.erro) {
                                                        toast.error('CEP não encontrado')
                                                        return
                                                    }
                                                    setStreet(data.logradouro)
                                                    setNeighborhood(data.bairro)
                                                    setCity(data.localidade)
                                                    setState(data.uf)
                                                })
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id='logradouro_pf'
                                    label="Logradouro"
                                    variant="outlined"
                                    name="logradouro"
                                    value={street}
                                    onChange={(e) => setStreet(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id='numero_pf'
                                    label="Número"
                                    variant="outlined"
                                    name="numero"
                                    value={number}
                                    onChange={(e) => setNumber(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id='bairro_pf'
                                    label="Bairro"
                                    variant="outlined"
                                    name="bairro"
                                    value={neighborhood}
                                    onChange={(e) => setNeighborhood(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id='cidade_pf'
                                    label="Cidade"
                                    variant="outlined"
                                    name="cidade"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id='estado_pf'
                                    label="Estado"
                                    variant="outlined"
                                    name="estado"
                                    value={state}
                                    onChange={(e) => setState(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id='complemento_pf'
                                    label="Complemento"
                                    variant="outlined"
                                    name="complemento"
                                    value={complement}
                                    onChange={(e) => setComplement(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    )}

                    {tipoCliente === 'pj' &&  (
                        <>
                            <Grid sx={{ mt: 1 }} container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='cnpj'
                                        label="CNPJ"
                                        variant="outlined"
                                        name="cnpj"
                                        value={cnpj}
                                        onChange={(e) => {
                                            e.target.value = cnpj_format(e.target.value)
                                            setCnpj(e.target.value)
                                            if (e.target.value.length === 18) {
                                                handleCNPJ(e.target.value)
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='razao_social'
                                        label="Razão Social"
                                        variant="outlined"
                                        name="razaoSocial"
                                        value={corporate_reason}
                                        onChange={(e) => setCorporate_reason(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='nome_fantasia'
                                        label="Nome Fantasia"
                                        variant="outlined"
                                        name="nomeFantasia"
                                        value={fantasy_name}
                                        onChange={(e) => setFantasy_name(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='email_pj'
                                        label="Email"
                                        variant="outlined"
                                        name="email"
                                        value={email}
                                        onChange={(e) => {
                                            e.target.value = e.target.value.toLowerCase()
                                            if (e.target.value.includes(' ')) {
                                                e.target.value = e.target.value.replace(/\s/g, '')
                                            }

                                            setEmail(e.target.value)
                                            if (!e.target.value.includes('@')) {
                                                document.getElementById('email_pj').style.border = '2px solid red'
                                            } else {
                                                document.getElementById('email_pj').style.border = '1px solid #ced4da'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='contato_pj'
                                        label="Contato"
                                        variant="outlined"
                                        name="contato"
                                        value={contact}
                                        onChange={(e) => {
                                            e.target.value = phone_format(e.target.value)
                                            setContact(e.target.value)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='cep_pj'
                                        label="CEP"
                                        variant="outlined"
                                        name="cep"
                                        value={cep}
                                        onChange={(e) => {
                                            e.target.value = cep_format(e.target.value)
                                            setCep(e.target.value)
                                            if (e.target.value.length === 9) {
                                                fetch(`https://viacep.com.br/ws/${e.target.value}/json/`)
                                                    .then(response => response.json())
                                                    .then(data => {
                                                        if (data.erro) {
                                                            toast.error('CEP não encontrado')
                                                            return
                                                        }
                                                        setStreet(data.logradouro)
                                                        setNeighborhood(data.bairro)
                                                        setCity(data.localidade)
                                                        setState(data.uf)
                                                    })
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='logradouro_pj'
                                        label="Logradouro"
                                        variant="outlined"
                                        name="logradouro"
                                        value={street}
                                        onChange={(e) => setStreet(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='numero_pj'
                                        label="Número"
                                        variant="outlined"
                                        name="numero"
                                        value={number}
                                        onChange={(e) => {
                                            e.target.value = e.target.value.replace(/\D/g, "")
                                            setNumber(e.target.value)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='bairro_pj'
                                        label="Bairro"
                                        variant="outlined"
                                        name="bairro"
                                        value={neighborhood}
                                        onChange={(e) => setNeighborhood(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='cidade_pj'
                                        label="Cidade"
                                        variant="outlined"
                                        name="cidade"
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='estado_pj'
                                        label="Estado"
                                        variant="outlined"
                                        name="estado"
                                        value={state}
                                        onChange={(e) => setState(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='complemento_pj'
                                        label="Complemento"
                                        variant="outlined"
                                        name="complemento"
                                        value={complement}
                                        onChange={(e) => setComplement(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <br />
                            <Alert severity="info">Representante Legal</Alert>
                            <Grid sx={{ mt: 1 }} container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='nome_representante'
                                        label="Nome"
                                        variant="outlined"
                                        name="nomeRepresentante"
                                        value={name_representative}
                                        onChange={(e) => setName_representative(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='rg_representante'
                                        label="RG"
                                        variant="outlined"
                                        name="RG"
                                        value={rg_representative}
                                        onChange={(e) => {
                                            e.target.value = e.target.value.replace(/\D/g, "")
                                            setRg_representative(e.target.value)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='cpf_representante'
                                        label="CPF"
                                        variant="outlined"
                                        name="cpf"
                                        value={cpf_representative}
                                        onChange={(e) => {
                                            e.target.value = e.target.value.replace(/\D/g, "")
                                            setCpf_representative(cpf_format(e.target.value))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='nacionalidade_representante'
                                        label="Nacionalidade"
                                        variant="outlined"
                                        name="nacionalidade"
                                        value={nationality_representative}
                                        onChange={(e) => setNationality_representative(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='profissao_representante'
                                        label="Profissão"
                                        variant="outlined"
                                        name="profissao"
                                        value={profession_representative}
                                        onChange={(e) => setProfession_representative(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    )}
                    </>}
                    {props.title === 'edit' && <>
                    {tipoCliente === 'pf'  && (
                        <Grid sx={{ mt: 1 }} container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    id='nome'
                                    size="small"
                                    label="Nome Completo"
                                    variant="outlined"
                                    name="nome"
                                    value={name ? name : props?.data.name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    id='rg'
                                    size="small"
                                    label="RG"
                                    variant="outlined"
                                    name="RG"
                                    value={rg ? rg : props?.data.rg}
                                    onChange={(e) => {
                                        e.target.value = e.target.value.replace(/\D/g, "")
                                        setRg(e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id='cpf'
                                    label="CPF"
                                    variant="outlined"
                                    name="cpf"
                                    value={cpf ? cpf : props?.data.cpf}
                                    onChange={(e) => {
                                        e.target.value = e.target.value.replace(/\D/g, "")
                                        setCpf(cpf_format(e.target.value))
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    select
                                    fullWidth
                                    size="small"
                                    id='estadoCivil'
                                    label="Estado Civil"
                                    variant="outlined"
                                    name="estadoCivil"
                                    value={civil_state ? civil_state : props?.data.civil_state}
                                    onChange={(e) => setCivil_state(e.target.value)}
                                >
                                    {civil_states.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id='nacionalidade'
                                    label="Nacionalidade"
                                    variant="outlined"
                                    name="nacionalidade"
                                    value={nationality ? nationality : props?.data.nationality}
                                    onChange={(e) => setNationality(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id='profissao'
                                    label="Profissão"
                                    variant="outlined"
                                    name="profissao"
                                    value={profession ? profession : props?.data.profession}
                                    onChange={(e) => setProfession(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id='email_pf'
                                    label="Email"
                                    variant="outlined"
                                    name="email"
                                    value={email ? email : props?.data.email}
                                    onChange={(e) => {
                                        e.target.value = e.target.value.toLowerCase()
                                        if (e.target.value.includes(' ')) {
                                            e.target.value = e.target.value.replace(/\s/g, '')
                                        }

                                        setEmail(e.target.value)
                                        if (!e.target.value.includes('@')) {
                                            document.getElementById('email_pf').style.border = '2px solid red'
                                        } else {
                                            document.getElementById('email_pf').style.border = '1px solid #ced4da'
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id='contato_pf'
                                    label="Contato"
                                    variant="outlined"
                                    name="contato"
                                    value={contact ? contact : props?.data.contact}
                                    onChange={(e) => {
                                        e.target.value = phone_format(e.target.value)
                                        setContact(e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id='cep_pf'
                                    label="CEP"
                                    variant="outlined"
                                    name="cep"
                                    value={cep ? cep : props?.data.cep}
                                    onChange={(e) => {
                                        e.target.value = cep_format(e.target.value)
                                        setCep(e.target.value)
                                        if (e.target.value.length === 9) {
                                            fetch(`https://viacep.com.br/ws/${e.target.value}/json/`)
                                                .then(response => response.json())
                                                .then(data => {
                                                    if (data.erro) {
                                                        toast.error('CEP não encontrado')
                                                        return
                                                    }
                                                    setStreet(data.logradouro)
                                                    setNeighborhood(data.bairro)
                                                    setCity(data.localidade)
                                                    setState(data.uf)
                                                })
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id='logradouro_pf'
                                    label="Logradouro"
                                    variant="outlined"
                                    name="logradouro"
                                    value={street ? street : props?.data.street}
                                    onChange={(e) => setStreet(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id='numero_pf'
                                    label="Número"
                                    variant="outlined"
                                    name="numero"
                                    value={number ? number : props?.data.number}
                                    onChange={(e) => setNumber(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id='bairro_pf'
                                    label="Bairro"
                                    variant="outlined"
                                    name="bairro"
                                    value={neighborhood ? neighborhood : props?.data.neighborhood}
                                    onChange={(e) => setNeighborhood(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id='cidade_pf'
                                    label="Cidade"
                                    variant="outlined"
                                    name="cidade"
                                    value={city ? city : props?.data.city}
                                    onChange={(e) => setCity(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id='estado_pf'
                                    label="Estado"
                                    variant="outlined"
                                    name="estado"
                                    value={state ? state : props?.data.state}
                                    onChange={(e) => setState(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id='complemento_pf'
                                    label="Complemento"
                                    variant="outlined"
                                    name="complemento"
                                    value={complement ? complement : props?.data.complement}
                                    onChange={(e) => setComplement(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    )}

                    {tipoCliente === 'pj' &&  (
                        <>
                            <Grid sx={{ mt: 1 }} container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='cnpj'
                                        label="CNPJ"
                                        variant="outlined"
                                        name="cnpj"
                                        value={cnpj ? cnpj : props?.data.cnpj}
                                        onChange={(e) => {
                                            e.target.value = cnpj_format(e.target.value)
                                            setCnpj(e.target.value)
                                            if (e.target.value.length === 18) {
                                                handleCNPJ(e.target.value)
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='razao_social'
                                        label="Razão Social"
                                        variant="outlined"
                                        name="razaoSocial"
                                        value={corporate_reason ? corporate_reason : props?.data.corporate_reason}
                                        onChange={(e) => setCorporate_reason(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='nome_fantasia'
                                        label="Nome Fantasia"
                                        variant="outlined"
                                        name="nomeFantasia"
                                        value={fantasy_name ? fantasy_name : props?.data.fantasy_name}
                                        onChange={(e) => setFantasy_name(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='email_pj'
                                        label="Email"
                                        variant="outlined"
                                        name="email"
                                        value={email ? email : props?.data.email}
                                        onChange={(e) => {
                                            e.target.value = e.target.value.toLowerCase()
                                            if (e.target.value.includes(' ')) {
                                                e.target.value = e.target.value.replace(/\s/g, '')
                                            }

                                            setEmail(e.target.value)
                                            if (!e.target.value.includes('@')) {
                                                document.getElementById('email_pj').style.border = '2px solid red'
                                            } else {
                                                document.getElementById('email_pj').style.border = '1px solid #ced4da'
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='contato_pj'
                                        label="Contato"
                                        variant="outlined"
                                        name="contato"
                                        value={contact ? contact : props?.data.contact}
                                        onChange={(e) => {
                                            e.target.value = phone_format(e.target.value)
                                            setContact(e.target.value)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='cep_pj'
                                        label="CEP"
                                        variant="outlined"
                                        name="cep"
                                        value={cep ? cep : props?.data.cep}
                                        onChange={(e) => {
                                            e.target.value = cep_format(e.target.value)
                                            setCep(e.target.value)
                                            if (e.target.value.length === 9) {
                                                fetch(`https://viacep.com.br/ws/${e.target.value}/json/`)
                                                    .then(response => response.json())
                                                    .then(data => {
                                                        if (data.erro) {
                                                            toast.error('CEP não encontrado')
                                                            return
                                                        }
                                                        setStreet(data.logradouro)
                                                        setNeighborhood(data.bairro)
                                                        setCity(data.localidade)
                                                        setState(data.uf)
                                                    })
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='logradouro_pj'
                                        label="Logradouro"
                                        variant="outlined"
                                        name="logradouro"
                                        value={street ? street : props?.data.street}
                                        onChange={(e) => setStreet(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='numero_pj'
                                        label="Número"
                                        variant="outlined"
                                        name="numero"
                                        value={number ? number : props?.data.number}
                                        onChange={(e) => {
                                            e.target.value = e.target.value.replace(/\D/g, "")
                                            setNumber(e.target.value)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='bairro_pj'
                                        label="Bairro"
                                        variant="outlined"
                                        name="bairro"
                                        value={neighborhood ? neighborhood : props?.data.neighborhood}
                                        onChange={(e) => setNeighborhood(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='cidade_pj'
                                        label="Cidade"
                                        variant="outlined"
                                        name="cidade"
                                        value={city ? city : props?.data.city}
                                        onChange={(e) => setCity(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='estado_pj'
                                        label="Estado"
                                        variant="outlined"
                                        name="estado"
                                        value={state ? state : props?.data.state}
                                        onChange={(e) => setState(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='complemento_pj'
                                        label="Complemento"
                                        variant="outlined"
                                        name="complemento"
                                        value={complement ? complement : props?.data.complement}
                                        onChange={(e) => setComplement(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <br />
                            <Alert severity="info">Representante Legal</Alert>
                            <Grid sx={{ mt: 1 }} container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='nome_representante'
                                        label="Nome"
                                        variant="outlined"
                                        name="nomeRepresentante"
                                        value={name_representative ? name_representative : props?.data.name_representative}
                                        onChange={(e) => setName_representative(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='rg_representante'
                                        label="RG"
                                        variant="outlined"
                                        name="RG"
                                        value={rg_representative ? rg_representative : props?.data.rg_representative}
                                        onChange={(e) => {
                                            e.target.value = e.target.value.replace(/\D/g, "")
                                            setRg_representative(e.target.value)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='cpf_representante'
                                        label="CPF"
                                        variant="outlined"
                                        name="cpf"
                                        value={cpf_representative ? cpf_representative : props?.data.cpf_representative}
                                        onChange={(e) => {
                                            e.target.value = e.target.value.replace(/\D/g, "")
                                            setCpf_representative(cpf_format(e.target.value))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='nacionalidade_representante'
                                        label="Nacionalidade"
                                        variant="outlined"
                                        name="nacionalidade"
                                        value={nationality_representative ? nationality_representative : props?.data.nationality_representative}
                                        onChange={(e) => setNationality_representative(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id='profissao_representante'
                                        label="Profissão"
                                        variant="outlined"
                                        name="profissao"
                                        value={profession_representative ? profession_representative : props?.data.profession_representative}
                                        onChange={(e) => setProfession_representative(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    )}
                    </>}



                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    {props?.title === 'edit' && <Button onClick={handleEditCustomer} variant='contained' color="primary"
                        autoFocus>
                        Salvar
                    </Button>}
                    {props?.title === 'new' && <Button onClick={handleNewCustomer} variant='contained' color="primary"
                        autoFocus>
                        Cadastrar
                    </Button>}
                </DialogActions>
            </Dialog>

        </>

    )
}

export default New_Edit_Customer
