import React from 'react'


import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator   
 from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
 import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';   
import Avatar from '@mui/material/Avatar';
import { Typography } from '@mui/material';


export default function Timeline_Demand() {
    const events = [
        { id: 1, date: '2023-11-01', description: 'Projeto Iniciado', type: 'project' },
        { id: 1, date: '2023-11-01', description: 'Projeto Iniciado', type: 'project' },
        { id: 1, date: '2023-11-01', description: 'Projeto Iniciado', type: 'project' },
        { id: 1, date: '2023-11-01', description: 'Projeto Iniciado', type: 'project' },
        // ... outros eventos
      ];
  return (
    <Timeline position="left">
  
    {events.map((event) => (
      <TimelineItem key={event.id}>
        <TimelineSeparator>
          <TimelineDot color="primary">
            {/* Ícone do evento */}
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Card sx={{ minWidth: 200 }}>
            <CardHeader
              title={event.date}
              subheader={event.type}
              avatar={
                <Avatar sx={{ bgcolor: 'grey.200' }}>
                  {/* Inicial do nome do contato ou ícone */}
                </Avatar>
              }
            />
            <CardContent>
              {event.description}
            </CardContent>
          </Card>
        </TimelineContent>
      </TimelineItem>
    ))}
  </Timeline>
  )
}
