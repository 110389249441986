

import React, { useState } from 'react';
import {
  TextField,
  Button,
  Typography,
  Container,
  Grid,
  Box,
  IconButton,
  Alert,
  ListItemText,
  List,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';   

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { toast } from 'react-toastify';
export default function Photovoltaic_Information(props) {
   

  
    const handleAddInverter = () => {
      props.setInverters([...props.inverters, { brand: '', model: '', qtd: '' }]);
    };
  
    const handleAddModule = () => {
      props.setModules([...props.modules, { brand: '', model: '', qtd: '' }]);
    };
  
    const handleChange = (event, index, type) => {
      const { name, value } = event.target;
      const newArray = [...(type === 'inverter' ? props.inverters : props.modules)];
      newArray[index][name] = value;
        if (type === 'inverter') {
            props.setInverters(newArray);
        } else {
            props.setModules(newArray);
        }
    };
    const handleSubmit = (event) => {
      event.preventDefault();
  
    };

    const handleRemoveInverter = (index) => {
        const newArray = [...props.inverters];
        newArray.splice(index, 1);
        props.setInverters(newArray);
        }
    const handleRemoveModule = (index) => {
        const newArray = [...props.modules];
        newArray.splice(index, 1);
        props.setModules(newArray);
        }



      
  return (
    <Grid >
        <Alert severity="info" sx={{ mt: 2, mb: 2 }}> Preencha as informações dos inversores e módulos ou envie uma imagem com as referidas informações.</Alert>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',mt: 2,mb:2}}>
      <Typography  sx={{ mt: 3, mb: 3 }}variant="h5">Inversores</Typography>
    </Box>
        
        <Grid container sx={{ mt: 2,mb:2 }}>

<Grid item xs={11} >
          {/* Seção dos Inversores */}
          {props.inverters.map((inverter, index) => (
        <Grid container key={index} spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={3.5} >
              <TextField
              id={`brand-inverter-${index}`}
              size='small'
                label="Marca"
                variant="outlined"
                fullWidth
                name="marca"
                value={inverter.marca}
                onChange={(event) => handleChange(event, index, 'inverter')}
              />
              </Grid>
                <Grid item xs={3.5} key={index}>
            <TextField
            size='small'  
            id={`model-inverter-${index}`}
                label="Modelo"
                variant="outlined"
                fullWidth
                name="modelo"
                value={inverter.modelo}
                onChange={(event) => handleChange(event, index, 'inverter')}
                />
                </Grid>
                <Grid item xs={3.5} key={index}>

            <TextField
            size='small'
            id={`quantity-inverter-${index}`}
                label="Quantidade"
                variant="outlined"
                fullWidth
                name="quantidade"
                value={inverter.quantidade}
                onChange={(event) => {
                  event.target.value = event.target.value.replace(/\D/g, '')
                  
                  handleChange(event, index, 'inverter')}}
                />


            </Grid>
            <Grid item xs={1} key={index}>
             {index !== 0 && <Button onClick={() => handleRemoveInverter(index)}>Remover</Button>}
            </Grid>
            </Grid>
          ))}
          </Grid>
               <Grid item xs={1} >
               <IconButton onClick={handleAddInverter} size='small' >
                <AddCircleOutlineIcon />
                </IconButton>
          </Grid>
          </Grid>

          {/* Seção dos Módulos */}

          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',mt: 2,mb:2}}>
      <Typography  sx={{ mt: 3, mb: 3 }}variant="h5">Módulos</Typography>
    </Box>
          <Grid container sx={{ mt: 2,mb:2 }}>

              <Grid item xs={11} >
          {props.modules.map((module, index) => (
    <Grid container key={index} spacing={2} sx={{ mt: 1 }}>

            <Grid item xs={3.5} >
              <TextField
              size='small'
              id={`brand-module-${index}`}
                label="Marca"
                variant="outlined"
                fullWidth
                name="marca"
                value={module.marca}
                onChange={(event) => handleChange(event, index, 'module')}
              />
              </Grid>
            <Grid item xs={3.5} key={index}>
                <TextField
                id={`model-module-${index}`}
                size='small'
                label="Modelo"
                variant="outlined"
                fullWidth
                name="modelo"
                value={module.modelo}
                onChange={(event) => handleChange(event, index, 'module')}
                />
                </Grid>
            <Grid item xs={3.5} key={index}>
                <TextField
                id={`quantity-module-${index}`}
                size='small'
                label="Quantidade"
                variant="outlined"
                fullWidth
                name="quantidade"
                value={module.quantidade}
                onChange={(event) => {
                  event.target.value = event.target.value.replace(/\D/g, '')
                  handleChange(event, index, 'module')}}
                />

                </Grid>
            <Grid item xs={1} key={index}>
            {index !== 0 &&    <Button onClick={() => handleRemoveModule(index)}>Remover</Button>}
            </Grid>
            </Grid>
          ))}
          </Grid>
          <Grid item xs={1} >
            <IconButton onClick={handleAddModule} size='small' >
                    <AddCircleOutlineIcon />
                    </IconButton>

          </Grid>
          </Grid>

          <Alert severity="info" sx={{ mt: 10, mb: 3 }}>Você também pode enviar um arquivo(imagem ou pdf) com  todos os dados dos módulos e inversores fotovoltáicos.</Alert>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',mt: 2,mb:2}}>
   
          <Button component="label" 
        variant="contained"
      >
        Carregar imagem
        <input type="file" 
         accept='image/* , application/pdf'
        hidden onChange={
          (e) => {
            const file = e.target.files[0]
            const formdata = new FormData()
            formdata.append('data_photovoltaic', file)
            props.setImage(formdata)
            toast.success(`Arquivo ${file.name} selecionado com sucesso!`)
          } 
        } />
        &nbsp;
        <CloudUploadIcon />
      </Button>
    </Box>
    </Grid>
  
    )}