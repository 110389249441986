
import React, { useEffect, useState } from 'react'

import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import New_Edit_Module from '../new_edit_module/index.jsx';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Box,
  TextField,
  Button,
  IconButton,
  DialogActions,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogContentText,
} from '@mui/material';
import { Link } from 'react-router-dom';
import DropDialog from '../dropDialog/index.jsx';


function ListModules(props) {
  const [data, setData] = useState('')
  const [demand, setDemand] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 4;

 
  useEffect(() => {
    const fetchModules = async () => {
      setLoading(true);
      try {
        fetch(`http://15.228.8.54:5000/api/modules/list?page=${currentPage}&limit=${itemsPerPage}`, {
          //fetch(`http://15.228.8.54:5000/api/modules/list?page=${currentPage}&limit=${itemsPerPage}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((response) => response.json())
          .then((data) => {
      
            setDemand(data.result);
            setTotalPages(data.total);
          })

      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchModules();
  }, [currentPage, itemsPerPage]);
 

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // ... renderizar a lista de produtos e a paginação


  const formatDate = (date) => {
    const dateFormatted = new Date(date);
    return dateFormatted.toLocaleDateString('pt-BR');
  };


  document.querySelectorAll('.MuiPaginationItem-root').forEach((item) => {
    item.style.backgroundColor = '#004e64';
    item.style.color = '#fff';
    if (item.ariaCurrent === 'true') {
      item.style.backgroundColor = '#ffffff';
      item.style.color = '#004e64';
      item.style.border = '2px solid #004e64';
    }

  })
 
  const [handleData, setHandleData] = useState('');


  const searchModule = () => {

    let value = document.querySelector('.search-module').value;
    value = value.toLowerCase();
    value = value.replace('/', '-');
    if (value !== '') {
      fetch(`http://15.228.8.54:5000/api/modules/search?page=${currentPage}&limit=${itemsPerPage}&search=${value}`, {
        method: 'GET',
      }).then(response => response.json())
        .then(data => {
            setDemand(data.result);
            setTotalPages(data.total);
            props.setWork_around('list-modules')

        })
      return
    }
    else {
      fetch(`http://15.228.8.54:5000/api/modules/list?page=${currentPage}&limit=${itemsPerPage}`, {
        //fetch(`http://15.228.8.54:5000/api/modules/list?page=${currentPage}&limit=${itemsPerPage}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => response.json())
        .then((data) => {
            setDemand(data.result);
            setTotalPages(data.total);
  props.setWork_around('list-modules')

        })
    }
  }


  const [doc_proxy, setDoc_proxy] = useState(null)

  const input_search_demand = document.querySelector('.search-module')
  input_search_demand?.addEventListener('keyup', (e) => {
    if (e.key === 'Enter') {
      searchModule()
    }
  }
  )

  const [title, setTitle] = useState('')
  const [open, setOpen] = useState(false)
  const handleClickOpenNewModule = () => {
    setData('')
    setTitle('new')
    setOpen(true)
  }
  const handleClickOpenEditModule = (item) => {
    setData(item)
    setOpen(true)
    setTitle('edit')
  }
  const handleCloseModules = () => {
    setOpen(false)
  }

  const [openDrop, setOpenDrop] = useState(false)
  const [titleDrop, setTitleDrop] = useState('')
  const [msgDrop, setMsgDrop] = useState('')
  const [idDrop, setIdDrop] = useState('')
  const handleClickOpenDrop = () => {
    setOpenDrop(true)
  }
  const handleCloseDrop = () => {
    setOpenDrop(false)
  }
  const deleteModule = () => {
    fetch(`http://15.228.8.54:5000/api/modules/delete/${idDrop}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json())
      .then((data) => {
  
       
          fetch(`http://15.228.8.54:5000/api/modules/list?page=${currentPage}&limit=${itemsPerPage}`, {
            //fetch(`http://15.228.8.54:5000/api/modules/list?page=${currentPage}&limit=${itemsPerPage}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }).then((response) => response.json())
            .then((data) => {
              setDemand(data.result);
              setTotalPages(data.total);
            })
        
      })
    setOpenDrop(false)
    handleCloseDrop()
  props.setWork_around('list-modules')
    
  }

  return (


         <Dialog
                            open={props.open}
                            onClose={props.handleClose}
                        
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            maxWidth='1200px'
                            sx={{ml:5}}
                        >
                            <DialogTitle id="alert-dialog-title">{"Lista de Módulos"}</DialogTitle>
                            <DialogContent>
      <div id='list-modules'>
        <div className='project-solar'>

          <div style={{ display: 'flex', padding: '0 50px 0 50px', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', paddingLeft: '0px' }}>
              <div style={{ display: 'flex' }}>
                <input type="text" className='search-module' placeholder='Pesquisar'
                onChange={(e) => {
            

                  if (e.target.value === '') {
                    fetch(`http://15.228.8.54:5000/api/modules/list?page=${currentPage}&limit=${itemsPerPage}`, {
                      //fetch(`http://15.228.8.54:5000/api/modules/list?page=${currentPage}&limit=${itemsPerPage}`, {
                      method: 'GET',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                    }).then((response) => response.json())
                      .then((data) => {
                        setDemand(data.result);
                        setTotalPages(data.total);
  props.setWork_around('list-modules')

                      })
                  }
                }} />
                <button id='bt-search' onClick={searchModule}>Pesquisar</button>
              </div>

   
            </div>
           <button onClick={handleClickOpenNewModule} style={{ background: '#218380' }} > Cadatrar Módulo</button>
          </div>

          <div id='tableDemands' style={{
            padding: '0 0px 0 0px',
          }}>
            {

              (
                <>
                  <Paper>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">Marca </TableCell>
                          <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">Modelo</TableCell>
                          <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">Tipo</TableCell>
                          <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">Potência Máxima(Pmax)</TableCell>
                          <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">Tolerância de Potência</TableCell>
                          <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">Tensão de Circuito Aberto(Voc)</TableCell>
                          <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">Corrente de Curto-Circuito (Isc)</TableCell>
                          <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">Tensão Máxima de Operação (Vmpp)</TableCell>
                          <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">Corrente Máxima de Operação (Impp)</TableCell>
                          <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">Fusível Máximo em Série</TableCell>
                          <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">Tensão Máxima do Sistema</TableCell>
          

                          <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">opções</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {demand?.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">{item.brand_modules}</TableCell>
                              <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">{item.model_modules}</TableCell>
                              <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">{item.type}</TableCell>
                              <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">{item.maximum_power}</TableCell>
                              <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">{item.tower_tolerance}</TableCell>
                              <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">{item.open_circuit_voltage_modules}</TableCell>
                              <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">{item.short_circuit_current_modules}</TableCell>
                              <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">{item.maximum_operating_voltage}</TableCell>
                              <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">{item.maximum_operating_current}</TableCell>
                              <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">{item.maximum_fuse_in_series}</TableCell>
                              <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">{item.maximum_system_voltage}</TableCell>
                              <TableCell sx={{ fontSize: 11, minWidth: 100,display:'flex' }} align="center">
                              <IconButton onClick={()=>handleClickOpenEditModule(item)} title='EDITAR' style={{ color: '#218380' }}>
                                <EditIcon />
                              </IconButton>
                         
                              <IconButton title='DELETAR' style={{ color: 'red' }} onClick={() => {
                                setTitleDrop('Deletar Módulo')
                                setMsgDrop('Deseja realmente deletar o módulo?')
                                setIdDrop(item.id_modules)
                                handleClickOpenDrop()
                              }
                            }>
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>


                  </Paper>
                  <Pagination style={{ marginTop: '10px' }}
                    count={totalPages}
                    page={currentPage}
                    onChange={(event, page) => handlePageChange(page)}
                    showFirstButton
                    showLastButton
                    boundaryCount={1} // Número de páginas antes e depois das ellipses
                    siblingCount={1} // Número de páginas antes e depois da página atual
                  />
                </>
              )}


          </div>


        </div>
      </div>
      <DropDialog openDrop={openDrop} handleCloseDrop={handleCloseDrop} title={titleDrop} msg={msgDrop} delete={deleteModule} />

      <New_Edit_Module open={open} handleClose={handleCloseModules} title={title} data={data} setData={setData} />
   
      </DialogContent>
                            <DialogActions>
                            <Button onClick={props.handleClose} autoFocus>
                                Fechar
                            </Button>
                            </DialogActions>
                        </Dialog>

  )
}

export default ListModules
